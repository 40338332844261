import React, { useEffect, useState } from "react";

import { Bottle, head, logos, tshirt1, tshirt2 } from "../assets/img";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import { useNavigate , useLocation} from "react-router-dom";
import { useLazyProjectViewQuery } from "../redux/api/api";

const PresentationGalleryPage2A = () => {
  const navigate = useNavigate();
  const [projectViewApi] = useLazyProjectViewQuery();
  const location = useLocation();
  const [productlist,setProductList]=useState([]);
  const user=location?.state?.data?.project_request?.user;
  console.log(user,"user")
  const getView = () => {
    projectViewApi(location?.state?.data?.id)
      .unwrap()
      .then((res) => {
        if (res?.status == "success" || res?.data?.status == "success") {
          const projectData = res?.project || {};
          setProductList(projectData.projectDetail || []);
          console.log(projectData.projectDetail,"projectData.projectDetail")
        //    const allArtworks = projectData.projectDetail
        //     ?.map((detail) => detail.artworks || [])
        //     ?.flat();
        //    const imageGalleryItems = Array.isArray(allArtworks)
        //     ? allArtworks.map((artwork) => ({
        //         original: artwork?.image_url || "",
        //         thumbnail: artwork?.image_url || ""
        //       }))
        //     : [];
  
        //   console.log(imageGalleryItems, "Processed artworks for ImageGallery");
        //   setImages(imageGalleryItems);
        }
      })
      .catch((err) => {
        console.log("Error fetching project details:", err);
      });
  };
  
  useEffect(() => {
    if (location?.state?.data?.id) {
      getView();
    }
  }, []);
  return (
    <div className=" " style={{ backgroundColor: "#fff" }}>
      <div className=" " style={{ width: "90%", margin: "auto" }}>
        <div className="d-flex flex-wrap">
          <div
            className="d-flex flex-wrap align-items-center mt-3 py-2 banner col-md-5"
            style={{ backgroundColor: "#ecfaff", borderRadius: "20px" }}
          >
            <div className="">
              <img
                alt="img"
                src={logos}
                className=" ps-3"
                style={{ width: "150px", objectFit: "cover" }}
              />
            </div>

            <div className="px-2 font-blue2" >
              <h2 className="f4 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20">
                merchHQ Pvt.Ltd.
              </h2>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
                PRESENTATION #110208 for AAHIKA
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                Project Name
              </p>
            </div>
          </div>
          <div className="col-md-7 mt-3">
            <img alt="img" src={head} className="ps-lg-3 w-100" />
          </div>
        </div>
        {/* <div className="my-5">
          
          <p className="f3 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 font-blue1 text-center">
            This Pen Product is not Available in MHQ
          </p>
        </div> */}
      
      <div className="d-flex flex-wrap ac-jb  my-3 " >
      {productlist?.map((item, ind) => {
        return (
      <div className="hove" style={{ position: "relative" } }key={ind}>
        <div className="list">
          <div
         onClick={() => {
          navigate("/presentation-gallery-page-3a", {
            state: {data: item,user: user,
            },
          });
        }}
            className="image rounded"
            style={{
              backgroundImage: `url(${item.product.defaultImage})`,
              width: "300px",
              height: "300px",
              objectFit: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          >
            <div className="hidden">
              <div
                className="primary"
                style={{ margin: "0px 0px 0px 20px", paddingTop: "20px" }}
              >
                <ChatBubbleIcon />
              </div>
              <div style={{ marginTop: "100px" }}>
                <p className="f3 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 text-center">
                  Price {item.product.mrp}
                </p>
                <p className="f3 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 primary text-center">
                  Rs.99.00 - Rs.131.58
                </p>
              </div>
            </div>
          </div>
        </div>
        <p className="f2 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 font-blue1 text-center my-3">
         {item.product.name}
        </p>
      </div>
       );
      })}
    </div>
 

        <div className="d-flex justify-content-end">
                      <div>
                        <button
                          className=""
                          style={{
                            backgroundColor: "#f82a2a",
                            borderRadius: "10px",
                            padding: "10px",
                            border: "none",
                          }}
                          onClick={() => {
                            navigate("/poproduct-supplier-approved-a");
                          }}
                        >
                          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
                            Request Change
                          </p>
                        </button>
                      </div>
                      <div>
                        <button
                          style={{
                            backgroundColor: "#0082ca",
                            borderRadius: "10px",
                            padding: "10px",
                            border: "none",
                            marginLeft: "5px",
                          }}
                          onClick={() => {
                            navigate("/poproduct-supplier-approved-a");
                          }}
                        >
                          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white ">
                            Approve Order
                          </p>
                        </button>
                      </div>
                    </div>
      </div>
      
      <div className="">
        <div
          className="mt-3  px-2"
          style={{
            backgroundColor: "#07679c",
            padding: "20px",
            lineHeight: "30px",
          }}
        >
          <div className="w-90 m-auto d-flex flex-wrap ac-jb">
            <div className="">
              <h2 className="f4 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 white">
                Roger Daniel
              </h2>
              <a className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 lt-Blue">
                roger@companystore.io
              </a>
              <button
                class=" d-flex ac-jc px-3 mt-4"
                style={{
                  backgroundColor: "#0082ca",
                  border: "none",
                  borderRadius: "7px",
                  padding: "5px",
                  color: "#fff",
                }}
              >
                <UnsubscribeIcon />

                <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
                  Contact us
                </p>
              </button>
            </div>
            <div className="col-md-4">
              <p className="f3 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 white">
                merchHQ Pvt.Ltd.
              </p>
              <p className="f1 fs-xs-11 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 white ">
                #39 Krishna Reddy Colony, Domlur Layout Banglore- 560071
                Kartnataka, India.
              </p>
              <div class="d-flex white mt-sm-2 ">
              <FacebookRoundedIcon className="me-2"/>

<TwitterIcon className="mx-2"/>

<LinkedInIcon className="mx-2"/>

<InstagramIcon className="mx-2"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PresentationGalleryPage2A;
