import React, { useEffect, useState } from "react";
import { head, logos, tshirt1, tshirt2, tshirt3 } from "../assets/img";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import Carousel from "react-bootstrap/Carousel";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
// import ExampleCarouselImage from "components/ExampleCarouselImage";

// import ExampleCarouselImage from 'components/ExampleCarouselImage';

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { useNavigate , useLocation} from "react-router-dom";
import { useLazyPresentationProViewQuery } from "../redux/api/api";






const PresentationGalleryPage3A = () => {
     const navigate = useNavigate();
     const location = useLocation();
     console.log(location,"location")
     const user=location?.state?.user;
     console.log(user)
  const [imgitem, setimgitem] = useState([]);
  const [product,setProduct] = useState();
  const [productqty,setProductQty]=useState();
  const [images,setImages]=useState([]);
  const [description,setDescription]=useState();
  const [fixedcharges,setFixedCharges]=useState();
  const [data,setData]=useState([])
  const [projectdetailViewApi] = useLazyPresentationProViewQuery();
  
  const getView = () => {
    projectdetailViewApi(location?.state?.data?.id).unwrap()
      .then((res) => {
        if (res?.status === "success" || res?.data?.status === "success") {
          console.log(res, "Full response from API");
          setData(res);
          console.log(res,"res");
          const productDetail = res?.project_detail;
          if (Array.isArray(productDetail)) {
            setProduct(productDetail); 
        } else if (productDetail) {
            setProduct([productDetail]); 
        } else {
            setProduct([]); 
        }
      
          setDescription(res?.project_detail?.description);

          setProductQty(res?.project_detail?.projectPresentationPrice);
  
          const projectPresentationPrice = res?.project_detail?.projectPresentationPrice;
  
          const allRunCharges = projectPresentationPrice?.flatMap((detail) => detail.run_charge || []);
          setFixedCharges(allRunCharges);
          const projectPresentationimage = res?.project_detail?.projectArtwork;
          console.log(projectPresentationimage,"projectPresentationimage")
          const allArtworks = projectPresentationimage?.flatMap((detail) => detail.artworks || []) || [];
          console.log(allArtworks,"allArtworks")
        const imageGalleryItems = projectPresentationimage.map((artwork) => ({
          original: artwork?.image_url || "",  
          thumbnail: artwork?.image_url || ""  
        }));

        console.log(imageGalleryItems, "Processed artworks for ImageGallery");
        setImages(imageGalleryItems);
      }
    
      })
      .catch((err) => {
        console.error("Error fetching project details:", err);
      });
  };
  
  useEffect(() => {
    if (location?.state?.data?.id) {
      getView();
    }
  }, [location?.state?.data?.id]); 
  
 
  return (
    <div className="bg-white" style={{ backgroundColor: "#fff" }}>
      <div className=" " style={{ width: "90%", margin: "auto" }}>
      <div className="d-flex flex-wrap">
          <div
            className="d-flex flex-wrap align-items-center mt-3 py-2 banner col-md-5"
            style={{ backgroundColor: "#ecfaff", borderRadius: "20px" }}
          >
            <div className="">
              <img
                alt="img"
                src={logos}
                className=" ps-3"
                style={{ width: "150px", objectFit: "cover" }}
              />
            </div>

            <div className="px-2 font-blue2" >
              <h2 className="f4 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20">
                merchHQ Pvt.Ltd.
              </h2>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
                PRESENTATION #110208 for AAHIKA
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                Project Name
              </p>
            </div>
          </div>
          <div className="col-md-7 mt-3">
            <img alt="img" src={head} className="ps-lg-3 w-100" />
          </div>
        </div>
        <div className="d-flex flex-wrap mt-4">
          <div className="col-md-6 mt-4 rounded">
          {/* {images?.length > 0 && (
  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
    {images.map(image => (
      <div
        key={image.original} 
        onClick={() => setimgitem(images)}
        style={{ width: '300px', height: '300px', cursor: 'pointer', margin: '10px' }}
      >
        <img
          src={image.thumbnail}
          alt="Thumbnail"
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      </div>
    ))}
  </div>
)} */}
            {/* <ImageGallery items={images} onClick={() => setimgitem(images)} style={{width:"300px", height:"300px"}} /> */}
            {images?.length > 0 && (
              
          <ImageGallery items={images.map(image => ({
            original: image.original,
            thumbnail: image.thumbnail
          }))}
          style={{width:"300px", height:"300px"}} />
        )}
            {/* <Carousel className="rounded" >
              <Carousel.Item >
                <img src={tshirt1} className="rounded " alt="" style={{height:"500px",width:"100%",objectFit:"cover"}} />
              </Carousel.Item>
              <Carousel.Item >
                <img src={tshirt2} className="rounded" alt="" style={{height:"500px",width:"100%",objectFit:"cover"}} />
              </Carousel.Item>
              <Carousel.Item  >
                <img src={tshirt3} className="rounded" alt="" style={{height:"500px",width:"100%",objectFit:"cover"}} />
              </Carousel.Item>     
            </Carousel> */}
            {/* <div className="carousel-indicators">
               <img src={tshirt1} alt="" style={{width:"30px", height:"30px"}} />
               <img src={tshirt2} alt="" style={{width:"30px", height:"30px"}}/>
               <img src={tshirt3} alt="" style={{width:"30px", height:"30px"}}/>
            </div> */}
          </div>
          {product?.map((item,index)=>(
          <div className="p-3 col-md-6 my-3">
            <h2 className="f4 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 primary  mt-3">
            {item?.product?.name} </h2>
            <p className="f3 fs-xs-9 fs-sm-10 fs-md-12 fs-lg-12 fs-xl-12 fs-xxl-13">
              {item?.product?.description}
            </p>
            {/* <div className="my-3">
              <button className="border-0 bg-gray p-2 rounded f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
                Product Description
              </button>
            </div> */}
            <div>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 my-4 ">
                {/* Price label:  Rs.650.00 */}
              </p>
            </div>
            <div>
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary">
                Pricing
              </p>
            </div>
            <div
              className="my-3 bg-lt-blue3 p-3"
              style={{ lineHeight: "40px" }}
            >
              <table className="w-100 overflow-scroll  ">
              <tr className="text-center">
    <td className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
    QTY
  </td>
  {Array.isArray(item?.projectPresentationPrice) && item.projectPresentationPrice.map((presentationPrice, index) => (
    <td  className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
      {presentationPrice?.qty}
    </td>
                  ))}
</tr>
                <tr className="text-center">

                  <td className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    RS{" "}
                  </td>
                  {Array.isArray(item?.projectPresentationPrice) && item.projectPresentationPrice.map((presentationPrice, index) => (
    <td  className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
      {presentationPrice?.total}
    </td>                  ))}

                </tr>

              </table>
            </div>
            <div>
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary">
                Fixed Charges
              </p>

              <div
                className="p-3 my-3 bg-lt-blue3 w-90"
                style={{ lineHeight: "40px" }}
              >
                <table className=" w-90  overflow-scroll">
               
                {Array.isArray(item?.projectPresentationPrice) &&
                            item.projectPresentationPrice.map((presentationPrice, priceIndex) =>
                                Array.isArray(presentationPrice.run_charge) &&
                                presentationPrice.run_charge.map((charge, chargeIndex) => (
                                    <tr key={chargeIndex}>
                                        <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                            {charge.name}
                                        </td>
                                        <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                            {charge.value}
                                        </td>
                                    </tr>
                                ))
                            )}
               
                 
                </table>
              </div>

              <div>
                <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary">
                  Colors:
                </p>
                <div className="d-flex">
                  <div className="primary">
                    <FiberManualRecordIcon />
                  </div>
                  {/* <div className="lt-Blue">
                    <FiberManualRecordIcon />
                  </div>
                  <div className="gray">
                    <FiberManualRecordIcon />
                  </div>
                  <div className="gray1">
                    <FiberManualRecordIcon />
                  </div> */}
                </div>
              </div>
              <div>
               
              </div>
            </div>
            <div className="">
              <button
                onClick={() => {
                  navigate("/presentation-gallery-comment-a", {
                    state: { data: data,user:user }
                  });
                }}
                className="my-3 bg-primar border-0 p-2 rounded f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white px-4"
              >
                Comments{" "}
                <span className="bg-blue1 rounded px-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  12
                </span>
              </button>
            </div>
          </div>
          ))}
        </div>
      </div>

      <button
        class=" d-flex ac-jc px-3 mt-2 ms-4 py-2"
        style={{
          backgroundColor: "#0082ca",
          border: "none",
          borderRadius: "7px",
          padding: "5px",
          color: "#fff",
        }}
      >
        <UnsubscribeIcon />

        <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white px-2 py-2">
          Contact us
        </p>
      </button>
    </div>
  );
};

export default PresentationGalleryPage3A;
