import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { noLogo, product } from "../../assets/img";
import { Close } from "@mui/icons-material";
import {
  useArtworkStoreMutation,
  useEstimateUpdateMutation,
  usePresentationArtworkStatusMutation,
  useProjectStatusChangeMutation,
} from "../../redux/api/api";
import { saveLoader } from "../../redux/slice/loaderNoti";
import ViewStockPoppup from "../EstimateComp/ViewStockPoppup";
import AddFilesPopup from "../Popup/AddFilesOverview";
import DeletePopup from "../Popup/DeletePopup";
import AddArtWorker from "./AddArtWorker";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { toast } from "react-hot-toast";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ProductsViewPopup from "../Popup/ProductsViewPopup";

const PresentaionList = ({
  projectDetail,
  projectDetails,
  setProjectDetail,
  presentationStatus,
  presentationDelete,
  colSpan,
  setColspan,
  setShowPopup,
  getProjectView,
  projectStatus,
  projectData,
  artworkDelete,
  artList,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [detGrid, setDetGrid] = useState(0);
  const [actionBtn, setActionBtn] = useState(null);
  const [showAll, setShowAll] = useState(0);
  const [tabBtn, setTabBtn] = useState(0);
  const [addArt, setAddArt] = useState(false);
  const [addClient, setAddClient] = useState(false);
  const [AddFilesOverview, setAddFilesOverview] = useState(false);
  const [addShow, setAddShow] = useState(false);
  const [addTitle, setAddTitle] = useState(false);
  const [deletes, setDeletes] = useState(false);
  const [actDect, setActDect] = useState(false);
  const [viewStock, setViewStock] = useState(false);
  const [btn, setBtn] = useState(false);
  const [hide, setHide] = useState(0);
  const [status, setStatus] = useState(0);
  const [status2, setStatus2] = useState(0);
  const [artworkData, setArtWorkData] = useState({});
  const [artworkDatail, setArtWorkDatail] = useState({});
  const [design_status, setdesign_status] = useState("");
  const [sale_status, setsale_status] = useState("");

  const [decorative_methodList, setdecorative_methodList] = useState([]);
  const [decorativeMethodList, setDecorativeMethodList] = useState([]);
  const [decorative_method_id, setdecorative_method_id] = useState("");
  const [decorative_method_name, setdecorative_method_name] = useState("");
  const [proof_required, setproof_required] = useState("");
  const [logo_name, setlogo_name] = useState("");
  const [uom, setuom] = useState(0);
  const [logo_length, setlogo_length] = useState("");
  const [logo_breadth, setlogo_breadth] = useState("");
  const [logo_color, setlogo_color] = useState("");
  const [repeat_logo, setrepeat_logo] = useState("");
  const [supplier_note, setsupplier_note] = useState("");
  const [location, setlocation] = useState("");
  const [file, setfile] = useState("");

  const [decorative_method_idErr, setdecorative_method_idErr] = useState(false);
  const [proof_requiredErr, setproof_requiredErr] = useState(false);
  const [logo_nameErr, setlogo_nameErr] = useState(false);
  const [uomErr, setuomErr] = useState(false);
  const [logo_lengthErr, setlogo_lengthErr] = useState(false);
  const [logo_breadthErr, setlogo_breadthErr] = useState(false);
  const [logo_colorErr, setlogo_colorErr] = useState(false);
  const [repeat_logoErr, setrepeat_logoErr] = useState(false);
  const [supplier_noteErr, setsupplier_noteErr] = useState(false);
  const [locationErr, setlocationErr] = useState(false);
  const [fileErr, setfileErr] = useState(false);
  const [userPopup, setUserPopup] = useState(false);
  const [userCommend, setUserCommend] = useState({});

  // RTK QUERY
  const [artworkStoreApi] = useArtworkStoreMutation();
  const [artworkStatusApi] = usePresentationArtworkStatusMutation();
  const [artworkUpdateApi] = useEstimateUpdateMutation();

  const handleUserPopup = (item) => {
    setUserCommend(item);
    setUserPopup(!userPopup);
  };

  const submitHandleArt = () => {
    if (
      decorative_method_id?.length == 0 ||
      proof_required?.length == 0 ||
      logo_name?.length == 0 ||
      uom == 0 ||
      logo_length?.length == 0 ||
      logo_breadth?.length == 0 ||
      logo_color?.length == 0 ||
      repeat_logo?.length == 0 ||
      supplier_note?.length == 0 ||
      location?.length == 0 ||
      file?.length == 0
    ) {
      setdecorative_method_idErr(true);
      setproof_requiredErr(true);
      setlogo_nameErr(true);
      setuomErr(true);
      setlogo_lengthErr(true);
      setlogo_breadthErr(true);
      setlogo_colorErr(true);
      setrepeat_logoErr(true);
      setsupplier_noteErr(true);
      setlocationErr(true);
      setfileErr(true);
    } else {
      let formdata = new FormData();
      let project_code = searchParams?.get("project");
      if (project_code) {
        let split = project_code.split("_");
        formdata.append("project_id", split[1]);
        formdata.append("product_id", artworkData?.product?.id);
        formdata.append("project_detail_id", artworkData?.id);
        formdata.append("product_sku_id", artworkData?.product_sku?.id);
        formdata.append("decorative_method_id", decorative_method_id);
        formdata.append("proof_required", proof_required);
        formdata.append("logo_name", logo_name);
        formdata.append("uom", uom);
        formdata.append("logo_length", logo_length);
        formdata.append("logo_breadth", logo_breadth);
        formdata.append("logo_color", logo_color);
        formdata.append("repeat_logo", repeat_logo);
        formdata.append("supplier_note", supplier_note);
        formdata.append("location", location);

        if (file?.name) {
          formdata.append("file", file);
        }

        dispatch(saveLoader(true));
        setBtn(true);
        if (artworkDatail?.id) {
          artworkUpdateApi({ payload: formdata, id: artworkDatail?.id })
            .unwrap()
            .then((res) => {
              dispatch(saveLoader(false));
              setBtn(false);
              getProjectView();
              toggleShowClosePopup();
            })
            .catch((err) => {
              dispatch(saveLoader(false));
              setBtn(false);
              console.log("err", err);
            });
        } else {
          artworkStoreApi(formdata)
            .unwrap()
            .then((res) => {
              dispatch(saveLoader(false));
              setBtn(false);
              getProjectView();
              toggleShowClosePopup();
            })
            .catch((err) => {
              dispatch(saveLoader(false));
              setBtn(false);
              console.log("err", err);
            });
        }
      }
    }
  };

  const handleStatusArt = (status, type) => {
    dispatch(saveLoader(true));
    let formdata = new FormData();

    formdata.append("artwork_id", artworkDatail?.id);

    if (type == 1) {
      formdata.append("sale_status", status);
      setsale_status(status);
    }

    if (type == 2) {
      formdata.append("design_status", status);
      setdesign_status(status);
    }

    artworkStatusApi(formdata)
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        dispatch(saveLoader(false));

        if (type == 1) {
          if (status !== 1) {
            setAddClient(false);
          }
        }

        getProjectView();
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        console.log("err", err);
      });
  };

  const handleStatus = (value) => {
    if (actionBtn == value) {
      setActionBtn(null);
    } else {
      setActionBtn(value);
    }
  };

  const artWork = () => {
    toggleShowPopup();
  };

  const toggleShowPopup = () => {
    setAddClient(!addClient);
  };

  const toggleShowClosePopup = () => {
    setdecorative_method_id("");
    setdecorative_method_name("");
    setproof_required("");
    setlogo_name("");
    setuom("");
    setlogo_length("");
    setlogo_breadth("");
    setlogo_color("");
    setrepeat_logo("");
    setsupplier_note("");
    setlocation("");
    setfile("");
    setdecorative_method_idErr(false);
    setproof_requiredErr(false);
    setlogo_nameErr(false);
    setuomErr(false);
    setlogo_lengthErr(false);
    setlogo_breadthErr(false);
    setlogo_colorErr(false);
    setrepeat_logoErr(false);
    setsupplier_noteErr(false);
    setlocationErr(false);
    setfileErr(false);
    setAddClient(false);
    setArtWorkData({});
    setArtWorkDatail({});
    setdesign_status("");
    setsale_status("");
  };

  const toggleShowPopups = () => {
    setAddFilesOverview(!AddFilesOverview);
    setAddClient(!addClient);
  };

  const deleteToggle = (value) => {
    setDeletes(!deletes);
    if (value == 2) {
      presentationDelete(status);
    }
  };

  const deleteToggles = (value) => {
    setActDect(!actDect);
    if (value == 2) {
      artworkDelete(status2);
    }
  };

  const viewStockToggle = () => {
    setViewStock(!viewStock);
  };

  const hider = (num, type) => {
    if (type == "one") {
      if (hide == num) {
        setHide(0);
      } else {
        setHide(num);
      }
    } else {
      if (hide == num) {
        setHide(0);
      } else {
        setHide(num);
      }
    }
  };

  const deletePopupOpen = (id) => {
    setStatus(id);
    setDeletes(!deletes);
  };

  const deletePopupOpenArt = (id) => {
    setStatus2(id);
    setActDect(!actDect);
  };

  const handleArtworkAdder = (index) => {
    let temp = [...projectDetail];
    let temx = [...temp[index]["projectArtwork"]];
    let arr = [];
    temx.push(1);

    temp[index] = {
      ...temp[index],
      projectArtwork: temx,
    };

    temp?.map((item, ind) => {
      arr.push(item?.projectArtwork?.length);
    });

    let maxValue = Math.max(...arr);
    setColspan(maxValue);
    setProjectDetail(temp);
  };

  const artWorkHandler = (art, item, index, aind) => {
    if (art?.decorative_method_id?.id) {
      setdecorative_method_id(art?.decorative_method_id?.id);
      setdecorative_method_name(art?.decorative_method_id?.name);
    }
    if (art?.proof_required) {
      setproof_required(art?.proof_required);
    }

    if (art?.logo_name) {
      setlogo_name(art?.logo_name);
    }

    if (art?.uom) {
      setuom(+art?.uom);
    }

    if (art?.logo_length) {
      setlogo_length(art?.logo_length);
    }

    if (art?.logo_breadth) {
      setlogo_breadth(art?.logo_breadth);
    }

    if (art?.logo_color) {
      setlogo_color(art?.logo_color);
    }

    if (art?.repeat_logo) {
      setrepeat_logo(art?.repeat_logo);
    }

    if (art?.supplier_note) {
      setsupplier_note(art?.supplier_note);
    }

    if (art?.location) {
      setlocation(art?.location);
    } else {
      setlocation(aind + 1);
    }

    if (art?.image_url) {
      setfile(art?.image_url);
    }

    if (art?.design_status) {
      setdesign_status(art?.design_status);
    }

    if (art?.sale_status) {
      setsale_status(art?.sale_status);
    }

    if (art?.id) {
      setArtWorkDatail(art);
    }

    setdecorative_methodList(item?.product?.productDecorations);
    setDecorativeMethodList(item?.product?.productDecorations);
    setArtWorkData(item);
    setAddClient(true);
  };

  // console.log("projectDetail", projectDetail);

  const showChangeHandler = (value) => {
    if (value == 1) {
      let filter = projectDetails?.filter((i) => i?.status == 2);
      setProjectDetail(filter);
    } else {
      setProjectDetail(projectDetails);
    }
    setShowAll(value);
  };

  return (
    <Col sm={12}>
      {userPopup && (
        <ProductsViewPopup
          handleUserPopup={handleUserPopup}
          userCommend={userCommend}
        />
      )}
      {viewStock && <ViewStockPoppup viewStockToggle={viewStockToggle} />}
      <div className="presen-list">
        <div className="mt-3 d-flex ac-jb mt-0 mt-md-3 flex-column flex-md-row presen-detail py-4 px-2">
          <div className="d-flex ac-js">
            <button
              onClick={() => setTabBtn(0)}
              className={`${
                tabBtn == 0 ? "border_click_cust" : "cust-btn "
              } bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 `}
            >
              Products
            </button>
            <button
              onClick={() => setTabBtn(1)}
              className={`${
                tabBtn == 1 ? "border_click_cust" : "cust-btn "
              } bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 `}
            >
              Artwork
            </button>
          </div>
          <div className="d-flex ac-jc gap-3 mt-3 mt-md-0 flex-column flex-sm-row ">
            <div className="d-flex gap-2">
              <div className="d-flex justify-conten-center align-items-center gap-2">
                <input
                  type={"checkbox"}
                  onClick={() => projectStatus(4)}
                  checked={
                    projectData?.status >= 4 && artList?.length > 0
                      ? true
                      : false
                  }
                  disabled={
                    projectData?.status >= 4 && artList?.length > 0
                      ? true
                      : false
                  }
                />
                <p className="text-nowrap">Show to Customer</p>
              </div>

              {tabBtn == 0 && (
                <button
                  onClick={() => setDetGrid(0)}
                  className={` ${
                    detGrid == 0 && "primary bg-lt-blue"
                  } editBtn bg-transparent text-nowrap rounded_cust_left py-2 px-3 f1 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 `}
                >
                  Detailed
                </button>
              )}
              {/* <button
                  onClick={() => setDetGrid(1)}
                  className={` ${
                    detGrid == 1 && "primary bg-lt-blue"
                  } editBtn bg-transparent text-nowrap rounded_cust_right border-start-0 py-2 px-3 f1 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 `}
                >
                  Grid
                </button> */}
            </div>

            <div className="d-flex">
              <button
                onClick={() => showChangeHandler(0)}
                className={` ${
                  showAll == 0 && "primary bg-lt-blue"
                } editBtn bg-transparent text-nowrap rounded_cust_left py-2 px-3 f1 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 `}
              >
                Show All
              </button>
              <button
                onClick={() => showChangeHandler(1)}
                className={` ${
                  showAll == 1 && "primary bg-lt-blue"
                } editBtn bg-transparent text-nowrap rounded_cust_right border-start-0 py-2 px-3 f1 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 `}
              >
                Show Active
              </button>
            </div>
          </div>
        </div>
        {/* <div className="d-flex justify-content-end ">
          {tabBtn == 0 && (
            <div className="mx-1">
              <button
                className={
                  detGrid == 0 ? "presen-detail active " : "presen-detail"
                }
                onClick={() => setDetGrid(0)}
                style={{ marginRight: "0" }}
              >
                Detailed
              </button>
              <button
                className={
                  detGrid == 1 ? "presen-detail active" : "presen-detail"
                }
                onClick={() => setDetGrid(1)}
                style={{ marginRight: "0" }}
              >
                Grid
              </button>
            </div>
          )}
          <div>
            <button
              className={
                showAll == 0 ? "presen-detail active " : "presen-detail"
              }
              onClick={() => setShowAll(0)}
              style={{ marginRight: "0" }}
            >
              Show All
            </button>
            <button
              className={
                showAll == 1 ? "presen-detail active" : "presen-detail"
              }
              onClick={() => setShowAll(1)}
              style={{ marginRight: "0" }}
            >
              Show Active
            </button>
          </div>
        </div>
        <div className="d-flex">
          <button
            className={
              tabBtn == 0
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-slide_tab f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 border-slide_tab_line"
                : "border-bottom-0 border-top-0 border-start-0 border-end-0 border-slide_tab f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            }
            onClick={() => setTabBtn(0)}
          >
            Products
          </button>
          <button
            className={
              tabBtn == 1
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-slide_tab border-slide_tab_line f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                : "border-bottom-0 border-top-0 border-start-0 border-end-0 border-slide_tab f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            }
            onClick={() => setTabBtn(1)}
          >
            Artwork
          </button>
        </div> */}
        {tabBtn == 0 && (
          <div className="w-100 overflow-scroll table_border mt-3">
            <table className="w-100 presen-tables">
              <thead className="presen-table">
                <tr>
                  <th style={{ textAlign: "left", width: "600px" }}>
                    <div className="dropdown mx-2">
                      <button
                        className={`dropdown-toggle cust-btn addbtn  ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded
                       ${projectData?.status >= 5 ? "opacity-50" : ""} `}
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        // onClick={() => setShowPopup(true)}
                        disabled={projectData?.status >= 5 ? true : false}
                        onClick={() =>
                          navigate(
                            `/product-library-select?project=${searchParams?.get(
                              "project"
                            )}`,
                            { state: { back: "presentation" } }
                          )
                        }
                      >
                        + Add
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <span
                            className="dropdown-item f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                            onClick={() => navigate("/product-library")}
                          >
                            Product
                          </span>
                        </li>
                        <li>
                          <span
                            className="dropdown-item f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                            onClick={() => setAddTitle(true)}
                          >
                            Tittle
                          </span>
                        </li>
                      </ul>
                    </div>
                  </th>
                  {detGrid == 0 && (
                    <>
                      <th className="text-center f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary">
                        <div style={{ width: "100px" }}>Qty</div>
                      </th>
                      <th className="text-center text-nowrap f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary">
                        <div>Additional Cost</div>
                      </th>
                      <th className="text-center f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary">
                        <div style={{ width: "100px" }}>Margin</div>
                      </th>
                      <th className="text-center f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary">
                        <div style={{ width: "100px" }}>Amount</div>
                      </th>
                      <th className="text-center f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary">
                        <div style={{ width: "100px" }}>Fixed Price</div>
                      </th>
                    </>
                  )}
                </tr>
              </thead>
              {detGrid == 0 ? (
                <>
                  {projectDetail?.map((item, index) => {
                    return (
                      <tbody
                        className={`pres-body border-bottom ${
                          item?.status == 3
                            ? "opacity-50"
                            : item?.status == 4
                            ? "opacity-50"
                            : ""
                        }`}
                        key={index}
                      >
                        <tr className="position-relative color-for-hecd ">
                          <td
                            rowspan={item?.projectPresentationPrice?.length + 1}
                            className=""
                          >
                            <div className="ps-3" style={{ width: "550px" }}>
                              <div>
                                <div className="d-flex cp">
                                  <div className="productsImg">
                                    <img
                                      src={
                                        item?.product?.defaultImage
                                          ? item?.product?.defaultImage
                                          : noLogo
                                      }
                                      className="pre-products-img mt-2"
                                      onClick={() =>
                                        navigate("/presentation-edit", {
                                          state: {
                                            data: item,
                                            type: "edit",
                                            list: projectData,
                                          },
                                        })
                                      }
                                    />
                                  </div>
                                  <div
                                    className="d-flex flex-column mt-4 cp mx-3"
                                    onClick={() =>
                                      navigate("/product-library-details")
                                    }
                                  >
                                    <span
                                      onClick={() =>
                                        navigate("/product-library-details")
                                      }
                                      className="cp text-info f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 "
                                    >
                                      {item?.product?.name}
                                    </span>
                                    {/* <span
                                  onClick={() =>
                                    navigate("/product-library-details")
                                  }
                                  className="f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14"
                                >
                                  Sample(0571 x2415039)
                                </span> */}
                                    <span
                                      onClick={() =>
                                        navigate("/product-library-details")
                                      }
                                      className="f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14"
                                    >
                                      {item?.product?.description}
                                    </span>
                                  </div>
                                </div>
                                <div className="d-flex mt-3 mb-3">
                                  <button
                                    className="text-nowrap px-2"
                                    onClick={() => {
                                      if (item?.status == 3) {
                                        presentationStatus(item?.id, 1);
                                      } else if (item?.status == 4) {
                                        presentationStatus(item?.id, 2);
                                      } else if (item?.status == 2) {
                                        presentationStatus(item?.id, 4);
                                      } else if (item?.status == 1) {
                                        presentationStatus(item?.id, 3);
                                      }
                                    }}
                                  >
                                    {item?.status == 3
                                      ? "Unhide"
                                      : item?.status == 4
                                      ? "Unhide"
                                      : "Hide"}
                                  </button>
                                  <button
                                    className="text-nowrap px-2 "
                                    onClick={() =>
                                      navigate("/presentation-edit", {
                                        state: {
                                          data: item,
                                          type: "edit",
                                          list: projectData,
                                        },
                                      })
                                    }
                                  >
                                    Edit
                                  </button>
                                  {/* <button className="text-nowrap px-2">Copy</button> */}
                                  {/* <button
                              className={actDect && "del-colo-d"}
                              onClick={() => setActDect(!actDect)}
                            >
                              {actDect ? "DeActive" : "Active"}
                            </button> */}
                                  <button
                                    className="text-nowrap px-2  del-colo-d"
                                    onClick={() => {
                                      deletePopupOpen(item?.id);
                                    }}
                                  >
                                    Delete
                                  </button>

                                  <button
                                    className="text-nowrap px-2"
                                    onClick={viewStockToggle}
                                  >
                                    View Stock
                                  </button>
                                  {/* <button
                                    // onClick={() =>
                                    //   navigate("/upload-artwork-inprocess")
                                    // }
                                    className="text-nowrap px-2 artwor_btn"
                                  >
                                    Artwork Pending
                                  </button> */}
                                </div>
                              </div>
                              <div
                                className="status_btn w-auto d-flex align-items-center "
                                style={{
                                  bottom: 0,
                                }}
                              >
                                <div
                                  className={`${
                                    item?.artwork_status == 1
                                      ? "process_btn"
                                      : item?.artwork_status == 2
                                      ? "accepted_btn"
                                      : item?.artwork_status == 3
                                      ? "pending_btn"
                                      : item?.artwork_status == 4
                                      ? "pending_btn"
                                      : ""
                                  } rounded-3 d-flex mb-2 ac-jc px-4 py-2 position-relative`}
                                  style={{ width: "140px" }}
                                  role={"button"}
                                  onClick={() => handleStatus(index)}
                                >
                                  <p className="text_btn text-nowrap">
                                    {/* {item?.status == 1
                                      ? "In Process"
                                      : item?.status == 2
                                      ? "Ready"
                                      : item?.status == 3
                                      ? "In Progress Hide"
                                      : item?.status == 4
                                      ? "Ready Hide"
                                      : ""} */}
                                    {item?.artwork_status == 1
                                      ? "In Process"
                                      : item?.artwork_status == 2
                                      ? "Artwork Ready"
                                      : item?.artwork_status == 3
                                      ? "User Approved"
                                      : item?.artwork_status == 4
                                      ? "User Rejected"
                                      : ""}
                                  </p>

                                  {/* {index == actionBtn && (
                                    <div
                                      className="action_btn ml-1 position-absolute"
                                      style={{
                                        width: "140px",
                                        zIndex: 300,
                                        top: "110%",
                                        left: 0,
                                      }}
                                    >
                                      <ul
                                        style={{
                                          border: "1px solid #6c757d",
                                          borderRadius: "3px",
                                        }}
                                      >
                                        <li
                                          className="border-0 bg-white text-start f2 text-dark fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100"
                                          onClick={() => {
                                            presentationStatus(item?.id, 1);
                                            setActionBtn(null);
                                          }}
                                        >
                                          In Process
                                        </li>

                                        <li
                                          className="border-0 bg-white text-start f2 text-dark fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100"
                                          onClick={() => {
                                            presentationStatus(item?.id, 2);
                                            setActionBtn(null);
                                          }}
                                        >
                                          Ready
                                        </li>
                                      </ul>
                                    </div>
                                  )} */}
                                </div>
                                {item?.location && (
                                  <div onClick={() => handleUserPopup(item)}>
                                    <RemoveRedEyeIcon className="primary pointer ms-3 mb-2" />
                                  </div>
                                )}
                              </div>
                            </div>
                          </td>
                          <td></td>
                          <td
                            style={{ width: "200px" }}
                            className="text-center"
                          >
                            {/* {item?.qty} */}
                          </td>
                          <td
                            style={{ width: "200px" }}
                            className="text-center"
                          >
                            {/* {item?.tax_percent}% */}
                          </td>
                          <td
                            style={{ width: "200px" }}
                            className="text-center"
                          >
                            {/* {item?.final_amount} */}
                          </td>
                          <td></td>
                        </tr>
                        {item?.projectPresentationPrice?.map((charge, cInd) => {
                          let run_charge_value = "";
                          if (charge?.run_charge?.length > 0) {
                            run_charge_value = charge?.run_charge?.reduce(
                              (n, { value }) => n + +value,
                              0
                            );
                          }
                          return (
                            <tr key={cInd}>
                              <td
                                style={{ width: "200px" }}
                                className="text-center"
                              >
                                {charge?.qty}
                              </td>
                              <td
                                style={{ width: "200px" }}
                                className="text-center"
                              >
                                {run_charge_value ? run_charge_value : ""}
                              </td>
                              <td
                                style={{ width: "200px" }}
                                className="text-center"
                              >
                                {charge?.margin}%
                              </td>
                              <td
                                style={{ width: "200px" }}
                                className="text-center"
                              >
                                {" "}
                                ₹{charge?.total}
                              </td>
                              <td
                                style={{ width: "200px" }}
                                className="text-center"
                              >
                                {" "}
                                ₹{charge?.total}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    );
                  })}
                </>
              ) : (
                <div>
                  <div className="d-flex py-3 px-4 gap-3 ">
                    <div className="grid-view-presen cp position-relative ac-jc">
                      <img src={product} className="w-100" />
                      <div className="  product_text tranc ">
                        <p className=" text-center black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                          Product
                        </p>
                      </div>
                    </div>
                    <div className="grid-view-presen cp position-relative ac-jc">
                      <img src={product} className="w-100" />
                      <div className="  product_text  tranc ">
                        <p className=" text-center black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                          Product
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </table>
          </div>
        )}
        {tabBtn == 1 && (
          <div className="mt-3 w-100 overflow-scroll table_border">
            <table className="w-100 presen-tables">
              <thead className="presen-table">
                <tr className="">
                  <th style={{ textAlign: "left", width: "0%" }}>
                    <div className="dropdown mx-2">
                      <button
                        className={`dropdown-toggle cust-btn addbtn  ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded
                       ${projectData?.status >= 5 ? "opacity-50" : ""} `}
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        disabled={projectData?.status >= 5 ? true : false}
                        onClick={() =>
                          navigate(
                            `/product-library-select?project=${searchParams?.get(
                              "project"
                            )}`,
                            { state: { back: "presentation" } }
                          )
                        }
                      >
                        + Add
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <span
                            className="dropdown-item f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                            onClick={() => navigate("/product-library")}
                          >
                            Product
                          </span>
                        </li>
                        <li>
                          <span
                            className="dropdown-item f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                            onClick={() => setAddTitle(true)}
                          >
                            Tittle
                          </span>
                        </li>
                      </ul>
                    </div>
                  </th>
                  <th
                    className="f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center"
                    colSpan={colSpan + 1}
                  >
                    Artwork
                  </th>
                </tr>
              </thead>
              <tbody className="overflow-scroll">
                {projectDetail?.map((item, index) => {
                  return (
                    <tr
                      className={`art-work-tab1 ${
                        item?.status == 3
                          ? "opacity-50 pe-none"
                          : item?.status == 4
                          ? "opacity-50 pe-none"
                          : ""
                      }`}
                      key={index}
                    >
                      <td className="text-left">
                        <div
                          style={{ width: "300px" }}
                          className="d-flex ac-js"
                        >
                          <div
                            className="artwork_image cp"
                            onClick={() =>
                              navigate("/presentation-edit", {
                                state: {
                                  data: item,
                                  type: "edit",
                                  list: projectData,
                                },
                              })
                            }
                          >
                            <img
                              src={
                                item?.product?.defaultImage
                                  ? item?.product?.defaultImage
                                  : noLogo
                              }
                              className="w-100 mt-2"
                            />
                          </div>
                          <span
                            onClick={() =>
                              navigate("/presentation-edit", {
                                state: {
                                  data: item,
                                  type: "edit",
                                  list: projectData,
                                },
                              })
                            }
                            className="d-block cp mt-3 text-info f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary"
                          >
                            {item?.product?.name}
                          </span>
                        </div>
                      </td>
                      {item?.projectArtwork?.map((art, aind) => {
                        return (
                          <td key={aind}>
                            <div className="d-inline">
                              <label className="f3 mt-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary d-flex">
                                Location {aind + 1}:
                                {art?.id && projectData?.status <= 4 && (
                                  <div
                                    className="mx-2 d-flex ac-jc position-relative hide_res2"
                                    style={{ width: "20px" }}
                                    onClick={() => {
                                      if (projectData?.status <= 4) {
                                        deletePopupOpenArt(art?.id);
                                      }
                                    }}
                                  >
                                    <Close className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center gray" />
                                  </div>
                                )}
                              </label>
                              <div className="" style={{ width: "300px" }}>
                                <div
                                  className="artwork_images d-flex ac-jc"
                                  role={"button"}
                                  onClick={() => {
                                    artWorkHandler(art, item, index, aind);
                                  }}
                                >
                                  {art?.id ? (
                                    <div className="w-100">
                                      <img
                                        src={
                                          art?.image_url
                                            ? art?.image_url
                                            : noLogo
                                        }
                                        className="w-100"
                                      />
                                    </div>
                                  ) : (
                                    <p className="f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                      {" "}
                                      Add Artwork
                                    </p>
                                  )}
                                </div>
                                {art?.id && (
                                  <div className="mx-1 mt-2 mb-2">
                                    <button
                                      // onClick={() =>
                                      //   navigate("/upload-artwork-inprocess")
                                      // }
                                      className="text-nowrap px-2 artwor_btn"
                                    >
                                      {art?.design_status == 2 &&
                                      art?.sale_status == 2
                                        ? "Sales Approved"
                                        : art?.design_status == 2 &&
                                          art?.sale_status == 3
                                        ? "Sales Rejected"
                                        : art?.design_status == 1
                                        ? "Design Inprogress"
                                        : art?.design_status == 2
                                        ? "Design Completed"
                                        : "Art Work Progress"}
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </td>
                        );
                      })}
                      {projectData?.status <= 4 && (
                        <td>
                          <div className="d-inline">
                            <label className="f3 mt-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary">
                              &nbsp;
                            </label>
                            <div
                              className="artwork_images d-flex ac-jc"
                              role={"button"}
                              onClick={() => {
                                if (projectData?.status <= 4) {
                                  handleArtworkAdder(index);
                                }
                              }}
                            >
                              <p className="f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 w-100">
                                <AddBoxIcon />
                              </p>
                            </div>
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        {addTitle && (
          <div className="add-tit-btn mt-3">
            <div className="m-2">
              <input
                placeholder="Add a title"
                className="w-100 b-color-add mt-3"
              />
              <div className="d-flex mt-2 position-absolute m-2">
                <div className="dropdown mx-2">
                  <button
                    className="add-tit-btn2 mx-1 bottom_show_btn"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    + Add
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <span className="dropdown-item">Product</span>
                    </li>
                    <li>
                      <span className="dropdown-item">Tittle</span>
                    </li>
                  </ul>
                </div>
                {/* <button className="add-tit-btn1">+ Add</button> */}
                <button
                  className="add-tit-btn2 mx-1 bottom_show_btn"
                  onClick={() => setAddShow(!addShow)}
                >
                  {addShow ? "Show" : "hide"}
                </button>
                <button
                  className="add-tit-btn3 mx-1 bottom_delete_btn"
                  onClick={() => setDeletes(!deletes)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {addClient && (
        <AddArtWorker
          toggleShowPopup={toggleShowClosePopup}
          setAddArt={setAddArt}
          toggleShowPopups={toggleShowPopups}
          statusOption={true}
          decorative_method_id={decorative_method_id}
          setdecorative_method_id={setdecorative_method_id}
          decorative_method_name={decorative_method_name}
          setdecorative_method_name={setdecorative_method_name}
          proof_required={proof_required}
          setproof_required={setproof_required}
          logo_name={logo_name}
          setlogo_name={setlogo_name}
          uom={uom}
          setuom={setuom}
          logo_length={logo_length}
          setlogo_length={setlogo_length}
          logo_breadth={logo_breadth}
          setlogo_breadth={setlogo_breadth}
          logo_color={logo_color}
          setlogo_color={setlogo_color}
          repeat_logo={repeat_logo}
          setrepeat_logo={setrepeat_logo}
          supplier_note={supplier_note}
          setsupplier_note={setsupplier_note}
          location={location}
          setlocation={setlocation}
          file={file}
          setfile={setfile}
          decorative_method_idErr={decorative_method_idErr}
          proof_requiredErr={proof_requiredErr}
          logo_nameErr={logo_nameErr}
          uomErr={uomErr}
          logo_lengthErr={logo_lengthErr}
          logo_breadthErr={logo_breadthErr}
          logo_colorErr={logo_colorErr}
          repeat_logoErr={repeat_logoErr}
          supplier_noteErr={supplier_noteErr}
          locationErr={locationErr}
          fileErr={fileErr}
          decorative_methodList={decorative_methodList}
          decorativeMethodList={decorativeMethodList}
          setDecorativeMethodList={setDecorativeMethodList}
          btn={btn}
          submitHandleArt={submitHandleArt}
          artworkDatail={artworkDatail}
          design_status={design_status}
          sale_status={sale_status}
          handleStatusArt={handleStatusArt}
        />
      )}
      {AddFilesOverview && (
        <AddFilesPopup
          toggleShowPopup={toggleShowPopups}
          popupType={"addClient"}
        />
      )}
      {deletes && (
        <DeletePopup deleteToggle={deleteToggle} setAddTitle={setAddTitle} />
      )}

      {actDect && (
        <DeletePopup deleteToggle={deleteToggles} setAddTitle={setAddTitle} />
      )}
    </Col>
  );
};

export default PresentaionList;
