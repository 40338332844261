import React, { useState } from "react";
import { noLogo, product } from "../../assets/img";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AddArtWorker from "./AddArtWorker";
import AddFilesPopup from "../Popup/AddFilesOverview";
import VariationsPopUp from "../Popup/VariationsPopUp";
import VariationsPopUpCopy from "../Popup/VariationsPopUpCopy";
import AddFilesPopupCopy from "../Popup/AddFiles";
import ImageCroper from "../Popup/ImageCroper";
import VariationsPopUpCopy2 from "../Popup/VariationsPopUpCopy2";

const PresntationImage = ({
  artWork,
  runCharge,
  setRunCharge,
  priceCharge,
  setPriceCharge,
  productData,
}) => {
  const [activeTab, setActiveTab] = useState("Supplier Decorator");
  const [addDec, setAddDec] = useState([]);
  const [Variations, setVariations] = useState(false);
  const [VariationsCopy, setVariationsCopy] = useState(false);
  const [variationType, setVariationsType] = useState("project-colors");
  const [AddFilesOverview, setAddFilesOverview] = useState(false);

  //   Croper
  const [croper, setCroper] = useState(false);
  const [croperImage, setCropImage] = useState();

  const toggleImagePopup = () => {
    setCroper(!croper);
  };

  const onTabClick = (tab) => {
    setActiveTab(tab);
  };

  const addDecoration = () => {
    let temp = [...addDec];

    temp.push({ loactions: "", image: "" });
    setAddDec(temp);
    let temps = [...runCharge];

    temps.push({
      num1: "",
      num2: "",
      num3: "",
      num4: "",
      num5: "",
      num6: "",
      num7: "",
      num8: "",
    });
    setRunCharge(temps);

    let price = [...priceCharge];

    price.push({ num1: "", num2: "", num3: "", num4: "" });
    setPriceCharge(price);
  };

  const removeDec = (e, ind) => {
    let temp = [...addDec];
    temp.splice(ind, 1);
    setAddDec(temp);

    let temps = [...runCharge];
    temps.splice(ind, 1);
    setRunCharge(temps);

    let price = [...priceCharge];
    price.splice(ind, 1);
    setPriceCharge(temp);
  };

  const handleDec = (e, index) => {
    let temp = [...addDec];
    const name = e.target.name;
    const value = e.target.value;
    temp[index][name] = value;
    setAddDec(temp);
  };

  const toggleShowPopup = (type) => {
    if (type == "copy") {
      setVariationsCopy(!VariationsCopy);
    } else {
      setVariations(!Variations);
    }
  };

  const toggleShowPopups = () => {
    setAddFilesOverview(!AddFilesOverview);
  };

  return (
    <div className="d-flex gap-2 flex-column ac-jc mb-5 w_auto_cust">
      {croper && (
        <ImageCroper
          toggleImagePopup={toggleImagePopup}
          setCropImage={setCropImage}
        />
      )}

      <div className="present-edit-img">
        <div
          className="imgs w-100 position-relative "
          // onClick={() => toggleShowPopups()}
        >
          <img
            src={
              productData?.product?.defaultImage
                ? productData?.product?.defaultImage
                : noLogo
            }
            className="img_up_cont_hover object-fit-contain"
          />
          {/* <div className="position-absolute click-to-edit">
            <span className="border-0 ">Click to Edit</span>
          </div> */}
        </div>
      </div>
      <div className="mt-3 w-100">
        <div className="d-flex mt-4 w-100 ac-jc mt-3">
          {/* <button
            onClick={() => onTabClick("Supplier Decorator")}
            style={{ cursor: "pointer" }}
            className={`${
              activeTab === "Supplier Decorator"
                ? "border_click_cust bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 "
                : "cust-btn  bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 "
            } tranc`}
          >
            <p
              className={`${
                activeTab === "Supplier Decorator" ? "primary" : "text-dark f3"
              } f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-12 fs-xl-12 fs-xxl-13 text-nowrap`}
            >
              Supplier Decorator
            </p>
          </button> */}
          {/* <button
            onClick={() => onTabClick("3rd Party Decorator")}
            style={{ cursor: "pointer" }}
            className={`${
              activeTab === "3rd Party Decorator"
                ? "border_click_cust bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 "
                : "cust-btn  bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 "
            } tranc`}
          >
            <p
              className={`${
                activeTab === "3rd Party Decorator" ? "primary" : "text-dark f3"
              } f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-12 fs-xl-12 fs-xxl-13 text-nowrap`}
            >
              3rd Party Decorator
            </p>
          </button> */}
        </div>
      </div>

      {addDec?.map((item, ind) => {
        return (
          <div className="mt-3">
            <div className="d-flex">
              <span className="editBtnSelect bg-secondary text-white border-0 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 rounded-1">
                {ind + 1}
              </span>
              <input
                className="editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-1 mx-2"
                onChange={(e) => handleDec(e, ind)}
                value={item?.loactions}
                name="loactions"
              />
              <span>
                <button
                  className="border-0 mt-2"
                  style={{ backgroundColor: "transparent" }}
                  onClick={() => removeDec(item, ind)}
                >
                  <HighlightOffIcon />
                </button>
              </span>
            </div>
            <div
              className="present-edit-img-below mt-2"
              onClick={() => artWork()}
            >
              <p className="f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-3">
                Add Artwork
              </p>
            </div>
          </div>
        );
      })}
      {/* <div className="mt-2 w-100"> */}
      {/* <div className="d-flex ac-jc">
          <div className="dropdown">
            <button
              className="btn border-0 dropdown add-tit-btn1 primary fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{ content: "none" }}
            >
              + Decoration Location
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li onClick={addDecoration}>
                <span className="dropdown-item">
                  Add new decoration location
                </span>
              </li>
              {/* <li>
                <span
                  className="dropdown-item"
                  onClick={() => toggleShowPopup("copy")}
                >
                  Copy from another product on this order
                </span>
              </li> commended 
            </ul>
          </div>
        </div> */}
      {/* <div className="mx-2 w-100">
          <div>
            <div className="d-flex justify-content-between">
              <label className="f3 fs-15 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 mt-2 text-dark">
                Product Colors and Size
              </label>
              <button
                className="cust-btn addbtn ms-1 mt-2 bg-primar text-white f2 fs-10 fs-xs-9 fs-sm-9 fs-md-9 fs-lg-9 fs-xl-10 fs-xxl-10 py-2 py-md-2 px-5 rounded"
                onClick={() => {
                  toggleShowPopup("variation");
                  setVariationsType("project-colors");
                }}
              >
                Update
              </button>
            </div>
            <span className="pres-img-up-col-siz f3 fs-15 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 mt-2 primary bg-lt-blue1">
              XL/Blue/Classic/Gold
            </span>
          </div>
          {/* <div>
            <div className="d-flex justify-content-between mt-3">
              <label className="f3 fs-15 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 mt-2 text-dark mt-2">
                Product Sizes
              </label>
              <button
                className="cust-btn addbtn ms-1 mt-2 bg-primar text-white f2 fs-10 fs-xs-9 fs-sm-9 fs-md-9 fs-lg-9 fs-xl-10 fs-xxl-10 py-2 py-md-2 px-5 rounded"
                onClick={() => {
                  toggleShowPopup("variation");
                  setVariationsType("project-size");
                }}
              >
                Update Sizes
              </button>
            </div>
            <span className="pres-img-up-col-siz f3 fs-15 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 mt-2 primary bg-lt-blue1">
              XL
            </span>
          </div> 
        </div> */}
      {/* </div> */}

      {Variations && (
        <VariationsPopUpCopy2
          toggleShowPopup={toggleShowPopup}
          variationType={variationType}
        />
      )}

      {VariationsCopy && (
        <VariationsPopUpCopy toggleShowPopup={toggleShowPopup} />
      )}
      {AddFilesOverview && (
        <AddFilesPopupCopy
          toggleImagePopup={toggleImagePopup}
          toggleShowPopup={toggleShowPopups}
          popupType={"addClient"}
        />
      )}
    </div>
  );
};

export default PresntationImage;
