import { PointOfSale } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import NearMeIcon from "@mui/icons-material/NearMe";
import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";
import HubOutlinedIcon from "@mui/icons-material/HubOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import {
  addres_list,
  department_list,
  phone_list,
  sub_menu,
  countryList,
} from "../../redux/api/DummyJson";
import { countriesList, stateList } from "../../redux/api/Country";

const AddClientContactPopupCopy = ({
  closePopup,
  submitAddress,
  btn,
  poppupToggle,
  toggleShowPopup,
  popupType,
  first_name,
  setfirst_name,
  last_name,
  setlast_name,
  address1,
  setaddress1,
  address2,
  setaddress2,
  city,
  setcity,
  state,
  setstate,
  pincode,
  setpincode,
  country,
  setcountry,
  email_id,
  setemail_id,
  first_nameErr,
  last_nameErr,
  address1Err,
  address2Err,
  cityErr,
  stateErr,
  pincodeErr,
  countryErr,
  email_idErr,
  land_markErr,
  phone_noErr,
  gstErr,
  alternative_phone_noErr,
  land_mark,
  setland_mark,
  phone_no,
  setphone_no,
  gst,
  setgst,
  alternative_phone_no,
  setalternative_phone_no,
}) => {
  const [dropDwon, setDropDown] = useState(undefined);

  const [department, setDepartment] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [listState, setListState] = useState([]);

  // Function
  const onDepartment = (e) => {
    setDepartment(e.target.value);
    department.length === 0 ? setDropDown(undefined) : setDropDown(1);
  };

  // const closePopup = () => {
  //   // toggleShowPopup();
  //   poppupToggle();
  // };

  const getState = (value) => {
    setcountry(value);
    let finder = countryList?.find((i) => i?.name == value);
    setListState(finder?.states);
  };

  return (
    <div className="modal-popup">
      <div onClick={() => closePopup()} className="back-close" />
      <div className="z_ind_cust center-content-contact2 w-90 w-md-55">
        <div className="w-100 d-flex ac-jb mt-4 px-md-5 px-3">
          <p className="f3 fs-sm-19 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 primary">
            Add Contact
          </p>
          <div className="">
            <button
              class="cust-btn addbtn_cancel bg-danger text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 mx-2 rounded"
              onClick={() => closePopup()}
            >
              Cancel
            </button>
            <button
              class={`cust-btn addbtn  addbtn_save  bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 mx-2 rounded ${
                btn ? "opacity-50" : ""
              }`}
              onClick={() => submitAddress()}
            >
              save
            </button>
          </div>
        </div>
        <div className="mt-3 overflow-scroll h_cust_res">
          <div className="w-100 px-4 px-md-5 ">
            <div className="w-100">
              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                  First Name :
                </p>
                <div className="md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100">
                  <input
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    onChange={(e) => setfirst_name(e.target.value)}
                    value={first_name}
                  />
                  {first_name?.length == 0 && first_nameErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">Enter First Name</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                  Last Name :
                </p>
                <div className="md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100">
                  <input
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    onChange={(e) => setlast_name(e.target.value)}
                    value={last_name}
                  />
                  {last_name?.length == 0 && last_nameErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">Enter Last Name</p>
                    </div>
                  )}
                </div>
              </div>

              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                  Email :
                </p>
                <div className="md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100">
                  <input
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    onChange={(e) => setemail_id(e.target.value)}
                    value={email_id}
                  />
                  {email_id?.length == 0 && email_idErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">Enter Email</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                  Address Line 1:
                </p>
                <div className="md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100">
                  <input
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    onChange={(e) => setaddress1(e.target.value)}
                    value={address1}
                  />
                  {address1?.length == 0 && address1Err && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">Enter Address Line 1</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                  Address Line 2:
                </p>
                <div className="md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100">
                  <input
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    onChange={(e) => setaddress2(e.target.value)}
                    value={address2}
                  />
                  {address2?.length == 0 && address2Err && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">Enter Address Line 2</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                  Land Mark:
                </p>
                <div className="md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100">
                  <input
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    onChange={(e) => setland_mark(e.target.value)}
                    value={land_mark}
                  />
                  {land_mark?.length == 0 && land_markErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">Enter Land Mark 2</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                  Pincode:
                </p>
                <div className="md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100">
                  <input
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    onChange={(e) => setpincode(e.target.value)}
                    value={pincode}
                    type={"number"}
                  />
                  {pincode?.length == 0 && pincodeErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">Enter Pincode</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                  City:
                </p>
                <div className="md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100">
                  <input
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    onChange={(e) => setcity(e.target.value)}
                    value={city}
                  />
                  {city?.length == 0 && cityErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">Enter City</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                  Country:
                </p>
                <div className="md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100">
                  <select
                    className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    onChange={(e) => getState(e.target.value)}
                    value={country}
                  >
                    <option>Select</option>
                    {countryList?.map((item, ind) => {
                      return (
                        <option value={item?.name} key={ind}>
                          {item?.name}
                        </option>
                      );
                    })}
                  </select>

                  {country?.length == 0 && countryErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">Enter Country</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                  State:
                </p>
                <div className="md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100">
                  <select
                    className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    onChange={(e) => setstate(e.target.value)}
                    value={state}
                  >
                    <option>Select</option>
                    {listState?.map((item, ind) => {
                      return (
                        <option value={item?.name} key={ind}>
                          {item?.name}
                        </option>
                      );
                    })}
                  </select>

                  {state?.length == 0 && stateErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">Enter State</p>
                    </div>
                  )}
                </div>
              </div>

              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                  Phone Number:
                </p>
                <div className="md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100">
                  <input
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    onChange={(e) => setphone_no(e.target.value)}
                    value={phone_no}
                    type={"number"}
                  />
                  {phone_no?.length == 0 && phone_noErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">Enter Phone Number</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                  Alternate Phone Number:
                </p>
                <div className="md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100">
                  <input
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    onChange={(e) => setalternative_phone_no(e.target.value)}
                    value={alternative_phone_no}
                    type={"number"}
                  />
                  {alternative_phone_no?.length == 0 &&
                    alternative_phone_noErr && (
                      <div className="d-flex gap-1 mt-2">
                        <ErrorIcon className="svg_log" />
                        <p className="err-input_log mt-1">
                          Enter Alternate Phone Number
                        </p>
                      </div>
                    )}
                </div>
              </div>
              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                  GST(Optional):
                </p>
                <div className="md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100">
                  <input
                    placeholder="Optional Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    onChange={(e) => setgst(e.target.value)}
                    value={gst}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddClientContactPopupCopy;
