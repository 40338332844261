import React, { useEffect, useState } from "react";
import NavbarHead from "../Sub_Layout/NavbarHead";
import EstimateHeadComp from "../EstimateComp/EstimateHeadComp";
import TableComp from "../EstimateComp/TableComp";
import { Col, Modal } from "react-bootstrap";
import TableComptwo from "../EstimateComp/TableComptwo";
import AddContactPopup from "../EstimateComp/Poppup/AddContactPopup";
import AddressPopup from "../EstimateComp/Poppup/AddressPopup";
import CompactsTableComp from "../EstimateComp/CompactsTableComp";
import NewTableComp from "../EstimateComp/NewTableComp";
import TableComp2 from "../EstimateComp/TableComp2";
import ViewStockPoppup from "../EstimateComp/ViewStockPoppup";
import DeletePopup from "../Popup/DeletePopup";
import TableDetaile from "../EstimateComp/TableDetaileComp";
import EstimateShipWarehouse from "../EstimateComp/EstimateShipWarehouse";
import AddClientContactPopupCopy from "./AddClientContactPopupCopy";
import EstimateAddress from "../EstimateComp/EstimateAddress";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  useCategoryProductViewNewMutation,
  useEstimateAddMutation,
  useEstimateEditMutation,
  useEstimateSizeMutation,
  useEstimateStatusMutation,
  useEstimateTotalUpdateMutation,
  useLazyArtworkDeleteQuery,
  useLazyCategoriesQuery,
  useLazyEstimateTaxQuery,
  useLazyMasterNoPageListQuery,
  useLazyNewCategoryViewQuery,
  useLazyPresentationDeleteQuery,
  useLazyProjectAddressNoPageListQuery,
  useLazyProjectAddressViewQuery,
  useLazyProjectViewQuery,
  usePresentationProAddMutation,
  useProjectAddressAddMutation,
  useProjectAddressEditMutation,
  useProjectAddressListMutation,
  useProjectStatusChangeMutation,
} from "../../redux/api/api";
import { saveLoader } from "../../redux/slice/loaderNoti";
import { toast } from "react-hot-toast";
import ErrorIcon from "@mui/icons-material/Error";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { saveAlertMessage } from "../../redux/slice/alertMessage";
import moment from "moment/moment";

const EstimateScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [addContactComp, setAddContactComp] = useState(false);
  const [addAddressComp, setAddAddressComp] = useState(false);
  const [actionBtn, setActionBtn] = useState(false);
  const [collaboratClick, setCollaboratClick] = useState(true);
  const [tab, setTab] = useState(1);
  const [toggleBtn1, setToggleBtn1] = useState(2);
  const [toggleBtn2, setToggleBtn2] = useState(2);
  const [statusDrop, setStatusDrop] = useState(0);
  const [addShow, setAddShow] = useState(false);
  const [deletes, setDeletes] = useState(false);
  const [viewStock, setViewStock] = useState(false);

  const [showPopup, setShowPopup] = useState(false);
  const [addTitle, setAddTitle] = useState(false);

  // RTK QUERY
  const [masterApi] = useLazyMasterNoPageListQuery();
  const [projectViewApi] = useLazyProjectViewQuery();
  const [estimateAddApi] = useEstimateAddMutation();
  const [estimateEditApi] = useEstimateEditMutation();
  const [estimateSizeApi] = useEstimateSizeMutation();
  const [estimateStatusApi] = useEstimateStatusMutation();
  const [estimateTotalUpdateApi] = useEstimateTotalUpdateMutation();
  const [projectAddressAddApi] = useProjectAddressAddMutation();
  const [projectAddressUpdateApi] = useProjectAddressEditMutation();
  const [projectAddressListApi] = useProjectAddressListMutation();
  const [projectAddressListNoPageListApi] =
    useLazyProjectAddressNoPageListQuery();
  const [projectAddressViewApi] = useLazyProjectAddressViewQuery();
  const [viewCategoryApi] = useLazyNewCategoryViewQuery();
  const [viewCategory] = useCategoryProductViewNewMutation();
  const [presentationProAddApi] = usePresentationProAddMutation();
  const [listCategory] = useLazyCategoriesQuery();
  const [artworkDeleteApi] = useLazyArtworkDeleteQuery();
  const [presentationDeleteApi] = useLazyPresentationDeleteQuery();
  const [estimateTaxApi] = useLazyEstimateTaxQuery();
  const [projectStatusChangeApi] = useProjectStatusChangeMutation();

  const [projectData, setProjectData] = useState({});
  const [projectDetail, setProjectDetail] = useState([]);
  const [projectDetails, setProjectDetails] = useState([]);
  const [btn, setBtn] = useState(false);
  const [estimate_date, setestimate_date] = useState("");
  const [estimate_dateErr, setestimate_dateErr] = useState(false);
  const [tax_percent, settax_percent] = useState("");
  const [tax_percentErr, settax_percentErr] = useState(false);
  const [start_dateErr, setstart_dateErr] = useState(false);
  const [in_hand_date, setin_hand_date] = useState("");
  const [in_hand_dateErr, setin_hand_dateErr] = useState(false);
  const [introduction, setIntroduction] = useState("");
  const [introductionErr, setIntroductionErr] = useState(false);
  const [terms_and_conditions, setTerms_and_conditions] = useState("");
  const [terms_and_conditionsErr, setTerms_and_conditionsErr] = useState(false);
  const [closing_date, setclosing_date] = useState("");
  const [closing_dateErr, setclosing_dateErr] = useState(false);
  const [presentation_style, setpresentation_style] = useState(0);
  const [presentation_styleErr, setpresentation_styleErr] = useState(false);
  const [pricing_status, setpricing_status] = useState(false);
  const [pricing_statusErr, setpricing_statusErr] = useState(false);
  const [colSpan, setColspan] = useState(1);
  const [defaultTax, setDefaultTax] = useState("");
  const [defaultTaxs, setDefaultTaxs] = useState("");
  const [defaultTaxErr, setDefaultTaxErr] = useState(false);

  const [first_name, setfirst_name] = useState("");
  const [last_name, setlast_name] = useState("");
  const [address1, setaddress1] = useState("");
  const [address2, setaddress2] = useState("");
  const [city, setcity] = useState("");
  const [state, setstate] = useState("");
  const [pincode, setpincode] = useState("");
  const [country, setcountry] = useState("");
  const [email_id, setemail_id] = useState("");
  const [land_mark, setland_mark] = useState("");
  const [phone_no, setphone_no] = useState("");
  const [gst, setgst] = useState("");
  const [alternative_phone_no, setalternative_phone_no] = useState("");
  const [type, settype] = useState("");
  const [first_nameErr, setfirst_nameErr] = useState(false);
  const [last_nameErr, setlast_nameErr] = useState(false);
  const [address1Err, setaddress1Err] = useState(false);
  const [address2Err, setaddress2Err] = useState(false);
  const [cityErr, setcityErr] = useState(false);
  const [stateErr, setstateErr] = useState(false);
  const [pincodeErr, setpincodeErr] = useState(false);
  const [countryErr, setcountryErr] = useState(false);
  const [email_idErr, setemail_idErr] = useState(false);
  const [land_markErr, setland_markErr] = useState(false);
  const [phone_noErr, setphone_noErr] = useState(false);
  const [gstErr, setgstErr] = useState(false);
  const [alternative_phone_noErr, setalternative_phone_noErr] = useState(false);
  const [listBillingAddress, setListBillingAddress] = useState([]);
  const [AddressBillingList, setAddressBillingList] = useState([]);
  const [listShippingAddress, setListShippingAddress] = useState([]);
  const [AddressShippingList, setAddressShippingList] = useState([]);

  const [catogory, setCatogory] = useState("");
  const [dropDown, setDropDown] = useState(null);
  const [searchValueCategory, setSearchValueCategory] = useState("");
  const [list, setList] = useState([]);
  const [lists, setLists] = useState([]);
  const [categoryID, setCategoryID] = useState("");
  const [categoryIDErr, setCategoryIDErr] = useState(false);
  const [category_id, setCategory_id] = useState("");
  const [parentCategory, setParentCategory] = useState("");
  const [productName, setProductName] = useState([]);
  const [prods, setProds] = useState("");
  const [productID, setProductID] = useState("");
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [subCategoryLists, setSubCategoryLists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  const [productLists, setProductLists] = useState([]);
  const [skuCodeList, setSkuCode] = useState([]);
  const [skuSearch, setSearch] = useState("");
  const [sku, setSku] = useState("");
  const [skuErr, setSkuErr] = useState(false);
  const [productData, setProductData] = useState("");
  const [skuID, setSkuID] = useState("");
  const [productsErr, setProductsErr] = useState(false);
  const [subcatogory, setSubcatogory] = useState(false);
  const [catogoryShow2, setCatogoryShow2] = useState(null);
  const [searchValueSubCategory, setSearchSubValueCategory] = useState({});
  const [catogoryName, setCatogoryName] = useState({});
  const [searchValuePro, setSearchValuePro] = useState("");
  const [secondaryCategoryId, setSecondaryCategoryId] = useState("");
  const [customer_po, setcustomer_po] = useState("");
  const [skuList, setSkuList] = useState([]);
  const [status, setStatus] = useState(0);

  const [subtotal, setSubtotal] = useState("");
  const [gstFinal, setGstFinal] = useState("");
  const [gstTax, setGstTax] = useState("");
  const [discount, setDiscount] = useState("");
  const [totalFinal, setTotalFinal] = useState("");
  const [igst, setigst] = useState("");
  const [sgst, setsgst] = useState("");
  const [cgst, setcgst] = useState("");
  const [discount_percent, setdiscount_percent] = useState("");

  const [tax_percentGst, settax_percentGst] = useState("");
  const [tax_percentGst1, settax_percentGst1] = useState("");
  const [billingAddress, setBillingAddress] = useState({});
  const [mainShipAddress, setMainShipAddress] = useState({});

  // OTHER INFORMATION START
  const [dynamicAttr, setDynamicAttr] = useState([]);
  const [listAttributes, setListAttributes] = useState([]);
  const [selectALLProInfo, setSelectALLProInfo] = useState(true);
  const [attributesList, setAttributesList] = useState([]);
  const [otherProInfo, setOtherProInfo] = useState("");
  const [productInformation, setProductInformation] = useState([]);
  const [searchProInfo, setSearchProInfo] = useState("");
  const [otherProInfoErr, setOtherProInfoErr] = useState(false);
  const [productInformationErr, setProductInformationErr] = useState(false);
  // OTHER INFORMATION END

  // OTHER INFORMATION START
  const allSelectProductInfo = () => {
    let ListId = [];
    let ListName = [];
    if (selectALLProInfo) {
      attributesList?.map((item, ind) => {
        ListId.push({ id: item?.id, title: item?.name, value: "" });
        ListName.push(item?.name);
        setOtherProInfo(item?.id);
      });

      setProductInformation(ListId);
      setDynamicAttr(ListName);
    } else {
      setProductInformation(ListId);
      setDynamicAttr(ListName);
      setOtherProInfo("");
    }

    setSelectALLProInfo(!selectALLProInfo);
  };

  const searchProductInfoFun = (event) => {
    let temp = [...attributesList];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setListAttributes(search);
    } else {
      setListAttributes(attributesList);
    }

    setSearchProInfo(event);
  };

  const onDynamicAttributeSelect = (event, id, ind) => {
    let temp = [...productInformation];

    let finder = temp.find((i) => i.id == id);

    if (finder?.id) {
      let inx = temp.findIndex((i) => i.id == id);
      temp.splice(inx, 1);
    } else {
      temp.push({
        id: id,
        title: event,
        value: "",
      });
    }

    setProductInformation(temp);

    if (dynamicAttr.includes(event)) {
      const listtemp = dynamicAttr?.indexOf(event);
      const list = [...dynamicAttr];
      list.splice(listtemp, 1);
      setDynamicAttr(list);
    } else {
      setDynamicAttr([...dynamicAttr, event]);
    }

    setListAttributes(attributesList);
    setSearchProInfo("");

    if (otherProInfo == id) {
      setOtherProInfo("");
    } else {
      setOtherProInfo(id);
    }
  };

  // handle Product Information
  const handleProductInformation = (event, ind) => {
    let temp = [...productInformation];
    temp[ind]["value"] = event;
    setProductInformation(temp);
  };

  // OTHER INFORMATION END

  // ESTIMATE STATUS
  const estimateStatus = (id, status) => {
    let formdata = new FormData();
    formdata.append("project_detail_id", id);
    formdata.append("estimate_status", status);
    dispatch(saveLoader(true));
    estimateStatusApi(formdata)
      .unwrap()
      .then((res) => {
        dispatch(saveLoader(false));
        if (res?.status == "success") {
          getProjectView();
        }
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        console.log("err", err);
      });
  };

  const getList = () => {
    listCategory()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setList(res?.categories);
          setLists(res?.categories);
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  useEffect(() => {
    // getList();
  }, []);

  // ARTWORK DELETE API
  const artworkDelete = (id) => {
    dispatch(saveLoader(true));
    artworkDeleteApi(id)
      .unwrap()
      .then((res) => {
        dispatch(saveLoader(false));
        if (res?.status == "success") {
          getProjectView();
        }
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        console.log("err", err);
      });
  };

  // SEARCH CATEGROY
  const searchCategory = (event) => {
    let temp = [...lists];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setList(search);
    } else {
      setList(lists);
    }

    setSearchValueCategory(event);
  };
  // SELECT CATEGORY
  const onCategorySelct = (item) => {
    setCategoryID(item?.id);
    setCatogory(item?.name);
    setSubcatogory(true);
    getCategoryId(item?.id, "category");
    setList(lists);
    setCatogoryName({});
    setDropDown(null);
    setSkuCode(skuCodeList);
    setProductID("");
    setProds("");
    setSkuList([]);
    setSku("");
    // setParentCategory(item?.id);
  };

  // GET PARENT ID FOR SUB CATEGORY LIST
  const getCategoryId = (id, type, indx, index, data) => {
    if (type == "category") {
      setCategory_id(id);
      setParentCategory(id);
      getView(id, "", "", type, "");
    } else {
      // console.log("lll");
      if (index == 0) {
        setCategory_id(id);
        // console.log("kkkkk");
      }
      onSubCatSelect(id, indx, index, data);
    }
    setProductName([]);

    // setProds((val) => ({ ...val, [ind]: {} }));
    setProductID("");
  };
  // SLICE ARRAY
  const onSubCatSelect = (id, ind, index, data) => {
    if (index < data?.length - 1) {
      let temp = [...data];
      let slicer = temp.slice(0, index + 1);
      setSubCategoryList(slicer);

      let temps = [...subCategoryLists];
      let slicers = temps.slice(0, index + 1);
      setSubCategoryLists(slicers);

      getView(id, ind, index, "sub_category", slicer);
    } else {
      getView(id, ind, index, "", data);
    }

    setProductID("");
    setProds("");
    setSkuList([]);
    setSku("");
  };
  // SUB CATEGORY SEARCH
  const searchSubCatFun = (event, ind) => {
    let temp = [...subCategoryLists];
    let tempX = temp[ind];
    let temps = [...subCategoryList];
    let tempXs = temps[ind];
    let search = tempX?.child_categories?.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    // console.log("search", search);

    tempXs = search;

    let obj = {
      ...subCategoryList[ind],
      child_categories: tempXs,
    };

    let arr = [...subCategoryList];
    arr[ind] = obj;

    // console.log("tempXs", obj, arr);

    if (event?.length > 0) {
      setSubCategoryList(arr);
    } else {
      // console.log("jjj");
      setSubCategoryList(subCategoryLists);
    }

    setSearchSubValueCategory((val) => ({ ...val, [ind]: event }));
  };
  // SELECT CHILD CATEGORY
  const onSubCategorySelect = (subItem, indx, inx) => {
    setSearchSubValueCategory((val) => ({ ...val, [inx]: "" }));
    let temp = [...subCategoryList];
    // console.log("temp[inx]", temp[inx], subCategoryLists[inx]);
    temp[inx] = subCategoryLists[inx];
    // console.log("temp", temp);
    setSubCategoryList(temp);
    setCatogoryShow2(null);
    getCategoryId(subItem?.id, "sub_category", indx, inx, temp);
    setCatogoryName((val) => ({
      ...val,
      [subItem?.category?.id]: subItem?.name,
    }));

    setProductName([]);

    if (inx == 0) {
      setSecondaryCategoryId(subItem?.id);
    }
    // setProds((val) => ({ ...val, [ind]: {} }));
    setProductID("");
  };
  // CHILD CATEGORY VIEW API AND 2D DIMENTION ARRAY CREATE
  const getView = (id, inx, index, type, data) => {
    setLoading(true);
    viewCategoryApi(id)
      .unwrap()
      .then((res) => {
        let child = [];
        res?.child_categories?.map((item, ind) => {
          child.push({
            ...item,
            category: {
              ...res?.category,
            },
          });
        });

        let responce = {
          category: res?.category,
          child_categories: child,
        };

        let temp = [];
        let temps = [];
        if (data?.length > 0) {
          temp = [...data];
          temps = [...data];
        } else if (type !== "category") {
          temp = [...subCategoryList];
          temps = [...subCategoryLists];
        }

        if (type == "subSumit") {
          let temX = responce?.child_categories;

          let obj = {
            ...temp[index],
            child_categories: temX,
          };

          let final = subCategoryList?.filter((i) => i?.category?.id !== id);
          final.splice(index, 0, obj);

          let finals = subCategoryLists?.filter((i) => i?.category?.id !== id);
          finals.splice(index, 0, obj);

          setSubCategoryList(final);
          setSubCategoryLists(finals);

          let productsArr = [];

          finals?.map((item, ind) => {
            item?.child_categories?.map((chi, indx) => {
              chi?.product?.map((pro, inx) => {
                productsArr.push(pro);
              });
            });
          });
          // console.log("finals", finals);
          setProductLists(productsArr);
          setProductList(productsArr);

          setLoading(false);
        } else {
          let finder = temp.indexOf(id);

          if (temp?.includes(responce)) {
            temp.slice(0, 1);
            temp.push(responce);
          } else {
            temp.push(responce);
          }

          if (temps?.includes(responce)) {
            temps.slice(0, 1);
            temps.push(responce);
          } else {
            temps.push(responce);
          }

          setSubCategoryList(temp);
          setSubCategoryLists(temps);

          let productsArr = [];

          temps?.map((item, ind) => {
            item?.child_categories?.map((chi, indx) => {
              chi?.product?.map((pro, inx) => {
                productsArr.push(pro);
              });
            });
          });
          console.log("temps", temps);
          setProductLists(productsArr);
          setProductList(productsArr);
          setLoading(false);
          getPro(id);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
    setProductName([]);

    // setProds((val) => ({ ...val, [ind]: {} }));
    setProductID("");
  };

  const searchPro = (event) => {
    let temp = [...productLists];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setProductList(search);
    } else {
      setProductList(productLists);
    }

    setSearchValuePro(event);
  };

  //PRODUCT
  const getProducts = (primary_category_id, secondary_category_id, id) => {
    let formdata = new FormData();
    formdata.append("primary_category_id", primary_category_id);
    formdata.append("secondary_category_id", secondary_category_id);
    viewCategory({ id: id, payload: formdata })
      .unwrap()
      .then((res) => {
        setProductLists(res?.products);
        setProductList(res?.products);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getPro = (id) => {
    if (categoryID && secondaryCategoryId) {
      getProducts(categoryID, secondaryCategoryId, id);
    }
  };

  const onProSelect = (event, id, type, item) => {
    setProductID(id);
    setProds(event);
    setProductData(item);
    let name = [];
    item?.productSku?.map((skulist, ind) => {
      if (skulist?.status == 1) {
        name?.push(skulist);
      }
    });
    setSkuList(name);

    setDropDown(null);
  };

  const skuSelect = (event) => {
    setSku(event);
  };

  const poppupToggle = () => {
    setAddContactComp(!addContactComp);
  };
  const poppupAddressToggle = () => {
    setAddAddressComp(!addAddressComp);
  };

  const onLinkShow = () => {
    setActionBtn(!actionBtn);
  };
  const onCollaborat = () => {
    setCollaboratClick(!collaboratClick);
  };
  const viewStockToggle = () => {
    setViewStock(!viewStock);
  };

  const deleteToggle = (value) => {
    setDeletes(!deletes);
    if (value == 2) {
      estimateDelete(status);
    }
  };

  const getTax = (item) => {
    let project_code = searchParams?.get("project");
    if (project_code) {
      let split = project_code.split("_");
      estimateTaxApi(split[1])
        .unwrap()
        .then((res) => {
          if (res?.max_igst) {
            setigst(res?.max_igst);
            setGstTax(res?.max_igst);
            totalUpdate(discount_percent, res?.max_igst, "", item);
          } else {
            setsgst(res?.max_sgst);
            setcgst(res?.max_cgst);
            setGstTax(res?.max_sgst + res?.max_cgst);
            totalUpdate(discount_percent, res?.max_sgst, res?.max_cgst, item);
          }
          if (item?.id) {
            let temp = item?.projectDetail?.reduce(
              (n, { retail_price }) => n + retail_price,
              0
            );
            setSubtotal(Number(temp).toFixed(2));
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  // PROJECT VIEW API
  const getProjectView = () => {
    let project_code = searchParams?.get("project");
    if (project_code) {
      let split = project_code.split("_");
      dispatch(saveLoader(true));
      projectViewApi(split[1])
        .unwrap()
        .then((res) => {
          dispatch(saveLoader(false));
          if (res?.status == "success") {
            let data = res?.project;
            let findDate = data?.projectDate?.find((i) => i?.type == 5);
            if (data?.status >= 5 && findDate?.type == 5) {
              setProjectData(res?.project);
              let arr = [];

              data?.projectDetail?.map((item, ind) => {
                arr.push(item?.projectArtwork?.length);
              });

              let maxValue = Math.max(...arr);
              setColspan(maxValue);

              let filter = data?.projectDetail?.filter(
                (i) => i?.estimate_status >= 0 && i?.estimate_status <= 3
              );
              setProjectDetail(filter);
              setToggleBtn2(2);
              setProjectDetails(data?.projectDetail);

              if (data?.tax_type == 2) {
                if (data?.tax_percent) {
                  if (data?.tax1_percent) {
                    settax_percentGst1(data?.tax1_percent);
                  }
                  settax_percentGst(data?.tax_percent);
                  setGstTax(data?.tax_percent);
                  setDiscount(data?.discount_amount);
                  setdiscount_percent(data?.discount_percent);
                } else {
                  getTax(data);
                }
              } else if (data?.tax_type == 1) {
                let temp = data?.projectDetail?.reduce(
                  (n, { retail_price }) => n + retail_price,
                  0
                );
                setSubtotal(Number(temp).toFixed(2));
                setGstTax(data?.tax_percent + data?.tax1_percent);
                setGstFinal(data?.tax_total_amount);
                setTotalFinal(data?.total_amount);
              }

              if (data?.in_hand_date) {
                setin_hand_date(data?.in_hand_date);
              }

              if (data?.closing_date) {
                setclosing_date(data?.closing_date);
              }

              if (data?.terms_condition) {
                setTerms_and_conditions(data?.terms_condition);
              }

              if (data?.introduction) {
                setIntroduction(data?.introduction);
              }

              if (data?.presentation_style) {
                setpresentation_style(data?.presentation_style);
              }

              if (data?.pricing_status == 1) {
                setpricing_status(true);
              } else {
                setpricing_status(false);
              }

              if (data?.projectDate?.length > 0) {
                let finder = data?.projectDate?.filter((i) => i?.type == 5);
                setestimate_date(
                  moment(finder?.created_date).format("YYYY-MM-DD")
                );
              } else {
                setestimate_date(moment(new Date()).format("YYYY-MM-DD"));
              }

              if (data?.tax_type) {
                setDefaultTax(data?.tax_type);
                setDefaultTaxs(data?.tax_type);
              }

              if (data?.customer_po) {
                setcustomer_po(data?.customer_po);
              }

              let productAttributesName = [];
              let productAttributesID = [];
              data?.EstimateProjectOtherInfo?.map((item, ind) => {
                productAttributesName.push(item?.master?.name);
                setOtherProInfo(item?.id);
                productAttributesID.push({
                  info_id: item?.id,
                  id: item?.master?.id,
                  title: item?.master?.name,
                  value: item?.value,
                });
              });
              setDynamicAttr(productAttributesName);
              setProductInformation(productAttributesID);
            } else if (
              res?.project?.status == 3 ||
              res?.project?.status == 4 ||
              (res?.project?.status == 5 && findDate?.type !== 5)
            ) {
              dispatch(saveAlertMessage("Presentation Not Completed"));
              setTimeout(() => {
                navigate(
                  `/presentation?project=${searchParams?.get("project")}`
                );
                saveAlertMessage(null);
              }, 1500);
            } else {
              dispatch(saveAlertMessage("Overview Not Completed"));
              setTimeout(() => {
                navigate(`/overview?project=${searchParams?.get("project")}`);
                saveAlertMessage(null);
              }, 1500);
            }
          }
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          console.log("err", err);
        });
    }
  };

  // GET MASTER
  const getMaster = () => {
    masterApi()
      .unwrap()
      .then((res) => {
        setListAttributes(res?.estimate_attributes);
        setAttributesList(res?.estimate_attributes);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // GET ADDRESS
  const getAddressList = () => {
    let project_code = searchParams?.get("project");
    let split = project_code.split("_");
    projectAddressListNoPageListApi(split[1])
      .unwrap()
      .then((res) => {
        let billig = res?.data?.filter((i) => i?.type == 2);
        let shipping = res?.data?.filter((i) => i?.type == 1);
        setListBillingAddress(billig);
        setBillingAddress(billig?.[0]);
        setAddressBillingList(billig);
        setListShippingAddress(shipping);
        setAddressShippingList(shipping);
        setMainShipAddress(shipping?.[shipping?.length - 1]);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    let project_code = searchParams?.get("project");
    if (project_code) {
      getProjectView();
      getAddressList();
    }

    getMaster();
  }, []);

  // ESTIMATE STORE
  const submitHandle = () => {
    let check;

    if (productInformation?.length > 0) {
      productInformation?.map((item, ind) => {
        check = Object.values(item).some((x) => x === " " || x === "");
      });
    }

    if (
      // estimate_date?.length == 0 ||
      in_hand_date?.length == 0 ||
      closing_date?.length == 0 ||
      introduction?.length == 0 ||
      terms_and_conditions?.length == 0 ||
      presentation_style == 0 ||
      defaultTax?.length == 0 ||
      check == true
    ) {
      setDefaultTaxErr(true);
      setProductInformationErr(true);
      // setestimate_dateErr(true);
      setin_hand_dateErr(true);
      setclosing_dateErr(true);
      setIntroductionErr(true);
      setTerms_and_conditionsErr(true);
      setpresentation_styleErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("project_id", projectData?.id);
      formdata.append("project_request_id", projectData?.project_request?.id);
      // formdata.append("estimate_date", estimate_date);
      formdata.append("terms_condition", terms_and_conditions);
      formdata.append("introduction", introduction);
      formdata.append("in_hand_date", in_hand_date);
      formdata.append("presentation_style", presentation_style);

      if (defaultTaxs) {
        formdata.append("closing_date", closing_date);
      } else {
        formdata.append("tax_type", defaultTax);
        formdata.append("closing_date", closing_date);
      }

      if (customer_po) {
        formdata.append("customer_po", customer_po);
      }

      if (productInformation?.length > 0) {
        productInformation?.map((item, ind) => {
          formdata.append(`informations[${ind}][master_id]`, item?.id);
          formdata.append(`informations[${ind}][value]`, item?.value);
        });
      }

      dispatch(saveLoader(true));
      setBtn(true);
      estimateAddApi(formdata)
        .unwrap()
        .then((res) => {
          dispatch(saveLoader(false));
          setBtn(false);
          if (res?.status == "success") {
            toast.success(res?.message);
            getProjectView();
          }
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          setBtn(false);
          console.log("err", err);
        });
    }
  };

  const showAddress = (value) => {
    settype(value);
    setAddContactComp(true);
  };

  // STORE ADDRESS
  const submitAddress = () => {
    if (
      address1?.length == 0 ||
      address2?.length == 0 ||
      land_mark?.length == 0 ||
      pincode?.length == 0 ||
      city?.length == 0 ||
      state?.length == 0 ||
      country?.length == 0 ||
      phone_no?.length == 0 ||
      alternative_phone_no?.length == 0 ||
      first_name?.length == 0 ||
      last_name?.length == 0 ||
      email_id?.length == 0
    ) {
      setaddress1Err(true);
      setaddress2Err(true);
      setland_markErr(true);
      setpincodeErr(true);
      setcityErr(true);
      setstateErr(true);
      setphone_noErr(true);
      setalternative_phone_noErr(true);
      setfirst_nameErr(true);
      setlast_nameErr(true);
      setemail_idErr(true);
      setcountryErr(true);
    } else {
      let formdata = new FormData();

      formdata.append("project_id", projectData?.id);
      formdata.append("first_name", first_name);
      formdata.append("last_name", last_name);
      formdata.append("address1", address1);
      formdata.append("address2", address2);
      formdata.append("city", city);
      formdata.append("state", state);
      formdata.append("pincode", pincode);
      formdata.append("country", country);
      formdata.append("email_id", email_id);
      formdata.append("landmark", land_mark);
      formdata.append("phone_no", phone_no);
      formdata.append("alter_phone_no", alternative_phone_no);
      formdata.append("type", type);

      if (gst) {
        formdata.append("gst_no", gst);
      }

      dispatch(saveLoader(true));
      setBtn(true);
      projectAddressAddApi(formdata)
        .unwrap()
        .then((res) => {
          dispatch(saveLoader(false));
          setBtn(false);
          getAddressList();
          closePopup();
          toast.success(res?.message);
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          setBtn(false);
          console.log("err", err);
        });
    }
  };

  const closePopup = () => {
    setaddress1Err(false);
    setaddress2Err(false);
    setland_markErr(false);
    setpincodeErr(false);
    setcityErr(false);
    setstateErr(false);
    setphone_noErr(false);
    setalternative_phone_noErr(false);
    setfirst_nameErr(false);
    setlast_nameErr(false);
    setemail_idErr(false);
    setcountryErr(false);
    setgstErr(false);
    settype("");
    setfirst_name("");
    setlast_name("");
    setaddress1("");
    setaddress2("");
    setcity("");
    setstate("");
    setpincode("");
    setcountry("");
    setemail_id("");
    setland_mark("");
    setphone_no("");
    setgst("");
    setalternative_phone_no("");
    setAddContactComp(false);
  };

  const closeAddPro = () => {
    setCatogory("");
    setShowPopup(false);
    setDropDown(null);
    setCategoryIDErr(false);
    setSubcatogory(false);
    setSubCategoryList([]);
    setCatogoryShow2(null);
    setProds("");
    setProductID("");
    setProductList([]);
    setProductLists([]);
    setProductsErr(false);
    setSkuList([]);
    setSku("");
    setSkuErr(false);
    setShowPopup(false);
  };

  // FOR ADD PRODUCT

  const submitHandleProduct = () => {
    if (productID?.length == 0 || sku?.length == 0 || categoryID?.length == 0) {
      setProductsErr(true);
      setSkuErr(true);
      setCategoryIDErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("project_id", projectData?.id);
      formdata.append("product_id", productID);
      formdata.append("product_sku_id", sku);
      formdata.append("no_of_colors", 1);
      formdata.append("qty", 1);
      formdata.append("final_amount", productData?.for_25);

      dispatch(saveLoader(true));
      setBtn(true);
      presentationProAddApi(formdata)
        .unwrap()
        .then((res) => {
          dispatch(saveLoader(false));
          setBtn(false);
          closeAddPro();
          getProjectView();
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          setBtn(false);
          console.log("err", err);
        });
    }
  };

  // ESTIMATE DELETE API
  const estimateDelete = (id) => {
    dispatch(saveLoader(true));
    presentationDeleteApi(id)
      .unwrap()
      .then((res) => {
        dispatch(saveLoader(false));
        if (res?.status == "success") {
          getProjectView();
        }
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        console.log("err", err);
      });
  };

  const showChangeHandler = (value) => {
    if (value == 2) {
      let filter = projectDetails?.filter(
        (i) => i?.estimate_status >= 0 && i?.estimate_status <= 3
      );
      setProjectDetail(filter);
    } else {
      setProjectDetail(projectDetails);
    }
    setToggleBtn2(value);
  };

  const statusChangeHandler = (value) => {
    if (value == 0) {
      setProjectDetail(projectDetails);
    } else {
      let filter = projectDetails?.filter((i) => i?.artwork_status == +value);
      setProjectDetail(filter);
    }
    setStatusDrop(value);
  };

  const totalUpdate = (value, tax_percent, tax1_percent, item) => {
    let formdata = new FormData();
    formdata.append("project_id", item?.id);

    let temp = item?.projectDetail?.reduce(
      (n, { retail_price }) => n + retail_price,
      0
    );

    if (tax1_percent) {
      let gsts = tax1_percent + tax_percent;
      let sum = (temp * gsts) / 100 + temp;

      formdata.append("tax1_percent", Number(tax1_percent).toFixed(2));
      formdata.append("tax_percent", Number(tax_percent).toFixed(2));
      formdata.append("tax_amount", Number(sum).toFixed(2));
    } else {
      let sum = (temp * tax_percent) / 100 + temp;
      formdata.append("tax_percent", Number(tax_percent).toFixed(2));
      formdata.append("tax_amount", Number(sum).toFixed(2));
    }

    if (value) {
      let sum = (temp * value) / 100 + temp;
      formdata.append("discount_percent", value);
      formdata.append("discount_amount", sum);
      setdiscount_percent(value);
      setDiscount(sum);
    } else {
      setDiscount("");
    }
    setdiscount_percent(value);
    settax_percentGst(tax_percent);
    settax_percentGst1(tax1_percent);
    dispatch(saveLoader(true));
    estimateTotalUpdateApi(formdata)
      .unwrap()
      .then((res) => {
        dispatch(saveLoader(false));
        getProjectView();
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        console.log("err", err);
      });
  };

  // PROJECT STATUS API
  const projectStatus = (status) => {
    let count = 1;
    // if (type == "estimate" && projectData?.status <= 4) {
    //   count = 2;
    //   dispatch(saveAlertMessage("User Not Approved"));
    // }

    let formdata = new FormData();
    formdata.append("project_id", projectData?.id);
    formdata.append("status", status);
    if (count == 1) {
      dispatch(saveLoader(true));
      projectStatusChangeApi(formdata)
        .unwrap()
        .then((res) => {
          dispatch(saveLoader(false));
          if (res?.status == "success") {
            // if (type == "estimate") {
            //   navigate(`/estimate?project=${searchParams?.get("project")}`);
            //   setActionBtn(false);
            // } else {
            getProjectView();
            // }
          }
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          console.log("err", err);
        });
    }

    setActionBtn(false);
  };

  return (
    <div className="dashRightView2 overview_pages overflow-scroll ">
      {viewStock && <ViewStockPoppup viewStockToggle={viewStockToggle} />}
      {deletes && (
        <DeletePopup deleteToggle={deleteToggle} setAddTitle={setAddTitle} />
      )}
      <div className=" d-flex justify-content-end position-relative mb-2">
        <div className="action_overview mx-4 mb-4">
          <NavbarHead onLinkShow={onLinkShow} projectData={projectData} />
          {actionBtn && (
            <div
              className="invisible-cont2"
              style={{ zIndex: 200 }}
              onClick={() => setActionBtn(false)}
            />
          )}
          {actionBtn && (
            <div className="action_btn ml-1" style={{ zIndex: 300 }}>
              <button
                className="border-0 bg-white text-start f2 text-dark fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100"
                onClick={() => {
                  setActionBtn(false);
                }}
              >
                Copy Estimate
              </button>
              <button
                onClick={() => {
                  setActionBtn(false);
                  navigate("/estimation-page-a", {
                    state: { data: projectData },
                  });
                }}
                className="border-0 bg-white text-start f2 text-dark fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 py-2 px-2 hover_eff w-100"
              >
                Preview Estimate
              </button>
              <button
                onClick={() => setActionBtn(false)}
                className="border-0 bg-white text-start f2 text-dark fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100"
              >
                Email Estimate
              </button>
              <button
                onClick={() => setActionBtn(false)}
                className="border-0 text-danger bg-white text-start f2 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100"
              >
                Delete Estimate
              </button>
              <button
                onClick={() => setActionBtn(false)}
                className="border-0 bg-white text-start f2 text-dark fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100"
              >
                Create Sales Order
              </button>
              <button
                onClick={() => setActionBtn(false)}
                className="border-0 bg-white text-start f2 text-dark fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100"
              >
                View Portal
              </button>
            </div>
          )}
        </div>
      </div>
      {addContactComp && (
        <AddClientContactPopupCopy
          closePopup={closePopup}
          poppupToggle={poppupToggle}
          first_name={first_name}
          setfirst_name={setfirst_name}
          last_name={last_name}
          setlast_name={setlast_name}
          address1={address1}
          setaddress1={setaddress1}
          address2={address2}
          setaddress2={setaddress2}
          city={city}
          setcity={setcity}
          state={state}
          setstate={setstate}
          pincode={pincode}
          setpincode={setpincode}
          country={country}
          setcountry={setcountry}
          email_id={email_id}
          setemail_id={setemail_id}
          land_mark={land_mark}
          setland_mark={setland_mark}
          phone_no={phone_no}
          setphone_no={setphone_no}
          gst={gst}
          setgst={setgst}
          alternative_phone_no={alternative_phone_no}
          setalternative_phone_no={setalternative_phone_no}
          first_nameErr={first_nameErr}
          last_nameErr={last_nameErr}
          address1Err={address1Err}
          address2Err={address2Err}
          cityErr={cityErr}
          stateErr={stateErr}
          pincodeErr={pincodeErr}
          countryErr={countryErr}
          email_idErr={email_idErr}
          land_markErr={land_markErr}
          phone_noErr={phone_noErr}
          gstErr={gstErr}
          alternative_phone_noErr={alternative_phone_noErr}
          submitAddress={submitAddress}
          btn={btn}
        />
      )}
      {addAddressComp && (
        <AddressPopup poppupAddressToggle={poppupAddressToggle} />
      )}
      <EstimateHeadComp
        statusChangeHandler={statusChangeHandler}
        statusDrop={statusDrop}
        showAddress={showAddress}
        onCollaborat={onCollaborat}
        collaboratClick={collaboratClick}
        setAddContComp={setAddContactComp}
        poppupAddressToggle={poppupAddressToggle}
        handleProductInformation={handleProductInformation}
        productInformationErr={productInformationErr}
        productInformation={productInformation}
        dynamicAttr={dynamicAttr}
        searchProductInfoFun={searchProductInfoFun}
        searchProInfo={searchProInfo}
        listBillingAddress={listBillingAddress}
        setListBillingAddress={setListBillingAddress}
        AddressBillingList={AddressBillingList}
        listShippingAddress={listShippingAddress}
        setListShippingAddress={setListShippingAddress}
        AddressShippingList={AddressShippingList}
        allSelectProductInfo={allSelectProductInfo}
        listAttributes={listAttributes}
        onDynamicAttributeSelect={onDynamicAttributeSelect}
        otherProInfo={otherProInfo}
        otherProInfoErr={otherProInfoErr}
        setIntroduction={setIntroduction}
        introduction={introduction}
        introductionErr={introductionErr}
        setTerms_and_conditions={setTerms_and_conditions}
        terms_and_conditions={terms_and_conditions}
        terms_and_conditionsErr={terms_and_conditionsErr}
        setin_hand_date={setin_hand_date}
        in_hand_date={in_hand_date}
        in_hand_dateErr={in_hand_dateErr}
        setclosing_date={setclosing_date}
        closing_dateErr={closing_dateErr}
        closing_date={closing_date}
        setestimate_date={setestimate_date}
        estimate_date={estimate_date}
        estimate_dateErr={estimate_dateErr}
        setDefaultTax={setDefaultTax}
        defaultTax={defaultTax}
        defaultTaxs={defaultTaxs}
        defaultTaxErr={defaultTaxErr}
        setcustomer_po={setcustomer_po}
        customer_po={customer_po}
        setBillingAddress={setBillingAddress}
        billingAddress={billingAddress}
        mainShipAddress={mainShipAddress}
        setMainShipAddress={setMainShipAddress}
      />
      <div className="d-flex justify-content-end">
        <button
          className={`cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded ${
            btn && "opacity-50"
          }`}
          onClick={() => {
            submitHandle();
          }}
          disabled={btn ? true : false}
        >
          Submit
        </button>
      </div>
      <div className="mt-3 d-flex ac-jb mt-0 mt-md-3 flex-column flex-md-row">
        <div className="d-flex ac-js">
          <button
            onClick={() => setTab(1)}
            className={`${
              tab == 1 ? "border_click_cust" : "cust-btn "
            } bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 `}
          >
            Products
          </button>
          <button
            onClick={() => setTab(2)}
            className={`${
              tab == 2 ? "border_click_cust" : "cust-btn "
            } bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 `}
          >
            Artwork
          </button>
          <button
            onClick={() => setTab(3)}
            className={`${
              tab == 3 ? "border_click_cust" : "cust-btn "
            } bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 `}
          >
            Shipping and Warehouse
          </button>
          <button
            onClick={() => setTab(4)}
            className={`${
              tab == 4 ? "border_click_cust" : "cust-btn "
            } bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 `}
          >
            Address
          </button>
        </div>
        {(tab == 1 || tab == 2) && (
          <div className="d-flex justify-conten-center align-items-center gap-2">
            <input
              type={"checkbox"}
              onClick={() => projectStatus(6)}
              checked={projectData?.status == 6 ? true : false}
              disabled={projectData?.status == 6 ? true : false}
            />
            <p className="">Show to Estimate Customer</p>
          </div>
        )}
        <div className="d-flex ac-jc gap-3 mt-3 mt-md-0 flex-column flex-sm-row ">
          {tab == 1 && (
            <div className="d-flex">
              <button
                onClick={() => setToggleBtn1(1)}
                className={` ${
                  toggleBtn1 === 1 && "primary bg-lt-blue"
                } editBtn bg-transparent text-nowrap rounded_cust_left py-2 px-3 f1 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 `}
              >
                Compacts
              </button>
              <button
                onClick={() => setToggleBtn1(2)}
                className={` ${
                  toggleBtn1 === 2 && "primary bg-lt-blue"
                } editBtn bg-transparent text-nowrap rounded_cust_right border-start-0 py-2 px-3 f1 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 `}
              >
                Detailed
              </button>
            </div>
          )}
          <div className="d-flex">
            <button
              onClick={() => showChangeHandler(1)}
              className={` ${
                toggleBtn2 === 1 && "primary bg-lt-blue"
              } editBtn bg-transparent text-nowrap rounded_cust_left py-2 px-3 f1 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 `}
            >
              Show All
            </button>
            <button
              onClick={() => showChangeHandler(2)}
              className={` ${
                toggleBtn2 === 2 && "primary bg-lt-blue"
              } editBtn bg-transparent text-nowrap rounded_cust_right border-start-0 py-2 px-3 f1 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 `}
            >
              Show Active
            </button>
          </div>
        </div>
      </div>
      {/* <TableComp /> */}
      {tab === 1 && toggleBtn1 === 1 && (
        <TableComp2
          setAddTitle={setAddTitle}
          toggleBtn1={toggleBtn1}
          tab={tab}
          projectData={projectData}
          deleteToggle={deleteToggle}
          viewStockToggle={viewStockToggle}
          projectDetail={projectDetail}
          setProjectDetail={setProjectDetail}
          setShowPopup={setShowPopup}
          estimateStatus={estimateStatus}
          status={status}
          setStatus={setStatus}
          setDeletes={setDeletes}
          deletes={deletes}
        />
      )}
      {tab === 1 && toggleBtn1 !== 1 && (
        <TableDetaile
          setAddTitle={setAddTitle}
          toggleBtn1={toggleBtn1}
          tab={tab}
          projectData={projectData}
          deleteToggle={deleteToggle}
          viewStockToggle={viewStockToggle}
          projectDetail={projectDetail}
          setProjectDetail={setProjectDetail}
          setShowPopup={setShowPopup}
          estimateStatus={estimateStatus}
          status={status}
          setStatus={setStatus}
          setDeletes={setDeletes}
          deletes={deletes}
        />
      )}{" "}
      {tab === 3 && toggleBtn1 !== 1 && <EstimateShipWarehouse />}
      {tab === 4 && toggleBtn1 !== 1 && (
        <EstimateAddress
          getAddressList={getAddressList}
          getTax={getTax}
          getProjectView={getProjectView}
        />
      )}
      {/* {tab === 1 && toggleBtn1 === 1 && <TableComp toggleBtn1={toggleBtn1} />} */}
      {/* {tab === 1 && toggleBtn1 !== 1 &&  */}
      {/* <CompactsTableComp /> */}
      {tab === 2 && (
        <TableComptwo
          setAddTitle={setAddTitle}
          toggleBtn1={toggleBtn1}
          tab={tab}
          projectData={projectData}
          deleteToggle={deleteToggle}
          viewStockToggle={viewStockToggle}
          projectDetail={projectDetail}
          setProjectDetail={setProjectDetail}
          colSpan={colSpan}
          setColspan={setColspan}
          getProjectView={getProjectView}
          setShowPopup={setShowPopup}
          artworkDelete={artworkDelete}
        />
      )}
      {/* 
      <div className="h-100 w-100">
        <NewTableComp />
      </div> */}
      {addTitle && (
        <div className="presen-list mb-3">
          <div className="add-tit-btn presen-detail presen-list mt-3">
            <div className="m-2">
              <input
                placeholder="Add a title"
                className="w-100 b-color-add mt-3"
              />
              <div className="d-flex mt-2 position-absolute m-2">
                <div className="dropdown mx-2">
                  <button
                    className="add-tit-btn2 mx-1 bottom_show_btn"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    + Add
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <span className="dropdown-item">Product</span>
                    </li>
                    <li>
                      <span className="dropdown-item">Tittle</span>
                    </li>
                  </ul>
                </div>
                {/* <button className="add-tit-btn1">+ Add</button> */}
                <button
                  className="add-tit-btn2 mx-1 bottom_show_btn"
                  onClick={() => setAddShow(!addShow)}
                >
                  {addShow ? "Show" : "hide"}
                </button>
                <button
                  className="add-tit-btn3 mx-1 bottom_delete_btn"
                  onClick={() => setDeletes(!deletes)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="d-flex as-jb flex-column flex-md-row gap-3 w-100">
        {tab !== 3 && tab !== 4 && (
          <div class="summary bg-soft-gray1 w-35 md_t_W">
            <div className="d-flex ac-jb py-3 px-1 px-md-3">
              <p className="">Booked Margin Amount</p>
              <p className="">₹0.00</p>
            </div>
            <div className="d-flex ac-jb px-1 px-md-3 py-3">
              <p className="">Booked Margin Amount</p>
              <p className="">₹0.00</p>
            </div>
          </div>
        )}
        {tab == 1 && (
          <div class="summary bg-soft-gray1 w-45 md_t_W ">
            <div className="d-flex ac-jb py-3 px-1 px-md-3">
              <p className="">Subtotal</p>
              <p className="">{subtotal ? "₹" + subtotal : "0"}</p>
            </div>
            <div className="d-flex ac-jb px-1 px-md-3 py-3">
              <p className="">GST ({gstTax ? gstTax + "%" : ""})</p>
              <p className="">{gstFinal ? "₹" + gstFinal : "0"}</p>
            </div>

            {projectData?.tax_type == 2 && (
              <div className="d-flex ac-jb px-1 px-md-3 py-3">
                <div>
                  <p className="">Discount % </p>
                  <input
                    onChange={(e) =>
                      totalUpdate(
                        e.target.value,
                        tax_percentGst,
                        tax_percentGst1,
                        projectData
                      )
                    }
                    type={"number"}
                    value={discount_percent}
                  />
                </div>
                <p className="">{discount ? "₹" + discount : "0"}</p>
              </div>
            )}
            <div className="d-flex ac-jb px-1 px-md-3 py-3">
              <p className="">Total</p>
              <p className="">{totalFinal ? "₹" + totalFinal : "0"}</p>
            </div>
            <div className="d-flex ac-jb px-1 px-md-3 py-3">
              <div className="d-flex gap-2">
                <input type="checkbox" />
                <p className="">Hide Totals</p>
              </div>
            </div>
          </div>
        )}
        {viewStock && <ViewStockPoppup viewStockToggle={viewStockToggle} />}
      </div>
      <Modal
        show={showPopup}
        onHide={() => setShowPopup(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <div className="p-5">
          <p className="d-flex ac-jb mt-2 f4 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
            Add Product
            <span>
              <button onClick={() => closeAddPro()} className="cust-btn">
                <HighlightOffIcon />
              </button>
            </span>
          </p>

          <div className="w-100 d-flex flex-md-row flex-column gap-md-5 mt-3 align-items-start align-items-md-center">
            <p className="w-50 text-nowrap f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
              Category
            </p>
            <div className="w-md-50 w-100 position-relative">
              <input
                placeholder="Catogory"
                type="text"
                value={
                  catogory?.length > 0
                    ? catogory?.toString()
                    : "Select Catogory"
                }
                onClick={() => {
                  setDropDown(1);
                }}
                className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                disabled={location?.state?.type == "view" ? true : false}
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setDropDown(1);
                }}
                // disabled={location?.state?.type == "view" ? true : false}
              >
                <KeyboardArrowDownIcon />
              </button>
              {dropDown == 1 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setDropDown(null)}
                />
              )}
              <div
                className={`${
                  dropDown == 1 && "submenu_1 py-2"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                <div className="d-flex gap-1">
                  <input
                    className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                    placeholder="Search..."
                    onChange={(e) => searchCategory(e.target.value)}
                    value={searchValueCategory}
                  />
                </div>
                {list?.map((item, ind) => {
                  return (
                    <button
                      className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                      onClick={() => {
                        onCategorySelct(item);
                      }}
                      key={ind}
                    >
                      <button className="px-2 cust-btn text-start py-1 w-100 ">
                        {item?.name}
                      </button>
                    </button>
                  );
                })}
              </div>
              {categoryID?.length == 0 && categoryIDErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Select Category</p>
                </div>
              )}
            </div>
          </div>
          {subcatogory && (
            <>
              {subCategoryList?.map((item, ind) => {
                return (
                  <div
                    className="w-100 d-flex flex-md-row flex-column gap-md-5 mt-3 align-items-start align-items-md-center"
                    key={ind}
                  >
                    <p className="w-50 text-nowrap f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                      {item?.categories
                        ? item?.categories?.name
                        : item?.category?.name}
                    </p>
                    <div className="w-md-50 w-100 position-relative">
                      <input
                        placeholder="Required Field"
                        className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        value={
                          catogoryName?.[
                            item?.categories
                              ? item?.categories?.id
                              : item?.category?.id
                          ]
                            ? catogoryName?.[
                                item?.categories
                                  ? item?.categories?.id
                                  : item?.category?.id
                              ]
                            : ""
                        }
                        // onChange={onCatogory2}
                        onClick={() => setCatogoryShow2(ind)}
                        readOnly
                      />
                      <button
                        className="drop_down cust-btn"
                        onClick={() => {
                          setCatogoryShow2(ind);
                          setDropDown(null);
                        }}
                      >
                        <KeyboardArrowDownIcon />
                      </button>
                      {catogoryShow2 == ind && (
                        <div
                          className="invisible-cont2 z-0"
                          onClick={() => setCatogoryShow2(null)}
                        />
                      )}
                      <div
                        className={`${
                          catogoryShow2 == ind && "submenu_1 py-2"
                        } submenu_cont_1 overflow-scroll z-3`}
                      >
                        <div className="d-flex gap-1">
                          <input
                            className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                            placeholder="Search..."
                            onChange={(e) =>
                              searchSubCatFun(e.target.value, ind)
                            }
                            value={searchValueSubCategory[ind]}
                          />
                        </div>
                        {item?.child_categories?.map((subItem, indx) => {
                          return (
                            <button
                              className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                              onClick={() => {
                                onSubCategorySelect(subItem, indx, ind);
                              }}
                              key={ind}
                            >
                              <button className="px-2 cust-btn text-start py-1 w-100 ">
                                {subItem?.name}
                              </button>
                            </button>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          )}

          <div className="w-100 d-flex flex-md-row flex-column gap-md-5 mt-3 align-items-start align-items-md-center">
            <p className="w-50 text-nowrap f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
              Product Name
            </p>
            <div className="w-md-50 w-100 position-relative">
              <input
                placeholder="Product Name"
                type="text"
                value={prods ? prods : "Select Product Name"}
                onClick={() => {
                  setDropDown(2);
                }}
                className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setDropDown(2);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
              {dropDown == 2 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => {
                    setDropDown(null);
                    setCatogoryShow2(null);
                  }}
                />
              )}
              <div
                className={`${
                  dropDown == 2 && "submenu_1 py-2"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                <div className="d-flex gap-1">
                  <input
                    className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                    placeholder="Search..."
                    onChange={(e) => searchPro(e.target.value)}
                    value={searchValuePro}
                  />
                </div>
                {productList?.map((item, inx) => {
                  return (
                    <button
                      className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                      key={inx}
                    >
                      <button
                        className="px-2 cust-btn text-start py-1 w-100 "
                        onClick={() => {
                          onProSelect(item?.name, item?.id, "", item);
                        }}
                      >
                        {item?.name}
                      </button>
                    </button>
                  );
                })}
              </div>
              {productID?.length == 0 && productsErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Select Product</p>
                </div>
              )}
            </div>
          </div>

          <div className="w-100 d-flex flex-md-row flex-column gap-md-5 mt-3 align-items-start align-items-md-center">
            <p className="w-50 text-nowrap f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
              SKU Code:
            </p>
            <div className="w-md-50 w-100">
              <select
                className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                onChange={(e) => skuSelect(e.target.value)}
                value={sku}
              >
                <option value={""}>Select SKU</option>
                {skuList?.length > 0 &&
                  skuList?.map((skuCode, ind) => (
                    <option
                      className="px-2 cust-btn text-start py-1 w-100"
                      value={skuCode?.id}
                      key={ind}
                    >
                      {skuCode?.variant_sku}
                      {skuCode?.variant_name ? "-" + skuCode?.variant_name : ""}
                    </option>
                  ))}
              </select>
              {sku?.length == 0 && skuErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Select SKU</p>
                </div>
              )}
            </div>
          </div>
          <div className="d-flex mt-3 justify-content-end m-2">
            <button
              onClick={submitHandleProduct}
              disabled={btn}
              className={`mt-2 cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
                btn && "opacity-50"
              }`}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EstimateScreen;
