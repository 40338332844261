import React, { useState } from "react";
import { noLogo, product, viewIcon } from "../../assets/img";
import { collaborateList, Compact_table } from "../../redux/api/DummyJson";
import { useNavigate, useSearchParams } from "react-router-dom";
import DeletePopup from "../Popup/DeletePopup";
import ViewStockPoppup from "./ViewStockPoppup";

const TableComp2 = ({
  setAddTitle,
  viewStockToggle,
  deleteToggle,
  toggleBtn1,
  tab,
  projectDetail,
  setProjectDetail,
  setShowPopup,
  estimateStatus,
  setStatus,
  setDeletes,
  deletes,
  projectData,
}) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [hide, setHide] = useState(false);
  const [actionBtn, setActionBtn] = useState(null);
  const handleStatus = (value) => {
    if (actionBtn == value) {
      setActionBtn(null);
    } else {
      setActionBtn(value);
    }
  };

  const deletePopupOpen = (id) => {
    setStatus(id);
    setDeletes(!deletes);
  };

  return (
    <div className="presentaion_pages overflow-scroll mt-4 w-100">
      <div className="w-100 overflow-scroll table_border menu-table mt-3">
        <table className="w-100 presen-tables">
          <thead className="presen-table">
            <tr className="bg-soft-gray1">
              <th style={{ width: "600px" }} className="py-3">
                <div className="dropdown mx-2 d-flex- flex-column ac-js d-flex w-100">
                  <button
                    className="dropdown-toggle align-self-start cust-btn addbtn  ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    onClick={() =>
                      navigate(
                        `/product-library-select?project=${searchParams?.get(
                          "project"
                        )}`,
                        { state: { back: "estimate" } }
                      )
                    }
                  >
                    + Add
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <span
                        className="dropdown-item f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        onClick={() => navigate("/product-library")}
                      >
                        Product
                      </span>
                    </li>
                    <li>
                      <span
                        className="dropdown-item f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        onClick={() => setAddTitle(true)}
                      >
                        Tittle
                      </span>
                    </li>
                  </ul>
                </div>
              </th>
              <th>Qty</th>
              <th>Margin</th>
              <th>Tax</th>
              <th>Amount</th>
              <th>SubTotal</th>
              <th>Total</th>
            </tr>
          </thead>
          {projectDetail?.map((item, index) => {
            let sub_total_return = item?.discount_amount
              ? item?.discount_amount
              : item?.net_price;

            if (item?.extra_charges?.length > 0) {
              item?.extra_charges?.map((items, ind) => {
                if (items?.client_price == 1) {
                  sub_total_return += +items?.final_amount;
                } else if (items?.client_price == 2) {
                  sub_total_return += +items?.final_amount;
                }
              });
            }
            return (
              <tbody
                className={`pres-body color-for-hecd_new ${
                  item?.estimate_status == 4
                    ? "opacity-50"
                    : item?.estimate_status == 5
                    ? "opacity-50"
                    : ""
                }`}
                key={index}
              >
                <tr className="position-relative color-for-hecd bor_t value_tr">
                  <td
                    rowspan={item?.projectPresentationPrice?.length + 1}
                    className=""
                  >
                    <div className="ps-3" style={{ width: "600px" }}>
                      <div>
                        <div className="d-flex cp">
                          <img
                            src={
                              item?.product?.defaultImage
                                ? item?.product?.defaultImage
                                : noLogo
                            }
                            className="w-15 mt-2"
                            onClick={() => navigate("/presentation-edit")}
                          />
                          <div className="d-flex flex-column mt-4 mx-3">
                            <span className="text-info text-start mb-2 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                              {item?.product?.name}
                            </span>
                            <span className="f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                              {item?.product?.description}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex mt-3 mb-3">
                          <button
                            className="text-nowrap px-2"
                            onClick={() => {
                              if (item?.estimate_status == 4) {
                                estimateStatus(item?.id, 1);
                              } else if (item?.estimate_status == 5) {
                                estimateStatus(item?.id, 2);
                              } else if (item?.status == 1) {
                                estimateStatus(item?.id, 4);
                              } else if (item?.status == 2) {
                                estimateStatus(item?.id, 5);
                              }
                            }}
                          >
                            {item?.estimate_status == 4
                              ? "Unhide"
                              : item?.estimate_status == 5
                              ? "Unhide"
                              : "Hide"}
                          </button>
                          <button
                            className="text-nowrap px-2 "
                            onClick={() =>
                              navigate("/estimate-edit-item", {
                                state: {
                                  data: item,
                                  type: "edit",
                                  list: projectData,
                                },
                              })
                            }
                          >
                            Edit
                          </button>

                          <button
                            className="text-nowrap px-2  del-colo-d"
                            onClick={() => {
                              deletePopupOpen(item?.id);
                            }}
                          >
                            Delete
                          </button>

                          <button
                            className="text-nowrap px-2"
                            onClick={viewStockToggle}
                          >
                            View Stock
                          </button>
                        </div>
                      </div>
                      <div
                        className="status_btn w-auto "
                        style={{
                          bottom: 0,
                        }}
                      >
                        <div
                          className={`${
                            item?.artwork_status == 1
                              ? "process_btn"
                              : item?.artwork_status == 2
                              ? "accepted_btn"
                              : item?.artwork_status == 3
                              ? "pending_btn"
                              : item?.artwork_status == 4
                              ? "pending_btn"
                              : ""
                          } rounded-3 d-flex mb-2 ac-jc px-4 py-2 position-relative`}
                          style={{ width: "140px" }}
                          role={"button"}
                          // onClick={() => handleStatus(ind)}
                        >
                          <p className="text_btn text-nowrap">
                            {item?.artwork_status == 1
                              ? "In Process"
                              : item?.artwork_status == 2
                              ? "Artwork Ready"
                              : item?.artwork_status == 3
                              ? "User Approved"
                              : item?.artwork_status == 4
                              ? "User Rejected"
                              : ""}
                          </p>
                          {index == actionBtn && (
                            <div
                              className="action_btn ml-1 position-absolute"
                              style={{
                                width: "140px",
                                zIndex: 300,
                                top: "110%",
                                left: 0,
                              }}
                            >
                              <ul
                                style={{
                                  border: "1px solid #6c757d",
                                  borderRadius: "3px",
                                }}
                              >
                                <li
                                  className="border-0 bg-white text-start f2 text-dark fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100"
                                  onClick={() => {
                                    setActionBtn(null);
                                  }}
                                >
                                  In Process
                                </li>

                                <li
                                  className="border-0 bg-white text-start f2 text-dark fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100"
                                  onClick={() => {
                                    setActionBtn(null);
                                  }}
                                >
                                  Ready
                                </li>
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                {item?.retail_price > 0 ? (
                  <tr>
                    <td style={{ width: "500px" }} className="text-start mb-3">
                      {item?.qty && item?.retail_price > 0 ? item?.qty : ""}
                    </td>
                    <td style={{ width: "200px" }} className="text-start mb-3">
                      {item?.margin_percent && item?.retail_price > 0
                        ? item?.margin_percent
                        : ""}
                    </td>
                    <td style={{ width: "200px" }} className="text-start mb-3">
                      {item?.tax_percent && item?.retail_price > 0
                        ? item?.tax_percent + "%"
                        : ""}
                    </td>
                    <td style={{ width: "200px" }} className="text-start mb-3">
                      {" "}
                      {item?.retail_price && item?.retail_price > 0
                        ? "₹" + item?.retail_price
                        : ""}
                    </td>
                    <td style={{ width: "200px" }} className="text-start mb-3">
                      {" "}
                      {item?.retail_price && item?.retail_price > 0
                        ? "₹" + sub_total_return
                        : ""}
                    </td>
                    <td style={{ width: "200px" }} className="text-start mb-3">
                      {" "}
                      {item?.final_amount && item?.retail_price > 0
                        ? "₹" + item?.final_amount
                        : ""}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td style={{ width: "200px" }} className="text-center"></td>
                    <td style={{ width: "200px" }} className="text-center"></td>
                    <td style={{ width: "200px" }} className="text-center"></td>
                    <td style={{ width: "200px" }} className="text-center"></td>
                    <td style={{ width: "200px" }} className="text-center"></td>
                    <td style={{ width: "200px" }} className="text-center"></td>
                  </tr>
                )}
                {/* {item?.projectPresentationPrice?.map((charge, cInd) => {
                  let run_charge_value = "";
                  if (charge?.run_charge?.length > 0) {
                    run_charge_value = charge?.run_charge?.reduce(
                      (n, { value }) => n + +value,
                      0
                    );
                  }
                  return (
                    <tr key={cInd}>
                      <td style={{ width: "200px" }} className="text-center">
                        {charge?.qty}
                      </td>
                      <td style={{ width: "200px" }} className="text-center">
                        {charge?.margin}%
                      </td>
                      <td style={{ width: "200px" }} className="text-center">
                        {item?.tax_percent ? item?.tax_percent + "%" : ""}
                      </td>
                      <td style={{ width: "200px" }} className="text-center">
                        {" "}
                        ₹{charge?.total}
                      </td>
                    </tr>
                  );
                })} */}
              </tbody>
            );
          })}
        </table>
      </div>
    </div>
  );
};

export default TableComp2;
