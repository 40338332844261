import React, { useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { toast } from "react-hot-toast";

const PresentationEditTable = ({
  productDataList,
  runCharge,
  setRunCharge,
  qty,
  setQty,
  productPrice,
  setproductPrice,
  setNumbers,
  numbers,
  btns,
  submitHandle,
}) => {
  const [imprintCost, setImprintCost] = useState({
    0: "Imprint Cost",
    1: "Imprint Cost",
  });

  const onNoNameChange = (e, index) => {
    const value = e.target.value;
    setImprintCost((pre) => ({ ...pre, [index]: value }));
  };
  const addRunCharge = () => {
    let temp = [...runCharge];

    temp.push({
      name: "",
      for_25: "",
      for_50: "",
      for_100: "",
      for_150: "",
      for_250: "",
      for_500: "",
      for_1000: "",
    });
    setRunCharge(temp);
  };

  const removeRunCharge = (e, ind) => {
    let temp = [...runCharge];
    temp.splice(ind, 1);

    let tempx = [];

    temp?.map((item, ind) => {
      tempx.push({
        for_25: +item?.for_25,
        for_50: +item?.for_50,
        for_100: +item?.for_100,
        for_150: +item?.for_150,
        for_250: +item?.for_250,
        for_500: +item?.for_500,
        for_1000: +item?.for_1000,
      });
    });

    let for_price = {
      for_25: +productPrice?.for_25,
      for_50: +productPrice?.for_50,
      for_100: +productPrice?.for_100,
      for_150: +productPrice?.for_150,
      for_250: +productPrice?.for_250,
      for_500: +productPrice?.for_500,
      for_1000: +productPrice?.for_1000,
    };

    const result = tempx?.reduce((accum, current) => {
      Object.entries(current).forEach(([key, value]) => {
        accum[key] = +accum[key] + +value || +value;
      });
      return {
        ...accum,
      };
    }, {});

    delete result?.name;

    if (Object?.values(result)?.length > 0) {
      const net_value = Object.fromEntries(
        Object.keys(for_price).map((k) => [k, +for_price[k] + +result[k]])
      );

      setproductPrice((val) => ({ ...val, ["total"]: net_value }));
    } else {
      let obj = {
        for_25: +productPrice?.for_25,
        for_50: +productPrice?.for_50,
        for_100: +productPrice?.for_100,
        for_150: +productPrice?.for_150,
        for_250: +productPrice?.for_250,
        for_500: +productPrice?.for_500,
        for_1000: +productPrice?.for_1000,
      };
      setproductPrice((val) => ({ ...val, ["total"]: obj }));
    }

    setRunCharge(temp);
  };

  const handleRunCharge = (e, index) => {
    let temp = [...runCharge];
    const name = e.target.name;
    const value = e.target.value;
    if (name == "name") {
      temp[index][name] = value;
    } else if (value >= 0) {
      temp[index][name] = +value;

      let tempx = [];

      temp?.map((item, ind) => {
        tempx.push({
          for_25: +item?.for_25,
          for_50: +item?.for_50,
          for_100: +item?.for_100,
          for_150: +item?.for_150,
          for_250: +item?.for_250,
          for_500: +item?.for_500,
          for_1000: +item?.for_1000,
        });
      });

      let for_price = {
        for_25: +productPrice?.for_25,
        for_50: +productPrice?.for_50,
        for_100: +productPrice?.for_100,
        for_150: +productPrice?.for_150,
        for_250: +productPrice?.for_250,
        for_500: +productPrice?.for_500,
        for_1000: +productPrice?.for_1000,
      };

      const result = tempx?.reduce((accum, current) => {
        Object.entries(current).forEach(([key, value]) => {
          accum[key] = +accum[key] + +value || +value;
        });
        return {
          ...accum,
        };
      }, {});

      delete result?.name;
      const net_value = Object.fromEntries(
        Object.keys(for_price).map((k) => [k, +for_price[k] + +result[k]])
      );

      setproductPrice((val) => ({ ...val, ["total"]: net_value }));
    }

    setRunCharge(temp);
  };

  const enableBtn = (num) => {
    let temp = [...numbers];

    let event = temp.indexOf(num);

    if (temp?.includes(num)) {
      temp.splice(event, 1);
    } else {
      temp.push(num);
    }
    setNumbers(temp);
  };

  // console.log("productPrice", productPrice);
  // console.log("runCharge", runCharge);

  const handleQtyChange = (value, type, subType) => {
    if (value <= type && value >= subType) {
      setQty((val) => ({ ...val, [type]: value }));
    }
  };

  const handleScpChange = (value) => {
    try {
      let distributorPrice = productPrice?.productDistributorPrice;

      let prices = Object.keys(distributorPrice);
      if (prices?.length > 0) {
        let obj = {
          ...productPrice,
          ["scp_percentage"]: +value,
        };
        let for_price = {};
        let total = {};
        for (let i = 0; i < prices?.length; i++) {
          let percen = (+value / 100) * +distributorPrice[prices[i]];
          let sum = Number(+percen + +distributorPrice[prices[i]]).toFixed();
          obj[prices[i]] = +sum;
          for_price[prices[i]] = +sum;
          total[prices[i]] = +sum;
        }

        if (runCharge?.length > 0) {
          let temp = [];

          runCharge?.map((item, ind) => {
            temp.push({
              for_25: +item?.for_25,
              for_50: +item?.for_50,
              for_100: +item?.for_100,
              for_150: +item?.for_150,
              for_250: +item?.for_250,
              for_500: +item?.for_500,
              for_1000: +item?.for_1000,
            });
          });

          const result = {};

          temp.forEach((obj) => {
            for (const key in obj) {
              if (key.startsWith("for_")) {
                if (!(key in result)) {
                  result[key] = 0;
                }
                result[key] += obj[key];
              }
            }
          });

          const net_value = Object.fromEntries(
            Object.keys(for_price).map((k) => [k, +for_price[k] + +result[k]])
          );

          obj["total"] = net_value;
        } else {
          obj["total"] = total;
        }

        if (value < productPrice?.msp_percentage) {
          toast("Minimum Selling Price Reached");
          obj.request = 1;
        } else {
          obj.request = 0;
        }

        setproductPrice(obj);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    // <div className="" style={{ width: "500px" }}>
    <div className="w-100">
      <table className="w-100  overflow-scroll">
        <tr className="mt-4">
          <td>
            <div className="mx-2 d-flex ac-js table_head">
              <p className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                Qty
              </p>
            </div>
          </td>
          <div className="my-3">
            <td>
              <div className="d-flex w-100">
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    className={`num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                      !numbers?.includes("for_25") && "opacity-50"
                    }`}
                    type="number"
                    placeholder="25"
                    value={qty?.["25"]}
                    onChange={(e) => handleQtyChange(e.target.value, 25, 1)}
                    disabled={numbers?.includes("for_25") ? false : true}
                  />
                </div>
                <div className="mx-2 table_data d-flex ac-jc">
                  <input
                    className={`num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                      !numbers?.includes("for_50") && "opacity-50"
                    }`}
                    type="number"
                    placeholder="50"
                    value={qty?.["50"]}
                    onChange={(e) => handleQtyChange(e.target.value, 50, 26)}
                    disabled={numbers?.includes("for_50") ? false : true}
                  />
                </div>
                <div className="mx-2 table_data d-flex ac-jc">
                  <input
                    className={`num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                      !numbers?.includes("for_100") && "opacity-50"
                    }`}
                    type="number"
                    placeholder="100"
                    value={qty?.["100"]}
                    onChange={(e) => handleQtyChange(e.target.value, 100, 51)}
                    disabled={numbers?.includes("for_100") ? false : true}
                  />
                </div>
                <div className="mx-2 table_data d-flex ac-jc">
                  <input
                    className={`num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                      !numbers?.includes("for_150") && "opacity-50"
                    }`}
                    type="number"
                    placeholder="150"
                    value={qty?.["150"]}
                    onChange={(e) => handleQtyChange(e.target.value, 150, 101)}
                    disabled={numbers?.includes("for_150") ? false : true}
                  />
                </div>
                <div className="mx-2 table_data d-flex ac-jc">
                  <input
                    className={`num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                      !numbers?.includes("for_250") && "opacity-50"
                    }`}
                    type="number"
                    placeholder="250"
                    value={qty?.["250"]}
                    onChange={(e) => handleQtyChange(e.target.value, 250, 151)}
                    disabled={numbers?.includes("for_250") ? false : true}
                  />
                </div>
                <div className="mx-2 table_data d-flex ac-jc">
                  <input
                    className={`num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                      !numbers?.includes("for_500") && "opacity-50"
                    }`}
                    type="number"
                    placeholder="500"
                    value={qty?.["500"]}
                    onChange={(e) => handleQtyChange(e.target.value, 500, 251)}
                    disabled={numbers?.includes("for_500") ? false : true}
                  />
                </div>
                <div className="mx-2 table_data d-flex ac-jc">
                  <input
                    className={`num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                      !numbers?.includes("for_1000") && "opacity-50"
                    }`}
                    type="number"
                    placeholder="1000"
                    value={qty?.["1000"]}
                    onChange={(e) => handleQtyChange(e.target.value, 1000, 501)}
                    disabled={numbers?.includes("for_1000") ? false : true}
                  />
                </div>
              </div>
            </td>
          </div>
        </tr>
        <tr>
          <th>
            <div className="mx-2 d-flex ac-js table_head">
              <p className="primary text-nowrap f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                Show to client?
              </p>
            </div>
          </th>
          <div className="mt-2">
            <td>
              <div className="d-flex w-100">
                <div className="mx-2 table_data d-flex ac-jc">
                  <input
                    class=" f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                    type="checkbox"
                    onClick={() => enableBtn("for_25")}
                    checked={numbers?.includes("for_25") ? true : false}
                    disabled={productDataList?.status >= 5 ? true : false}
                  />
                </div>
                <div className="mx-2 table_data d-flex ac-jc">
                  <input
                    class=" f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                    type="checkbox"
                    onClick={() => enableBtn("for_50")}
                    checked={numbers?.includes("for_50") ? true : false}
                    disabled={productDataList?.status >= 5 ? true : false}
                  />
                </div>
                <div className="mx-2 table_data d-flex ac-jc">
                  <input
                    class=" f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                    type="checkbox"
                    onClick={() => enableBtn("for_100")}
                    checked={numbers?.includes("for_100") ? true : false}
                    disabled={productDataList?.status >= 5 ? true : false}
                  />
                </div>
                <div className="mx-2 table_data d-flex ac-jc">
                  <input
                    class=" f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                    type="checkbox"
                    onClick={() => enableBtn("for_150")}
                    checked={numbers?.includes("for_150") ? true : false}
                    disabled={productDataList?.status >= 5 ? true : false}
                  />
                </div>
                <div className="mx-2 table_data d-flex ac-jc">
                  <input
                    class=" f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-center"
                    type="checkbox"
                    onClick={() => enableBtn("for_250")}
                    checked={numbers?.includes("for_250") ? true : false}
                    disabled={productDataList?.status >= 5 ? true : false}
                  />
                </div>
                <div className="mx-2 table_data d-flex ac-jc">
                  <input
                    class=" f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-center"
                    type="checkbox"
                    onClick={() => enableBtn("for_500")}
                    checked={numbers?.includes("for_500") ? true : false}
                    disabled={productDataList?.status >= 5 ? true : false}
                  />
                </div>
                <div className="mx-2 table_data d-flex ac-jc">
                  <input
                    class=" f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-center"
                    type="checkbox"
                    onClick={() => enableBtn("for_1000")}
                    checked={numbers?.includes("for_1000") ? true : false}
                    disabled={productDataList?.status >= 5 ? true : false}
                  />
                </div>
              </div>
            </td>
          </div>
        </tr>
        {/* <tr>

          <th>
            <div className="mx-2 d-flex ac-js table_head">
              <p className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                Net
              </p>
            </div>
          </th>
          <div className="my-3">
            <td>
              <div className="d-flex w-100">
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                    type="number"
                    placeholder="₹100.00"
                  />
                </div>
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                    placeholder="₹90.00"
                    type="number"
                  />
                </div>
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                    placeholder="₹85.00"
                  />
                </div>
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                    placeholder="₹80.00"
                    type="number"
                  />
                </div>
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                    placeholder="₹90.00"
                    type="number"
                  />
                </div>
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                    placeholder="₹100.00"
                    type="number"
                  />
                </div>
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                    placeholder="₹120.00"
                    type="number"
                  />
                </div>
              </div>
            </td>
          </div>
        </tr> */}

        <tr style={{ textAlign: "center" }}>
          <td colspan="9" style={{ padding: "20px 0px" }}>
            {productDataList?.status <= 4 && (
              <button
                className="border-0 text-info primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                style={{ backgroundColor: "#f4f8ff" }}
                onClick={() => addRunCharge()}
              >
                + add run charge
              </button>
            )}
          </td>
        </tr>
        <tr className="mt-2">
          <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
            Margin
          </th>
          <div className="mt-2 mx-2 d-flex ac-jc table_data">
            <td>
              <div className="d-flex w-100">
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                    type="number"
                    placeholder="₹100.00"
                    onChange={(e) => handleScpChange(e.target.value)}
                    value={productPrice?.scp_percentage}
                    disabled={productDataList?.status >= 5 ? true : false}
                  />
                </div>
                {productPrice?.request == 1 && (
                  <div className="mx-2 d-flex ac-jc table_data">
                    <button
                      className={`border-0 text-danger f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 ${
                        btns ? "opacity-50" : ""
                      }`}
                      style={{ backgroundColor: "#f4f8ff" }}
                      onClick={() => submitHandle()}
                      disabled={btns ? true : false}
                    >
                      Request
                    </button>
                  </div>
                )}
                {/*  <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                    placeholder="₹85.00"
                  />
                </div>
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                    placeholder="₹80.00"
                    type="number"
                  />
                </div>
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                    placeholder="₹70.00"
                    type="number"
                  />
                </div>
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                    placeholder="₹70.00"
                    type="number"
                  />
                </div>
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                    placeholder="₹70.00"
                    type="number"
                  />
                </div> */}
              </div>
            </td>
          </div>
        </tr>
        <tr>
          <td>
            <div className="d-flex ac-js table_head">
              <p className="primary text-start f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                Amount
              </p>
            </div>
          </td>
          <div className="mt-2">
            <td>
              <div className="d-flex w-100">
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    className={`num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                      !numbers?.includes("for_25") && "opacity-50"
                    }`}
                    type="number"
                    placeholder="₹100.00"
                    value={productPrice?.for_25}
                    disabled={
                      numbers?.includes("for_25")
                        ? false
                        : productDataList?.status <= 4
                        ? false
                        : true
                    }
                  />
                </div>
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    className={`num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                      !numbers?.includes("for_50") && "opacity-50"
                    }`}
                    placeholder="₹90.00"
                    type="number"
                    value={productPrice?.for_50}
                    disabled={
                      numbers?.includes("for_50")
                        ? false
                        : productDataList?.status <= 4
                        ? false
                        : true
                    }
                  />
                </div>
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    className={`num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                      !numbers?.includes("for_100") && "opacity-50"
                    }`}
                    placeholder="₹85.00"
                    type="number"
                    value={productPrice?.for_100}
                    disabled={
                      numbers?.includes("for_100")
                        ? false
                        : productDataList?.status <= 4
                        ? false
                        : true
                    }
                  />
                </div>
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    className={`num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                      !numbers?.includes("for_150") && "opacity-50"
                    }`}
                    placeholder="₹80.00"
                    type="number"
                    value={productPrice?.for_150}
                    disabled={
                      numbers?.includes("for_150")
                        ? false
                        : productDataList?.status <= 4
                        ? false
                        : true
                    }
                  />
                </div>
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    className={`num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                      !numbers?.includes("for_250") && "opacity-50"
                    }`}
                    placeholder="₹70.00"
                    type="number"
                    value={productPrice?.for_250}
                    disabled={
                      numbers?.includes("for_250")
                        ? false
                        : productDataList?.status <= 4
                        ? false
                        : true
                    }
                  />
                </div>
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    className={`num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                      !numbers?.includes("for_500") && "opacity-50"
                    }`}
                    placeholder="₹70.00"
                    type="number"
                    value={productPrice?.for_500}
                    disabled={
                      numbers?.includes("for_500")
                        ? false
                        : productDataList?.status <= 4
                        ? false
                        : true
                    }
                  />
                </div>
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    className={`num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                      !numbers?.includes("for_1000") && "opacity-50"
                    }`}
                    placeholder="₹70.00"
                    type="number"
                    value={productPrice?.for_1000}
                    disabled={
                      numbers?.includes("for_1000")
                        ? false
                        : productDataList?.status <= 4
                        ? false
                        : true
                    }
                  />
                </div>
              </div>
            </td>
          </div>
        </tr>
        {runCharge?.map((item, ind) => {
          return (
            <tr>
              <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                <div className="d-flex">
                  <span>
                    {productDataList?.status <= 4 && (
                      <button
                        className="cust-btn"
                        onClick={() => removeRunCharge(item, ind)}
                      >
                        <HighlightOffIcon />
                      </button>
                    )}
                  </span>
                  <div
                    style={{
                      width: "40px",
                      height: "40px",
                      backgroundColor: "#07679c",
                    }}
                    className=" num-cls-btn rounded-3 d-flex border-0 ac-jc"
                  >
                    <p className="p-0 text-light fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      {ind + 1}
                    </p>
                  </div>
                  <input
                    class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center mx-2"
                    // value={item?.num1}
                    // value={"Imprint Cost"}
                    value={item?.name}
                    name="name"
                    onChange={(e) => {
                      handleRunCharge(e, ind);
                    }}
                    disabled={productDataList?.status >= 5 ? true : false}
                  />
                </div>
              </th>
              <div className="mt-2">
                <td>
                  <div className="d-flex w-100">
                    <div className="mx-2 d-flex ac-jc table_data">
                      <input
                        className={`num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                          !numbers?.includes("for_25") && "opacity-50"
                        }`}
                        value={item?.for_25}
                        placeholder="0.000"
                        name="for_25"
                        type={"number"}
                        disabled={numbers?.includes("for_25") ? false : true}
                        onChange={(e) => handleRunCharge(e, ind)}
                        readOnly={productDataList?.status >= 5 ? true : false}
                      />
                    </div>
                    <div className="mx-2 d-flex ac-jc table_data">
                      <input
                        className={`num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                          !numbers?.includes("for_50") && "opacity-50"
                        }`}
                        value={item?.for_50}
                        placeholder="0.000"
                        name="for_50"
                        type={"number"}
                        disabled={numbers?.includes("for_50") ? false : true}
                        onChange={(e) => handleRunCharge(e, ind)}
                        readOnly={productDataList?.status >= 5 ? true : false}
                      />
                    </div>
                    <div className="mx-2 d-flex ac-jc table_data">
                      <input
                        className={`num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                          !numbers?.includes("for_100") && "opacity-50"
                        }`}
                        value={item?.for_100}
                        placeholder="0.000"
                        name="for_100"
                        type={"number"}
                        disabled={numbers?.includes("for_100") ? false : true}
                        onChange={(e) => handleRunCharge(e, ind)}
                        readOnly={productDataList?.status >= 5 ? true : false}
                      />
                    </div>
                    <div className="mx-2 d-flex ac-jc table_data">
                      <input
                        className={`num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                          !numbers?.includes("for_150") && "opacity-50"
                        }`}
                        value={item?.for_150}
                        placeholder="0.000"
                        name="for_150"
                        type={"number"}
                        disabled={numbers?.includes("for_150") ? false : true}
                        onChange={(e) => handleRunCharge(e, ind)}
                        readOnly={productDataList?.status >= 5 ? true : false}
                      />
                    </div>
                    <div className="mx-2 d-flex ac-jc table_data">
                      <input
                        className={`num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                          !numbers?.includes("for_250") && "opacity-50"
                        }`}
                        value={item?.for_250}
                        placeholder="0.000"
                        name="for_250"
                        type={"number"}
                        disabled={numbers?.includes("for_250") ? false : true}
                        onChange={(e) => handleRunCharge(e, ind)}
                        readOnly={productDataList?.status >= 5 ? true : false}
                      />
                    </div>
                    <div className="mx-2 d-flex ac-jc table_data">
                      <input
                        className={`num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                          !numbers?.includes("for_500") && "opacity-50"
                        }`}
                        value={item?.for_500}
                        placeholder="0.000"
                        name="for_500"
                        type={"number"}
                        disabled={numbers?.includes("for_500") ? false : true}
                        onChange={(e) => handleRunCharge(e, ind)}
                        readOnly={productDataList?.status >= 5 ? true : false}
                      />
                    </div>
                    <div className="mx-2 d-flex ac-jc table_data">
                      <input
                        className={`num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                          !numbers?.includes("for_1000") && "opacity-50"
                        }`}
                        value={item?.for_1000}
                        placeholder="0.000"
                        name="for_1000"
                        type={"number"}
                        disabled={numbers?.includes("for_1000") ? false : true}
                        readOnly={productDataList?.status >= 5 ? true : false}
                        onChange={(e) => handleRunCharge(e, ind)}
                      />
                    </div>
                  </div>
                </td>
              </div>
            </tr>
          );
        })}
        <tr>
          <td>
            <div className="d-flex ac-js table_head">
              <p className="primary text-start f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                Total
              </p>
            </div>
          </td>
          <div className="mt-2 mb-3">
            <td>
              <div className="d-flex w-100">
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    className={`num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                      !numbers?.includes("for_25") && "opacity-50"
                    }`}
                    type="number"
                    placeholder="₹100.00"
                    readOnly
                    value={productPrice?.total?.for_25}
                    disabled={numbers?.includes("for_25") ? false : true}
                  />
                </div>
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    className={`num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                      !numbers?.includes("for_50") && "opacity-50"
                    }`}
                    placeholder="₹90.00"
                    type="number"
                    readOnly
                    value={productPrice?.total?.for_50}
                    disabled={numbers?.includes("for_50") ? false : true}
                  />
                </div>
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    className={`num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                      !numbers?.includes("for_100") && "opacity-50"
                    }`}
                    placeholder="₹85.00"
                    readOnly
                    type="number"
                    value={productPrice?.total?.for_100}
                    disabled={numbers?.includes("for_100") ? false : true}
                  />
                </div>
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    className={`num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                      !numbers?.includes("for_150") && "opacity-50"
                    }`}
                    placeholder="₹80.00"
                    readOnly
                    value={productPrice?.total?.for_150}
                    type="number"
                    disabled={numbers?.includes("for_150") ? false : true}
                  />
                </div>
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    className={`num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                      !numbers?.includes("for_250") && "opacity-50"
                    }`}
                    placeholder="₹70.00"
                    type="number"
                    readOnly
                    value={productPrice?.total?.for_250}
                    disabled={numbers?.includes("for_250") ? false : true}
                  />
                </div>
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    className={`num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                      !numbers?.includes("for_500") && "opacity-50"
                    }`}
                    placeholder="₹70.00"
                    type="number"
                    readOnly
                    value={productPrice?.total?.for_500}
                    disabled={numbers?.includes("for_500") ? false : true}
                  />
                </div>
                <div className="mx-2 d-flex ac-jc table_data">
                  <input
                    className={`num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center ${
                      !numbers?.includes("for_1000") && "opacity-50"
                    }`}
                    placeholder="₹70.00"
                    readOnly
                    value={productPrice?.total?.for_1000}
                    type="number"
                    disabled={numbers?.includes("for_1000") ? false : true}
                  />
                </div>
              </div>
            </td>
          </div>
        </tr>
      </table>
    </div>
  );
};

export default PresentationEditTable;
