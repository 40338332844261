import ProfileNavBar from "../../components/ProfileNavBar";
import { Search } from "@mui/icons-material";
import manImage from "../../assets/img/cd8c65c7813855d5f58cf91801b1d256.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import ImageCroper from "../../components/Popup/ImageCroper";
import { useEffect, useState } from "react";
import intelImg from "../../assets/img/download.jpeg";
// import {saveUser,getuser } from "../../redux/store/userData";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  useProducttaskaddMutation,
  useDeparmentListMutation,
  useLazyProjectsListQuery,
  useMasterActiveListMutation,
  useLazyProducttaskViewQuery,
  useProducttaskUpdateMutation,
  useProductStatusMutation,
} from "../../redux/api/api";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { saveUser, getuser } from "../../redux/store/userData";

import ErrorIcon from "@mui/icons-material/Error";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import { saveLoader } from "../../redux/slice/loaderNoti";
import ProductSearchHeader from "../ProductComp/ProductSearchHeader";
import moment from "moment/moment";

const AddTask = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const type = location?.state?.type;
  const assign_type = location?.state?.assign_type;
  console.log(assign_type, "assign_type");
  const [image, setImage] = useState();
  const [roles, setRoles] = useState([]);

  // RTK QUERY
  const [taskAddApi] = useProducttaskaddMutation();
  const [userApi] = useDeparmentListMutation();
  const [projectslistApi] = useLazyProjectsListQuery();
  const [activeMasterListApi] = useMasterActiveListMutation();
  const [viewApi] = useLazyProducttaskViewQuery();
  const [EditApi] = useProducttaskUpdateMutation();
  const [statusApi] = useProductStatusMutation();
  const userDetails = saveUser();
  // const user1=getuser();
  // console.log(getuser(),"jj")
  console.log(saveUser(), "users");

  // view API
  const getView = () => {
    viewApi(location?.state?.data?.id)
      .unwrap()
      .then((res) => {
        if (res?.status == "success" || res?.data?.status == "success") {
          let data = res?.data;
          console.log(data.completion_date, "data.completion_date");
          setTask_date(data.task_date);
          setTask_deadline_date(data.task_deadline_date);
          setTypeOfOrderName(data.project.project_code);
          setTypeOfOrderID(data.project.id);
          setAssigned_by(data.assignedBy.first_name);
          setCategory(data.department.name);
          setTypeOfOrderIDs(data.department.id);
          setAssigned_To(data.assignedTo.first_name);
          setTypeOfOrderIDss(data.assignedTo.id);
          setStatus(data.status);
          setDescription(data.description);
          setCompletion_date(data.completion_date);
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };
  useEffect(() => {
    if (location?.state?.data?.id) {
      getView();
    }
  }, []);

  // Recalculate SLA breach when dates change

  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [Email, setEmail] = useState("");
  const [Role, setRole] = useState(1);
  const [status, setStatus] = useState(1);
  const [Password, setPassword] = useState("");
  const [CPassword, setCPassword] = useState("");
  const [items, setItems] = useState([]);
  const [first_nameErr, setFirst_nameErr] = useState(false);
  const [last_nameErr, setLast_nameErr] = useState(false);
  const [EmailErr, setEmailErr] = useState(false);
  const [RoleErr, setRoleErr] = useState(false);
  const [PasswordErr, setPasswordErr] = useState(false);
  const [CPasswordErr, setCPasswordErr] = useState(false);
  const [btn, setBtn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [projectIdErr, setProjectIdErr] = useState(false);
  const [task_dateErr, setTask_dateErr] = useState(false);
  const [task_deadline_dateErr, setTask_deadline_dateErr] = useState(false);
  const [DepartmentErr, setDepartmentErr] = useState(false);
  const [DescriptionErr, setDescriptionErr] = useState(false);
  const [statusErr, setStatusErr] = useState(false);
  const [task_date, setTask_date] = useState("");
  const [task_deadline_date, setTask_deadline_date] = useState("");
  const [description, setDescription] = useState("");
  const [typeOfOrderName, setTypeOfOrderName] = useState("");
  const [typeOfOrderID, setTypeOfOrderID] = useState("");
  const [searchTypeOfOrder, setSearchTypeOfOrder] = useState("");
  const [typeOfOrderIDs, setTypeOfOrderIDs] = useState("");
  const [searchTypeOfOrders, setSearchTypeOfOrders] = useState("");
  const [typeOfOrderList, setTypeOfOrderList] = useState([]);
  const [typeOfOrderLists, setTypeOfOrderLists] = useState([]);
  const [dropDowns, setDropDowns] = useState(null);
  const [typeOfOrderIDss, setTypeOfOrderIDss] = useState("");
  const [searchTypeOfOrderss, setSearchTypeOfOrderss] = useState("");
  const [typeOfOrderListss, setTypeOfOrderListss] = useState([]);
  const [dropDownss, setDropDownss] = useState(null);
  const [typeOfOrderNamess, setTypeOfOrderNamess] = useState("");
  const [dropDown, setDropDown] = useState(null);
  const [masterList, setMasterList] = useState({});
  const [userList, setUserList] = useState([]);
  const [completion_date, setCompletion_date] = useState("");
  const [projectList, setProjectList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [category, setCategory] = useState("");
  const [roleName, setRoleName] = useState("");
  const [assigned_by, setAssigned_by] = useState();
  const [assigned_to_id, setAssigned_To] = useState();
  const [slaBreach, setSlaBreach] = useState(0);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(null);
  const [statusList, setStatusList] = useState();
  // Get unique roles
  const getUniqueRoles = () => {
    const uniqueRoles = [];
    const rolesMap = new Map();

    userList.forEach((item) => {
      if (!rolesMap.has(item?.role?.id)) {
        rolesMap.set(item?.role?.id, true);
        uniqueRoles.push(item.role);
      }
    });

    return uniqueRoles;
  };

  const handleRoleChange = (e) => {
    const selectedRoleId = e.target.value;
    setCategory(selectedRoleId);

    const usersForSelectedRole = userList.filter(
      (item) => item?.role?.id === Number(selectedRoleId)
    );
    setFilteredUsers(usersForSelectedRole);
  };

  const userdetails = localStorage.getItem("userDetails");
  let userStr = JSON.parse(userdetails);
  console.log(userStr, "usestr");

  const submitHandler = () => {
    if (
      typeOfOrderID?.length == 0 ||
      task_date?.length == 0 ||
      task_deadline_date?.length == 0 ||
      typeOfOrderIDs?.length == 0 ||
      description?.length == 0 ||
      status == 0
    ) {
      setProjectIdErr(true);
      setTask_dateErr(true);
      setTask_deadline_dateErr(true);
      setDepartmentErr(true);
      setDescriptionErr(true);
      setStatusErr(true);
    } else if (errorMessage?.length == 0) {
      let formdata = new FormData();

      // setBtn(true);
      dispatch(saveLoader(true));
      if (type === "edit" && assign_type === 1) {
        if (typeOfOrderID) {
          formdata.append("project_id", typeOfOrderID);
        }
        if (task_date) {
          formdata.append("task_date", task_date);
        }
        if (task_deadline_date) {
          formdata.append("task_deadline_date", task_deadline_date);
        }

        formdata.append("assigned_by_id", userStr.id);
        formdata.append("assigned_to_id", typeOfOrderIDss);
        formdata.append("department_id", typeOfOrderIDs);
        formdata.append("description", description);
        formdata.append("status", "1");
        EditApi({ payload: formdata, id: location?.state?.data?.id })
          .unwrap()
          .then((res) => {
            if (res?.status == "success") {
              toast.success(res?.data?.message || res?.message);
              navigate("/projects-tasks");
            }
            // setBtn(false);
            dispatch(saveLoader(false));
          })
          .catch((err) => {
            console.log("err");
            dispatch(saveLoader(false));
            // setBtn(false);
          });
      } else if (type === "edit" && assign_type === 2) {
        let formdata = new FormData();
        formdata.append("project_task_id", location?.state?.data?.id);
        formdata.append("status", status);
        statusApi(formdata)
          .unwrap()
          .then((res) => {
            if (res?.status == "success") {
              toast.success(res?.data?.message || res?.message);
              navigate("/projects-tasks");
            }
            dispatch(saveLoader(false));
          })
          .catch((err) => {
            console.log("err");
            dispatch(saveLoader(false));
            // setBtn(false);
          });
      } else {
        if (typeOfOrderID) {
          formdata.append("project_id", typeOfOrderID);
        }
        if (task_date) {
          formdata.append("task_date", task_date);
        }
        if (task_deadline_date) {
          formdata.append("task_deadline_date", task_deadline_date);
        }
        if (completion_date) {
          formdata.append("completion_date", completion_date);
        }
        formdata.append("assigned_by_id", userStr.id);
        formdata.append("assigned_to_id", typeOfOrderIDss);
        formdata.append("department_id", typeOfOrderIDs);
        formdata.append("description", description);
        formdata.append("status", status);
        taskAddApi(formdata)
          .unwrap()
          .then((res) => {
            if (res?.status == "success") {
              toast.success(res?.data?.message || res?.message);
              navigate("/projects-tasks");
            }
            // setBtn(false);
            dispatch(saveLoader(false));
          })
          .catch((err) => {
            console.log("err");
            dispatch(saveLoader(false));
            // setBtn(false);
          });
      }
    }
  };

  // MASTER LIST API
  const getMaster = () => {
    projectslistApi()
      .unwrap()
      .then((res) => {
        setProjectList(res?.data);
        // setClientList(res?.admins);
        // setOrderList(res?.admins);
        // setProductionList(res?.admins);
      })
      .catch((err) => {
        console.log("err", err);
      });
    let formdata = new FormData();
    formdata.append("type", 7);
    activeMasterListApi(formdata)
      .unwrap()
      .then((res) => {
        setCategoryList(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getMasters = (departmentId) => {
    let formdata = new FormData();
    const categorys = categoryList.id;
    console.log(categorys, "categorys");
    formdata.append("department_id", departmentId);

    userApi(formdata)
      .unwrap()
      .then((res) => {
        setUserList(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (selectedDepartmentId) {
      getMasters(selectedDepartmentId);
    }
  }, [selectedDepartmentId]);
  console.log(category, "category");
  console.log(categoryList, "categoryList");
  // Function for  Breach of SLA
  const calculateSlaBreach = () => {
    if (task_date && task_deadline_date) {
      if (!completion_date) {
        setSlaBreach(0);
      } else {
        const deadline = moment(task_deadline_date);
        const completion = moment(completion_date);
        const difference = completion.diff(deadline, "days");
        // if(task_date>deadline){
        if (difference < 0) {
          setSlaBreach(Math.abs(difference));
        } else if (difference === 0) {
          setSlaBreach(0);
        } else {
          setSlaBreach(-difference);
        }
      }
    }
    // }
  };

  useEffect(() => {
    calculateSlaBreach();
  }, [task_date, task_deadline_date, completion_date]);

  useEffect(() => {
    getMaster();
    // if (type=== "edit") {
    const todayDate = moment(new Date()).format("YYYY-MM-DD");
    setTask_date(todayDate);
    // }
  }, []);
  const todayDate = moment(new Date()).format("YYYY-MM-DD");
  const searchTypeofOrderFun = (event) => {
    let search = projectList.filter((value) =>
      value?.project_code?.toLowerCase().includes(event?.toLowerCase())
    );

    if (event.length > 0) {
      setTypeOfOrderList(search);
    } else {
      setTypeOfOrderList(projectList);
    }

    setSearchTypeOfOrder(event);
  };

  const onTypeofOrderSelect = (project_code, id) => {
    if (project_code === typeOfOrderName) {
      setTypeOfOrderName("");
      setTypeOfOrderID("");
    } else {
      setTypeOfOrderName(project_code);
      setTypeOfOrderID(id);
    }
    setDropDown(null);
    setTypeOfOrderList(projectList);
    setSearchTypeOfOrder("");
  };

  // department/roles dropdown function

  const searchTypeofOrderFuns = (event) => {
    let search = getUniqueRoles().filter((value) =>
      value?.role?.toLowerCase().includes(event?.toLowerCase())
    );

    if (event.length > 0) {
      setTypeOfOrderLists(search);
    } else {
      setTypeOfOrderLists(getUniqueRoles());
    }

    setSearchTypeOfOrders(event);
  };
  const onTypeofOrderSelects = (role, id) => {
    if (role === category) {
      setTypeOfOrderIDs("");
      setCategory("");
      setFilteredUsers(id);
    } else {
      setTypeOfOrderIDs(id);
      setCategory(role);
      const usersForSelectedRole = userList.filter(
        (item) => item?.role?.id === id
      ); // Filter users by role id
      setFilteredUsers(usersForSelectedRole);
    }
    setDropDowns(null);
    setTypeOfOrderLists(getUniqueRoles());
    setSearchTypeOfOrders("");
    setSelectedDepartmentId(id); // Set the selected department ID
  };

  // User dropdown function
  const searchTypeofOrderFunss = (event) => {
    let search = filteredUsers.filter((value) =>
      value?.first_name?.toLowerCase().includes(event?.toLowerCase())
    );

    if (event.length > 0) {
      setTypeOfOrderListss(search);
    } else {
      setTypeOfOrderListss(filteredUsers);
    }

    setSearchTypeOfOrderss(event);
  };
  const onTypeofOrderSelectss = (role, id) => {
    if (role === assigned_to_id) {
      setTypeOfOrderIDss("");
      setAssigned_To("");
    } else {
      setTypeOfOrderIDss(id);
      setAssigned_To(role);
    }
    setDropDownss(null);
    setTypeOfOrderListss(filteredUsers);
    setSearchTypeOfOrderss("");
  };
  console.log(status, "status");

  return (
    <div className="dashRightView p-5 home_section trans">
      <ProductSearchHeader />
      <p
        className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mt-5 d-flex align-items-center gap-1"
        role={"button"}
        onClick={() => navigate(-1)}
      >
        <KeyboardBackspaceIcon />
        {type === "view"
          ? "View Project Task"
          : type === "edit"
          ? "Edit Project Task"
          : "Add Project Task"}
      </p>
      <div className="w-90 mt-lg-5 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Task Date<span>*</span>
          </p>
          <input
            placeholder="Task Date"
            className="editBtn rounded-3 mt-2 p-2 w-100 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            value={task_date}
            type="date"
            disabled={type == "add" ? true : false}
            readOnly
          />
          {task_date?.length == 0 && task_dateErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Task Date</p>
            </div>
          )}
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Task Deadline<span>*</span>
          </p>
          <input
            placeholder="Task Deadline"
            className=" editBtn rounded-3 mt-2 p-2 w-100 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            onChange={(e) => setTask_deadline_date(e.target.value)}
            value={task_deadline_date}
            type="date"
            disabled={type === "edit" && assign_type === 2 ? true : false}
          />
          {task_deadline_date?.length == 0 && task_deadline_dateErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Task Date</p>
            </div>
          )}
        </div>
      </div>
      <div className="w-90 mt-lg-5 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Project Associated<span>*</span>
          </p>

          <div className="position-relative">
            <input
              placeholder="Select a Project"
              className=" editBtn rounded-3 mt-2 p-2 w-100 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              name="project_code"
              id="project_code"
              value={typeOfOrderName}
              onClick={() => {
                setDropDown(3);
              }}
              disabled={type === "edit" && assign_type === 2 ? true : false}
            />
            <button
              className="drop_down cust-btn"
              onClick={() => {
                setDropDown(3);
              }}
            >
              <KeyboardArrowDownIcon />
            </button>
            {dropDown == 3 && (
              <div
                className="invisible-cont2 z-0"
                onClick={() => setDropDown(null)}
              />
            )}
            <div
              className={`${
                dropDown == 3 && "submenu_1  py-2"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              <div className="d-flex gap-1">
                <input
                  className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                  placeholder="Search..."
                  onChange={(e) => searchTypeofOrderFun(e.target.value)}
                  value={searchTypeOfOrder}
                  disabled={type === "edit" && assign_type === 2 ? true : false}
                />
              </div>
              {projectList?.map((item, ind) => {
                return (
                  <button
                    className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                    key={ind}
                    onClick={() => {
                      onTypeofOrderSelect(item?.project_code, item?.id);
                    }}
                  >
                    <button className="px-2 cust-btn text-start py-1 w-100 ">
                      {item?.project_code}
                    </button>
                    <button
                      className="px-2 cust-btn text-start"
                      style={{ fontSize: "large" }}
                    >
                      {typeOfOrderName == item?.project_code ? (
                        <CheckCircleIcon className="primary" />
                      ) : (
                        <RadioButtonUncheckedIcon className="primary" />
                      )}
                    </button>
                  </button>
                );
              })}
            </div>
              {typeOfOrderID?.length == 0 && projectIdErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Project</p>
                </div>
              )}
          </div>
        </div>

        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Assigned By<span></span>
          </p>
          <input
            className="editBtn mt-2 rounded-3 p-2 w-100 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            value={`${userStr.first_name} ${userStr.last_name}`}
            readOnly
            disabled={type === "edit" && assign_type === 2 ? true : false}
          />
          {/* <option hidden>Select Assigned By</option>
    <option value={userStr.id}>{userStr.first_name} {userStr.last_name}</option>  */}
          {/* </select> */}
        </div>
      </div>
      <div className="w-90 mt-lg-5 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Department/ Team<span>*</span>
          </p>
          <div className="position-relative">
            <input
              placeholder="Department/ Team"
              className=" editBtn mt-2 rounded-3 p-2 w-100 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              onChange={(e) => setCategory(e.target.value)}
              value={category}
              onClick={() => {
                setDropDowns(7);
              }}
              disabled={type === "edit" && assign_type === 2 ? true : false}
            />
            <button
              className="drop_down cust-btn"
              onClick={() => {
                setDropDowns(7);
              }}
            >
              <KeyboardArrowDownIcon />
            </button>
            {dropDowns == 7 && (
              <div
                className="invisible-cont2 z-0"
                onClick={() => setDropDowns(null)}
              />
            )}
            <div
              className={`${
                dropDowns == 7 && "submenu_1  py-2"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              <div className="d-flex gap-1">
                <input
                  className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                  placeholder="Search..."
                  onChange={(e) => searchTypeofOrderFuns(e.target.value)}
                  value={searchTypeofOrderFuns}
                  disabled={type === "edit" && assign_type === 2 ? true : false}
                />
              </div>
              {/* <option hidden>Select</option> */}
              {categoryList?.map((role, ind) => {
                return (
                  <button
                    className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                    key={ind}
                    onClick={() => {
                      onTypeofOrderSelects(role?.name, role?.id);
                    }}
                  >
                    <button className="px-2 cust-btn text-start py-1 w-100 ">
                      {role?.name}
                    </button>
                    <button
                      className="px-2 cust-btn text-start"
                      style={{ fontSize: "large" }}
                    >
                      {/* {category == role?.name ? ( */}
                      {category === role?.name &&
                      role?.name !== userStr?.name ? (
                        <CheckCircleIcon className="primary" />
                      ) : (
                        <RadioButtonUncheckedIcon className="primary" />
                      )}
                    </button>
                  </button>
                );
              })}
              {/* </select> */}
            </div>
              {category?.length == 0 && DepartmentErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Department</p>
                </div>
              )}
          </div>
        </div>

        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            User<span></span>
          </p>
          <div className="position-relative">
            <input
              placeholder="user"
              className=" editBtn mt-2 rounded-3 p-2 w-100 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              onChange={(e) => setAssigned_To(e.target.value)}
              disabled={type === "edit" && assign_type === 2 ? true : false}
              value={assigned_to_id}
              onClick={() => {
                setDropDownss(6);
              }}
            />
            <button
              className="drop_down cust-btn"
              onClick={() => {
                setDropDownss(6);
              }}
            >
              <KeyboardArrowDownIcon />
            </button>
            {dropDownss == 6 && (
              <div
                className="invisible-cont2 z-0"
                onClick={() => setDropDownss(null)}
              />
            )}
            <div
              className={`${
                dropDownss == 6 && "submenu_1  py-2"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              <div className="d-flex gap-1">
                <input
                  className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                  placeholder="Search..."
                  onChange={(e) => searchTypeofOrderFunss(e.target.value)}
                  value={searchTypeofOrderFunss}
                  disabled={type == "view" && assign_type == 1 ? true : false}
                />
              </div>
              {/* {getUniqueRoles().map((role, ind) => { */}
              {/* //  {filteredUsers.map((user, ind) => { */}
              {userList.map((user, ind) => {
                return (
                  <button
                    className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                    key={ind}
                    onClick={() => {
                      onTypeofOrderSelectss(user?.first_name, user?.id);
                    }}
                  >
                    <button className="px-2 cust-btn text-start py-1 w-100 ">
                      {user?.first_name}
                    </button>
                    <button
                      className="px-2 cust-btn text-start"
                      style={{ fontSize: "large" }}
                    >
                      {assigned_to_id == user?.first_name ? (
                        <CheckCircleIcon className="primary" />
                      ) : (
                        <RadioButtonUncheckedIcon className="primary" />
                      )}
                    </button>
                  </button>
                );
              })}
            </div>
             {/* {typeOfOrderID?.length == 0 && projectIdErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Project</p>
                </div>
              )} */}
          </div>
        </div>
      </div>
      <div className="w-90 mt-lg-5 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Status<span>*</span>
          </p>
          <select
            placeholder=""
            className=" editBtn mt-2 rounded-3 p-2 w-100 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            name=""
            id=""
            onChange={(e) => {
              const newStatus = e.target.value;
              setStatus(newStatus);
              setCompletion_date(newStatus);
              if (newStatus === "3") {
                setCompletion_date(todayDate);
              } else {
                setCompletion_date("");
              }
            }}
            value={status}
            disabled={type === "edit" && assign_type === 1 ? true : false}
          >
            {assign_type === 2 ? (
              <>
                <option value={""}>Select Status</option>
                <option value={1}>Yet to start</option>
                <option value={2}>In Process</option>
                <option value={3}>Completed</option>
              </>
            ) : assign_type === 1 ? (
              <option value={1}>Yet to start</option>
            ) : (
              <option value={1}>Yet to start</option>
            )}
          </select>
          {status?.length == 0 && statusErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Status</p>
            </div>
          )}
        </div>

        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Description<span>*</span>
          </p>

          <textarea
            placeholder="Description"
            className="editBtn rounded-3 mt-2 p-2 w-100 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            disabled={type === "edit" && assign_type === 2 ? true : false}
          />

          {description?.length == 0 && DescriptionErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Description</p>
            </div>
          )}
        </div>
      </div>

      <div className="w-90 mt-lg-5 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
        {type === "edit" && status == 3 && (
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Breach of SLA<span>*</span>
            </p>
            <input
              placeholder="Breach of SLA"
              className="editBtn rounded-3 mt-2 p-2 w-100 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              value={slaBreach}
              readOnly
            />
          </div>
        )}

        {type === "edit" && status == 3 && (
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Date of completion<span></span>
            </p>
            <input
              placeholder="Date of completion"
              className="editBtn rounded-3 mt-2 p-2 w-100 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              onChange={(e) => setCompletion_date(e.target.value)}
              value={completion_date}
              type="date"
              disabled={type === "add" ? true : false}
            />
          </div>
        )}
      </div>

      <div className="w-90 ac-jb flex-md-row flex-column d-flex">
        <button
          onClick={() => navigate(-1)}
          className="cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
        >
          Back
        </button>
        {type === "edit" ? (
          <button
            className={`cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded ${
              btn && "opacity-50"
            }`}
            onClick={submitHandler}
            // disabled={btn ? true : false}
          >
            Update
          </button>
        ) : (
          <button
            className={`cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded ${
              btn && "opacity-50"
            }`}
            onClick={submitHandler}
            // disabled={btn ? true : false}
          >
            Submit
          </button>
        )}
        {/* )} */}
      </div>
    </div>
  );
};

export default AddTask;
