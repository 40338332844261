import React, { useEffect, useState } from "react";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import SalesForecastTab from "../components/Sales/SalesForecastTab";
import SalesTasks from "../components/Sales/SalesTasks";
import SalesProgress from "../components/Sales/SalesProgress";
import AddClientContactPopup from "../components/Projects/AddClientContactPopup";
import AddProjectPoppupComp from "../components/Projects/AddProjectPoppupComp";
import CachedIcon from "@mui/icons-material/Cached";
import ErrorIcon from "@mui/icons-material/Error";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  useAdminListCustomerReqMutation,
  useLazyPresentationProViewQuery,
  useProjectListMutation,
  useProjectMarginRequestMutation,
  useProjectMarginUpdateMutation,
} from "../redux/api/api";
import { viewIcon } from "../assets/img";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";
import { Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";

const ProjectsRequestMargin = () => {
  const dispatch = useDispatch();
  const [addProject, setAddProject] = useState(false);
  const [showAddTask, setShowAddTask] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({});
  const [btn, setBtn] = useState(false);
  const [presentation_style, setpresentation_style] = useState(0);
  const [presentation_styleErr, setpresentation_styleErr] = useState(false);

  // RTK QUERY
  const [presentationProViewApi] = useLazyPresentationProViewQuery();
  const [ProjectReqListApi] = useProjectMarginRequestMutation();
  const [ProjectUpdateApi] = useProjectMarginUpdateMutation();
  const [customerAdminApi] = useAdminListCustomerReqMutation();

  const [adminList, setAdminList] = useState([]);
  const [projectData, setProjectData] = useState({});
  const [projectViewData, setProjectViewData] = useState({});
  const [searchs, setsearchs] = useState("");
  const [rows, setrows] = useState("");
  const [orders, setorders] = useState("");
  const [order_reps, setorder_reps] = useState("");
  const [client_reps, setclient_reps] = useState("");
  const [statuss, setstatuss] = useState("");
  const [codes, setcodes] = useState("");
  const [production_reps, setproduction_reps] = useState("");

  // PROJECT PROJECT LIST
  const getProjectList = (
    search,
    row,
    order,
    order_rep,
    client_rep,
    status,
    code,
    production_rep
  ) => {
    let formdata = new FormData();
    let params = `?page=${page}`;

    if (row) {
      formdata.append("rows", row);
    }

    if (search) {
      formdata.append("search", search);
    }

    if (order) {
      formdata.append("order", order);
    }

    if (order_rep) {
      formdata.append("order_rep", order_rep);
    }
    if (client_rep) {
      formdata.append("client_rep", client_rep);
    }
    if (status) {
      formdata.append("status", status);
    }

    if (code) {
      formdata.append("code", code);
    }
    if (production_rep) {
      formdata.append("production_rep", production_rep);
    }

    setsearchs(search);
    setrows(row);
    setorders(order);
    setorder_reps(order_rep);
    setclient_reps(client_rep);
    setstatuss(status);
    setcodes(code);
    setproduction_reps(production_rep);

    ProjectReqListApi(formdata)
      .unwrap()
      .then((res) => {
        let temp = [];
        res?.lists?.map((item, ind) => {
          item?.projectDetail?.map((pro, inpro) => {
            if (pro?.price_status == 1) {
              temp.push({
                ...pro,
                project_list: item,
              });
            }
          });
        });

        console.log("temp", temp);

        setList(temp);
        setPagination(res?.pagination_meta);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // ADMIN LIST
  const getViewAdmin = () => {
    customerAdminApi()
      .unwrap()
      .then((res) => {
        setAdminList(res?.admins);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getProjectList();
  }, [page]);

  useEffect(() => {
    getViewAdmin();
  }, []);

  const onTabClick = (tab) => {
    setActiveTab(tab);
  };

  const closeToggle = () => {
    setAddProject(!addProject);
    setpresentation_style(0);
    setpresentation_styleErr(false);
    setProjectData({});
    setProjectViewData({});
  };

  const handleClick = (item) => {
    dispatch(saveLoader(true));
    presentationProViewApi(item?.id)
      .unwrap()
      .then((res) => {
        dispatch(saveLoader(false));
        if (res?.status == "success") {
          setProjectData(item);
          setProjectViewData(res);
          setAddProject(true);
        }
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        console.log("err", err);
      });
  };

  const submitHandle = () => {
    if (presentation_style == 0) {
      setpresentation_styleErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("project_detail_id", projectData?.id);
      formdata.append("margin", presentation_style);

      setBtn(true);
      dispatch(saveLoader(true));
      ProjectUpdateApi(formdata)
        .unwrap()
        .then((res) => {
          setBtn(false);
          dispatch(saveLoader(false));
          toast.success(res?.success);
          getProjectList();
          closeToggle();
        })
        .catch((err) => {
          setBtn(false);
          dispatch(saveLoader(false));
          console.log("err", err);
        });
    }
  };

  return (
    <>
      <div className="dashRightView  p-5 home_section trans">
        <ProductSearchHeader />
        <div className="d-flex ac-jb flex-wrap rk2">
          <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary ">
            Projects Request Margin
          </p>
        </div>

        <div className="mt-4 ">
          <div className="d-flex ac-jb flex-column flex-md-row">
            <div className=" d-flex ac-jc place-holde justify-content-md-start gap-3 flex-wrap w-100 w-md-90 mb-md-0 mb-4">
              <input
                type="text"
                className="w-20 get-start-btn respon-width  bg-lt-blue primary1 border-0 my-0 py-4 px-3"
                placeholder="Search for project"
                onChange={(e) =>
                  getProjectList(
                    e.target.value,
                    rows,
                    orders,
                    order_reps,
                    client_reps,
                    statuss,
                    codes,
                    production_reps
                  )
                }
                value={searchs}
              />
              <button className="w-20 get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4">
                <select
                  className="bg-transparent border-0 py-3 primary1"
                  onChange={(e) =>
                    getProjectList(
                      searchs,
                      rows,
                      orders,
                      order_reps,
                      e.target.value,
                      statuss,
                      codes,
                      production_reps
                    )
                  }
                  value={client_reps}
                >
                  <option disabled selected value="">
                    Client Rep
                  </option>
                  {adminList?.map((item, ind) => {
                    return (
                      <option value={item?.id} key={ind}>
                        {item?.first_name}
                      </option>
                    );
                  })}
                </select>
              </button>
              <button className="w-20 get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4">
                <select
                  className="bg-transparent border-0 py-3 primary1"
                  onChange={(e) =>
                    getProjectList(
                      searchs,
                      rows,
                      orders,
                      e.target.value,
                      client_reps,
                      statuss,
                      codes,
                      production_reps
                    )
                  }
                  value={order_reps}
                >
                  <option disabled selected value="">
                    Order Rep
                  </option>
                  {adminList?.map((item, ind) => {
                    return (
                      <option value={item?.id} key={ind}>
                        {item?.first_name}
                      </option>
                    );
                  })}
                </select>
              </button>
            </div>
            <div className="d-flex ac-jc gap-3">
              <button
                className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded"
                onClick={() => getProjectList("", 10, "", "", "", "", "", "")}
              >
                <CachedIcon />
              </button>
            </div>
          </div>
          <div className="w-95 ac-jb d-flex flex-md-row flex-column mt-4">
            <div className="d-flex">
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
                Show
              </p>
              <select
                className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary"
                onChange={(e) =>
                  getProjectList(
                    searchs,
                    e.target.value,
                    orders,
                    order_reps,
                    client_reps,
                    statuss,
                    codes,
                    production_reps
                  )
                }
                value={rows}
              >
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
                Entries
              </p>
            </div>
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont">
              Total Count : {pagination?.total ? pagination?.total : 0}
            </p>
          </div>
          <div className="mt-5" style={{ width: "100%", overflow: "auto" }}>
            <table className="table-design-new" style={{ width: "100%" }}>
              <thead>
                <tr className="border-bottom w-100 text-nowrap text-center">
                  <th>
                    <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                      S.No
                    </p>
                  </th>
                  <th>
                    <p className="f2 fs-14">Project ID</p>
                  </th>
                  <th>
                    <p className="f2 fs-14">Request ID</p>
                  </th>
                  <th>
                    <p className="f2 fs-14">Order Rep</p>
                  </th>
                  <th>
                    <p className="f2 fs-14">Client Rep</p>
                  </th>
                  <th>
                    <p className="f2 fs-14">Project Value</p>
                  </th>
                  <th>
                    <p className="f2 fs-14">Requested Margin</p>
                  </th>
                  <th>
                    <p className="f2 fs-14">Action</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {list?.map((item, index) => {
                  return (
                    <tr className="text-center">
                      <td>
                        <p className="pointerView f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                          {index + 1}
                        </p>
                      </td>
                      <td className="text-center">
                        <Link
                          className="f3 primary1 text-center"
                          to={`/overview?project=${item?.project_list?.project_code}_${item?.project_list?.id}`}
                          target="_blank"
                        >
                          {item?.project_list?.project_code}
                        </Link>
                      </td>
                      <td>
                        {
                          item?.project_list?.project_request
                            ?.project_request_id
                        }
                      </td>
                      <td>
                        {item?.project_list?.project_request?.order_rep
                          ?.first_name
                          ? item?.project_list?.project_request?.order_rep
                              ?.first_name
                          : "-"}
                      </td>
                      <td>
                        {item?.project_list?.project_request?.client_rep
                          ?.first_name
                          ? item?.project_list?.project_request?.client_rep
                              ?.first_name
                          : "-"}
                      </td>
                      <td>{item?.project_list?.total_amount}</td>
                      <td>{item?.projectPresentationPrice?.[0]?.margin}</td>
                      <td>
                        {" "}
                        <div
                          className="viewBoxDes p-2 mb-2 bg-white rounded-2"
                          onClick={() => handleClick(item)}
                        >
                          <img src={viewIcon} className="viewDes" />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="d-flex justify-content-between mt-3">
            <button
              className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
                page == 1 && "opacity-50"
              }`}
              onClick={() => setPage(page - 1)}
              disabled={page == 1 ? true : false}
            >
              Previous
            </button>
            <button
              className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
                page == pagination?.last_page && "opacity-50"
              }`}
              onClick={() => setPage(page + 1)}
              disabled={page == pagination?.last_page ? true : false}
            >
              Next
            </button>
          </div>
        </div>
      </div>

      {addProject && (
        <div className="add-category-popup">
          <div>
            <p className="d-flex ac-jb mt-2 f4 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
              Request Margin Update
              <span>
                <button onClick={() => closeToggle()} className="cust-btn">
                  <HighlightOffIcon />
                </button>
              </span>
            </p>

            <div className="w-100">
              <p
                className="w-100 f4 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary1 text-start mt-4 mx-3"
                style={{ marginLeft: "0px" }}
              >
                {projectData?.product?.name}
              </p>
              <div className="d-flex mt-2 mx-3">
                <div className="justify-content-center d-flex flex-column gap-3">
                  <div>
                    <span className="f2 f1 fs-12 fs-lg-13 fs-xl-15 fs-xxl-18 mb-2 mt-2">
                      MSP
                    </span>
                    <div
                      className="d-flex mb-2 mt-2"
                      role={"button"}
                      onClick={() =>
                        setpresentation_style(
                          projectViewData?.productPrice?.msp_percentage
                        )
                      }
                    >
                      <input
                        type={"radio"}
                        className="mx-1"
                        checked={
                          presentation_style ==
                          projectViewData?.productPrice?.msp_percentage
                            ? true
                            : false
                        }
                      />
                      <span className="f2 f1 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                        {projectViewData?.productPrice?.msp_percentage}
                      </span>
                    </div>
                  </div>
                  <div>
                    <span className="f2 f1 fs-12 fs-lg-13 fs-xl-15 fs-xxl-18 mb-2 mt-2">
                      SCP
                    </span>
                    <div
                      className="d-flex mb-2 mt-2"
                      role={"button"}
                      onClick={() =>
                        setpresentation_style(
                          projectViewData?.productPrice?.scp_percentage
                        )
                      }
                    >
                      <input
                        type={"radio"}
                        className="mx-1"
                        checked={
                          presentation_style ==
                          projectViewData?.productPrice?.scp_percentage
                            ? true
                            : false
                        }
                      />
                      <span className="f2 f1 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                        {projectViewData?.productPrice?.scp_percentage}
                      </span>
                    </div>
                  </div>
                  <div>
                    <span className="f2 f1 fs-12 fs-lg-13 fs-xl-15 fs-xxl-18 mb-2 mt-2">
                      Requested Margin
                    </span>
                    <div
                      className="d-flex mb-2 mt-2"
                      role={"button"}
                      onClick={() =>
                        setpresentation_style(
                          projectData?.projectPresentationPrice?.[0]?.margin
                        )
                      }
                    >
                      <input
                        type={"radio"}
                        className="mx-1"
                        checked={
                          presentation_style ==
                          projectData?.projectPresentationPrice?.[0]?.margin
                            ? true
                            : false
                        }
                      />
                      <span className="f2 f1 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                        {projectData?.projectPresentationPrice?.[0]?.margin}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {presentation_style == 0 && presentation_styleErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">
                    Select Presentation Style
                  </p>
                </div>
              )}
            </div>

            <div className="d-flex mt-3 justify-content-end m-2">
              <button
                disabled={btn}
                className={`mt-2 cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
                  btn && "opacity-50"
                }`}
                onClick={() => submitHandle()}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectsRequestMargin;
