import React from "react";
import { closeIcon, noLogo, profilePic } from "../../assets/img";

const ProductsViewPopup = ({ handleUserPopup, userCommend }) => {
  return (
    <div className="modal-popup">
      <div className="back-close" />
      <div
        style={{ width: "60%" }}
        className="center-content d-flex flex-column flex-lg-row p-0 m-0"
      >
        <button
          onClick={() => handleUserPopup()}
          className="modal-cls-btn fs-33 f5 primary cust-btn px-2 mb-0 mx-2"
        >
          <img src={closeIcon} alt="" />
        </button>
        <div className="d-flex flex-column as-jc px-3 mx-auto px-3 px-lg-5 w-100 h-100 ">
          {/* <h6 className="mb-2 mb-lg-3 f4 fs-xs-20 fs-sm-24 fs-lg-26 fs-xl-28">
          New Decoration1
        </h6> */}
          <div className="w-100 d-flex ac-jc flex-column flex-md-row">
            <div className="w-xs-100 w-md-50 px-2">
              <div className="d-flex flex-wrap tl-log-upload-box ac-jb  mt-lg-2">
                {/* <p className="parag mb-2">
                Artwork Allmade unisex Organic cotton Tee
              </p> */}
                <label className="w-100 position-relative">
                  <div className="rej-img">
                    <img
                      src={
                        userCommend?.user_logo_url
                          ? userCommend?.user_logo_url
                          : noLogo
                      }
                      alt="No Image"
                    />
                  </div>
                </label>
              </div>
            </div>
            <div className="w-xs-100 w-md-50 px-2">
              <div className="ac-jb mt-3 mt-lg-4 pop-input">
                <p className="f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19 primary1 mb-0">
                  Location
                </p>
                <input
                  type="text"
                  placeholder="Specify the placement of your artwork"
                  className="w-100 bg-transparent f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19"
                  value={userCommend?.location}
                />
              </div>
              <div className="ac-jb mt-3 mt-lg-4 pop-input">
                <p className="f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19 primary1 mb-0">
                  Dimensions
                </p>
                <input
                  type="text"
                  placeholder="W 2.3 X H 4.5"
                  className="w-100 bg-transparent f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19"
                  value={userCommend?.dimension}
                />
              </div>
            </div>
          </div>
          <div className="ac-jb mt-3 mt-lg-4 pop-input w-100">
            <p className="f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19 primary1 mb-2">
              Additional Notes
            </p>
            <input
              type="text"
              placeholder="Notes"
              className="w-100 bg-transparent f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19"
              value={userCommend?.user_comment}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsViewPopup;
