import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, TOKEN, URL } from "./constants";
import { getToken } from "../store/userTocken";
import TestimonialEdit from "../../routes/sitemanagement/testimonial/TestimonialEdit";

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: async (headers) => {
      // const token = getToken();
      const token = localStorage.getItem(TOKEN);
      headers.set("Authorization", "Bearer " + token);
      // headers.set("Content-Type", "multipart/form-data");
      // headers.set("Content-Type", "application/json");
      headers.set("Accept", "application/json");
    },
  }),
  refetchOnMountOrArgChange: true,
  tagTypes: [],
  endpoints: (builder) => ({
    // LOGIN
    login: builder.mutation({
      query: (payload) => ({
        url: URL.LOGIN,
        body: payload,
        method: "POST",
      }),
    }),

    // LOGOUT
    logout: builder.query({
      query: () => URL.LOGOUT,
    }),

    // CHANGE PASSWORD
    changePassword: builder.mutation({
      query: (payload) => ({
        url: URL.CHANGE_PASSWORD,
        body: payload,
        method: "POST",
      }),
    }),

    // SET PASSWORD
    setPassword: builder.mutation({
      query: (payload) => ({
        url: URL.SET_PASSWORD,
        body: payload,
        method: "POST",
      }),
    }),

    // FORGOT PASSWORD
    forgotPassword: builder.mutation({
      query: (payload) => ({
        url: URL.FORGOT_PASSWORD,
        body: payload,
        method: "POST",
      }),
    }),

    // TOKEN VERIFY
    tokenVerify: builder.query({
      query: (data) => URL.TOKEN_VERIFY + data,
    }),

    // ROLE
    roleCreate: builder.query({
      query: () => URL.ROLE + "/create",
    }),

    // ROLE ADD
    roleStore: builder.mutation({
      query: (payload) => ({
        url: URL.ROLE,
        body: payload,
        method: "POST",
      }),
    }),

    // ROLE EDIT
    roleEdit: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.ROLE + "/" + id,
        body: payload,
        method: "POST",
      }),
    }),

    // ROLE VIEW
    roleView: builder.query({
      query: (id) => URL.ROLE + "/" + id,
    }),

    // ROLE VIEW WITH TYPE
    roleViewWithType: builder.query({
      query: ({ id, payload }) => ({
        url: URL.ROLE + "/" + id + "/edit",
        params: payload,
        method: "GET",
      }),
    }),

    // DEPARTMENT ACTIVE ROLE
    departmentActiveRoleList: builder.query({
      query: (payload) => ({
        url: URL.DEPARTMENT_ACTIVE_ROLE,
        body: payload,
        method: "POST",
      }),
    }),
    // ROLE ALL
    roles: builder.query({
      query: () => URL.ROLE,
    }),

    // ROLE ALL WITH
    roleList: builder.mutation({
      query: ({ params, payload }) => ({
        url: URL.ROLES + "/lists" + params,
        body: payload,
        method: "POST",
      }),
    }),
    // ROLE STATUS
    roleStatus: builder.query({
      query: (id) => URL.ROLE + "/status/" + id,
    }),

    // ACTIVE ROLE
    activeRoles: builder.query({
      query: (id) => URL.ACTIVE_ROLES,
    }),

    // ADMIN ADD
    adminAdd: builder.mutation({
      query: (payload) => ({
        url: URL.ADMIN,
        body: payload,
        method: "POST",
      }),
    }),

    // ADMIN EDIT
    adminEdit: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.ADMIN + "/" + id + "/update",
        body: payload,
        method: "POST",
      }),
    }),

    // ADMIN VIEW
    adminView: builder.query({
      query: (id) => URL.ADMIN + "/" + id,
    }),

    // ADMIN ALL
    Admins: builder.query({
      query: () => URL.ADMIN,
    }),

    // ADMIN ALL WITH SEARCH
    AdminsList: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.ADMIN + "/lists" + params,
        body: payload,
        method: "POST",
      }),
    }),

    // ADMIN STATUS
    adminStatus: builder.query({
      query: (id) => URL.ADMIN + "/status/" + id,
    }),

    // ADMIN VIEW WITH ID
    adminViewId: builder.query({
      query: ({ id, payload }) => ({
        url: URL.ADMIN + "/" + id + "/edit",
        params: payload,
        method: "GET",
      }),
    }),

    // CATEGORY ADD
    categoryAdd: builder.mutation({
      query: (payload) => ({
        url: URL.CATEGORY_ADD,
        body: payload,
        method: "POST",
      }),
    }),

    // CATEGORY EDIT
    categoryEdit: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.CATEGORY_UPDATE + "/" + id,
        body: payload,
        method: "POST",
      }),
    }),

    // CATEGORY VIEW
    categoryView: builder.query({
      query: (id) => URL.CATEGORY_VIEW + "/" + id,
    }),

    // CATEGORY PROJECT VIEW
    categoryProductView: builder.query({
      query: (id) => URL.CATEGORY_PRODUCT_VIEW + id,
    }),

    // CATEGORY PROJECT VIEW NEW
    categoryProductViewNew: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.CATEGORY_PRODUCT_VIEW + id,
        body: payload,
        method: "POST",
      }),
    }),

    // CATEGORY VIEW ACTIVE
    categoryActiveView: builder.query({
      query: (id) => URL.CATEGORY_VIEW_ACTIVE + id,
    }),

    // CATEGORY ALL
    categories: builder.query({
      query: () => URL.CATEGORY,
    }),

    // CATEGORY ALL LIST
    categoriesAll: builder.query({
      query: () => URL.CATEGORY_ALL_LIST,
    }),

    categoriesAllActiveList: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.CATEGORY_ALL_LIST_ACTIVE + params,
        body: payload,
        method: "POST",
      }),
    }),

    categoryLists: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.CATEGORY_LIST + params,
        body: payload,
        method: "POST",
      }),
    }),

    // CATEGORY STATUS
    categoryStatus: builder.query({
      query: (id) => URL.CATEGORY_STATUS + "/" + id,
    }),

    // MASTER NO PAGINATION LIST
    masterNoPageList: builder.query({
      query: () => URL.MASTERS,
    }),

    // MASTER LIST
    masterList: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.MASTER_LIST + params,
        method: "POST",
        body: payload,
      }),
    }),

    // MASTER ADD
    masterAdd: builder.mutation({
      query: (payload) => ({
        url: URL.MASTER,
        method: "POST",
        body: payload,
      }),
    }),

    // MASTER EDIT
    masterEdit: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.MASTER + "/" + id + "/update",
        method: "POST",
        body: payload,
      }),
    }),

    // MASTER VIEW
    masterView: builder.query({
      query: (id) => URL.MASTER + "/" + id,
    }),

    // MASTER STATUS
    masterStatus: builder.query({
      query: (id) => URL.MASTER + "/status/" + id,
    }),

    // MASTER ACTIVE LIST
    masterActiveList: builder.mutation({
      query: (payload) => ({
        url: URL.MASTER_ACTIVE_LIST,
        method: "POST",
        body: payload,
      }),
    }),

    // CATERGORY LIST VENDOR
    categoriesListVendor: builder.query({
      query: () => URL.VENDOR_PARENT_CATEGORIES,
    }),

    // VENDOR ADD
    vendorRegister: builder.mutation({
      query: (payload) => ({
        url: URL.VENDOR_REGISTER,
        method: "POST",
        body: payload,
      }),
    }),

    // VENDORS LIST
    vendorsList: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.VENDORS_LIST + params,
        method: "POST",
        body: payload,
      }),
    }),

    // VENDORS ACTIVE LIST
    vendorsActiveList: builder.query({
      query: () => URL.VENDOR_ACTIVE_LIST,
    }),

    // VENDORS EDIT
    vendorsEdit: builder.mutation({
      query: (payload) => ({
        url: URL.VENDORS_EDIT,
        method: "POST",
        body: payload,
      }),
    }),

    // VENDORS VIEW
    vendorsView: builder.query({
      query: (id) => URL.VENDORS_VIEW + id,
    }),

    // DEPARTMENT ROLE ALL WITH SEARCH
    departmentRole: builder.mutation({
      query: ({ payload, params, id }) => ({
        url: URL.DEPARTMENT_ROLE + id + params,
        body: payload,
        method: "POST",
      }),
    }),

    // TAGS LIST
    tagsList: builder.query({
      query: () => URL.TAGS,
    }),

    // TAGS PRODUCT LIST
    tagsProductList: builder.query({
      query: () => URL.TAGS_PRODUCT,
    }),

    // TAGS CREATE
    tagsCreate: builder.mutation({
      query: (payload) => ({
        url: URL.TAGS,
        method: "POST",
        body: payload,
      }),
    }),

    // TAGS EDIT
    tagsEdit: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.TAG + "/" + id + "/update",
        method: "POST",
        body: payload,
      }),
    }),

    // TAGS STATUS
    tagsStatus: builder.query({
      query: (id) => URL.TAGS_STATUS + id,
    }),

    // TAGS VIEW
    tagsView: builder.query({
      query: (id) => URL.TAGS + "/" + id + "/edit",
    }),

    // TAGS LIST WITH SEARCH
    tagsLists: builder.mutation({
      query: (payload) => ({
        url: URL.TAGS_LIST,
        method: "POST",
        body: payload,
      }),
    }),

    // VENDORS

    // ATTRIBUTE LIST PAGINATE
    attributeLists: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.ATTRIBUTE_LIST_PAGINATE + params,
        method: "POST",
        body: payload,
      }),
    }),

    // ATTRIBUTE CREATE
    attributeCreate: builder.mutation({
      query: (payload) => ({
        url: URL.ATTRIBUTE,
        method: "POST",
        body: payload,
      }),
    }),

    // ATTRIBUTE EDIT
    attributeEdit: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.ATTRIBUTE + "/" + id + "/update",
        method: "POST",
        body: payload,
      }),
    }),

    // ATTRIBUTE LIST
    attributeList: builder.query({
      query: () => URL.ATTRIBUTE,
    }),

    // ATTRIBUTE VIEW
    attributeView: builder.query({
      query: (id) => URL.ATTRIBUTE + "/" + id,
    }),

    // ATTRIBUTE STATUS
    attributeStatus: builder.query({
      query: (id) => URL.ATTRIBUTE_STATUS + id,
    }),

    // DECORATIVE METHOD LIST PAGINATE
    decorativeLists: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.DECORATIVE_METHOD_LIST_PAGINATE + params,
        method: "POST",
        body: payload,
      }),
    }),

    // DECORATIVE METHOD CREATE
    decorativeCreate: builder.mutation({
      query: (payload) => ({
        url: URL.DECORATIVE_METHOD,
        method: "POST",
        body: payload,
      }),
    }),

    // DECORATIVE METHOD EDIT
    decorativeEdit: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.DECORATIVE_METHOD + "/" + id + "/update",
        method: "POST",
        body: payload,
      }),
    }),

    // DECORATIVE METHOD LIST
    decorativeList: builder.query({
      query: () => URL.DECORATIVE_METHOD,
    }),

    // DECORATIVE METHOD VIEW
    decorativeView: builder.query({
      query: (id) => URL.DECORATIVE_METHOD + "/" + id,
    }),

    // DECORATIVE METHOD VIEW AND EDIT
    decorativeViewNew: builder.query({
      query: (id) => URL.DECORATIVE_METHOD + "/" + id + "/edit",
    }),

    // DECORATIVE METHOD STATUS
    decorativeStatus: builder.query({
      query: (id) => URL.DECORATIVE_METHOD_STATUS + id,
    }),

    // DECORATIVE METHOD VENDOR PRICE EDIT
    decorativeVendorPriceEdit: builder.mutation({
      query: (payload) => ({
        url: URL.DECORATIVE_PRICE_EDIT,
        method: "POST",
        body: payload,
      }),
    }),

    // DECORATIVE METHOD PRICE CREATE
    decorativePriceAdd: builder.mutation({
      query: (payload) => ({
        url: URL.DECORATIVE_PRICE,
        method: "POST",
        body: payload,
      }),
    }),

    // DECORATIVE METHOD PRICE EDIT
    decorativePriceEdit: builder.mutation({
      query: (payload) => ({
        url: URL.DECORATIVE_PRICE + "-edit",
        method: "POST",
        body: payload,
      }),
    }),

    // DECORATIVE METHOD PRICE LIST
    decorativePriceList: builder.query({
      query: (id) => URL.DECORATIVE_PRICE_LIST + id,
    }),

    // HSN LIST WITHOUT PAGINATION
    hsnAllList: builder.query({
      query: () => URL.HSN,
    }),

    // NAVIGATION LIST
    navigationList: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.NAVIGATION + "/lists" + params,
        method: "POST",
        body: payload,
      }),
    }),

    // NAVIGATION NEW LIST
    navigationListNew: builder.mutation({
      query: (payload) => ({
        url: URL.NAVIGATIONS_lIST,
        method: "POST",
        body: payload,
      }),
    }),

    // NAVIGATION CREATE
    navigationCreate: builder.mutation({
      query: (payload) => ({
        url: URL.NAVIGATIONS,
        method: "POST",
        body: payload,
      }),
    }),

    // NAVIGATION EDIT
    navigationEdit: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.NAVIGATION + "/" + id + "/update",
        method: "POST",
        body: payload,
      }),
    }),

    // NAVIGATION VIEW
    navigationView: builder.query({
      query: (id) => URL.NAVIGATIONS + "/" + id + "/edit",
    }),

    // NAVIGATION VIEW 2
    navigationView2: builder.query({
      query: (id) => URL.NAVIGATIONS + "/" + id,
    }),

    // NAVIGATION STATUS
    navigationStatus: builder.query({
      query: (id) => URL.NAVIGATION + "/status/" + id,
    }),

    // NAVIGATION ASSIGN
    navigationAssign: builder.mutation({
      query: (payload) => ({
        url: URL.NAVIGATION_ASSIGN,
        method: "POST",
        body: payload,
      }),
    }),

    // HSN LIST
    hsnList: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.HSNS + params,
        method: "POST",
        body: payload,
      }),
    }),

    // HSN WITHOUT PAGINATION LIST
    hsnNoPageList: builder.query({
      query: () => URL.HSN,
    }),

    // HSN CREATE
    hsnCreate: builder.mutation({
      query: (payload) => ({
        url: URL.HSN,
        method: "POST",
        body: payload,
      }),
    }),

    // HSN EDIT
    hsnEdit: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.HSN + "/" + id + "/update",
        method: "POST",
        body: payload,
      }),
    }),

    // HSN STATUS
    hsnStatus: builder.query({
      query: (id) => URL.HSN + "-status/" + id,
    }),

    // HSN VIEW
    hsnView: builder.query({
      query: (id) => URL.HSN + "/" + id,
    }),

    // ADMIN PROFILE EXPORTS
    adminExports: builder.mutation({
      query: (payload) => ({
        url: URL.ADMIN_EXPORTS,
        method: "POST",
        body: payload,
      }),
    }),

    // PRODUCTS CREATE
    productCreate: builder.mutation({
      query: (payload) => ({
        url: URL.PRODUCTS,
        method: "POST",
        body: payload,
      }),
    }),

    // PRODUCT EDIT
    productView: builder.query({
      query: (id) => URL.PRODUCTS + "/" + id,
    }),

    // PRODUCT UPDATE
    productUpdate: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.PRODUCTS + "/" + id + "/update",
        method: "POST",
        body: payload,
      }),
    }),

    // PRODUCTS GENERATE VARIENT
    productGenerateVarient: builder.mutation({
      query: (payload) => ({
        url: URL.PRODUCTS_GENERATE_VARIENT,
        method: "POST",
        body: payload,
      }),
    }),

    // PRODUCTS STORE VARIENT
    productStoreVarient: builder.mutation({
      query: (payload) => ({
        url: URL.PRODUCTS_VARIENT_STORE,
        method: "POST",
        body: payload,
      }),
    }),

    // PRODUCTS UPDATE VARIENT
    productUpdateVarient: builder.mutation({
      query: (payload) => ({
        url: URL.PRODUCTS_VARIENT_UPDATE,
        method: "POST",
        body: payload,
      }),
    }),

    // PRODUCTS LIST
    productList: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.PRODUCTS_LIST + params,
        method: "POST",
        body: payload,
      }),
    }),

    productListNoPage: builder.mutation({
      query: (payload) => ({
        url: URL.PRODUCTS_LIST_NO_PAGE,
        method: "POST",
        body: payload,
      }),
    }),

    // PRODUCT IMAGE DELETE
    productImgDelete: builder.query({
      query: (id) => URL.PRODUCT_IMAGES_DELETE + id,
    }),

    // PRODUCT REQUEST STATUS CHANGE
    productReqStatusChange: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.PRODUCT_REQUEST_STATUS_CHANGE + id,
        method: "POST",
        body: payload,
      }),
    }),

    // // CURRENCY LIST
    // currencyListRegister: builder.query({
    //   query: () => URL.CURRENCY_LIST,
    // }),

    // CURRENCY LIST
    currencyList: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.CURRENCYS + params,
        method: "POST",
        body: payload,
      }),
    }),

    // CURRENCY CREATE
    currencyCreate: builder.mutation({
      query: (payload) => ({
        url: URL.CURRENCIES,
        method: "POST",
        body: payload,
      }),
    }),

    // CURRENCY EDIT
    currencyEdit: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.CURRENCIES + "/" + id + "/update",
        method: "POST",
        body: payload,
      }),
    }),

    // CURRENCY STATUS
    currencyStatus: builder.query({
      query: (id) => URL.CURRENCIES + "-status/" + id,
    }),

    // CURRENCY VIEW
    currencyView: builder.query({
      query: (id) => URL.CURRENCIES + "/" + id,
    }),

    // CURRENCY ACTIVE LIST
    currencyActiveList: builder.query({
      query: () => URL.CURRENCY_ACTIVE,
    }),

    // COLORS LIST
    colorList: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.COLORS + params,
        method: "POST",
        body: payload,
      }),
    }),

    // COLORS CREATE
    colorCreate: builder.mutation({
      query: (payload) => ({
        url: URL.COLOR,
        method: "POST",
        body: payload,
      }),
    }),

    // COLORS EDIT
    colorEdit: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.COLOR + "/" + id + "/update",
        method: "POST",
        body: payload,
      }),
    }),

    // COLORS STATUS
    colorStatus: builder.query({
      query: (id) => URL.COLOR + "-status/" + id,
    }),

    // COLORS VIEW
    colorView: builder.query({
      query: (id) => URL.COLOR + "/" + id,
    }),

    colorsListAll: builder.query({
      query: () => URL.COLOR,
    }),

    // TESTIMONIAL LIST
    TestimonialList: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.TESTIMONIAL_LIST + params,
        body: payload,
        method: "POST",
      }),
    }),
    // TESTIMONIAL STORE
    TestimonialStore: builder.mutation({
      query: (payload) => ({
        url: URL.TESTIMONIAL_STORE,
        body: payload,
        method: "POST",
      }),
    }),
    // TESTIMONIAL EDIT
    TestimonialEdit: builder.mutation({
      query: (payload) => ({
        url: URL.TESTIMONIAL_EDIT,
        body: payload,
        method: "POST",
      }),
    }),
    // TESTIMONIAL SHOW
    TestimonialShow: builder.query({
      query: (id) => URL.TESTIMONIAL_SHOW + id,
    }),

    // TESTIMONIAL STATUS
    TestimonialStatus: builder.query({
      query: (id) => URL.TESTIMONIAL_STATUS + id,
    }),

    //BANNER LIST
    bannerList: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.BANNER_LIST + params,
        body: payload,
        method: "POST",
      }),
    }),
    // BANNER STATUS
    bannerStatus: builder.query({
      query: (id) => URL.BANNER_STATUS + id,
    }),

    // BANNER STORE
    bannerStore: builder.mutation({
      query: (payload) => ({
        url: URL.BANNER_STORE,
        body: payload,
        method: "POST",
      }),
    }),
    // BANNER EDIT
    bannerEdit: builder.mutation({
      query: (payload) => ({
        url: URL.BANNER_EDIT,
        body: payload,
        method: "POST",
      }),
    }),
    // BANNER VIEW
    bannerView: builder.query({
      query: (id) => URL.BANNER_VIEW + id,
    }),

    //FAQ LIST
    faqList: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.FAQ_LIST + params,
        body: payload,
        method: "POST",
      }),
    }),
    // FAQ STATUS
    faqStatus: builder.query({
      query: (id) => URL.FAQ_STATUS + id,
    }),

    // FAQ STORE
    faqStore: builder.mutation({
      query: (payload) => ({
        url: URL.FAQ_STORE,
        body: payload,
        method: "POST",
      }),
    }),
    // FAQ EDIT
    faqEdit: builder.mutation({
      query: (payload) => ({
        url: URL.FAQ_EDIT,
        body: payload,
        method: "POST",
      }),
    }),
    // FAQ VIEW
    faqView: builder.query({
      query: (id) => URL.FAQ_VIEW + id,
    }),

    //VENDOR LIST
    vendorList: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.VENDOR_LIST + params,
        body: payload,
        method: "POST",
      }),
    }),
    // VENDOR STATUS
    vendorStatus: builder.query({
      query: (id) => URL.VENDOR_STATUS + id,
    }),

    // VENDOR STORE
    vendorStore: builder.mutation({
      query: (payload) => ({
        url: URL.VENDOR_STORE,
        body: payload,
        method: "POST",
      }),
    }),
    // VENDOR EDIT
    vendorEdit: builder.mutation({
      query: (payload) => ({
        url: URL.VENDOR_EDIT,
        body: payload,
        method: "POST",
      }),
    }),
    // VENDOR VIEW
    vendorView: builder.query({
      query: (id) => URL.VENDOR_VIEW + id,
    }),
    // BLOG LIST
    bloglist: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.BLOG_LIST + params,
        method: "POST",
        body: payload,
      }),
    }),
    //BLOG ADD
    blogAdd: builder.mutation({
      query: (payload) => ({
        url: URL.BLOG_ADD,
        body: payload,
        method: "POST",
      }),
    }),
    //BLOG_EDIT
    blogEdit: builder.mutation({
      query: (payload) => ({
        url: URL.BLOG_EDIT,
        body: payload,
        method: "POST",
      }),
    }),
    //BLOG_STATUS
    blogStatus: builder.query({
      query: (id) => ({
        url: URL.BLOG_STATUS + id,
      }),
    }),

    //BLOG_VIEW
    //  blogView:builder.query({
    //   query: (id) =>({
    //     url: URL.BLOG_VIEW + id,
    //   }),
    // }),
    //   }),

    // });
    //BLOGVIEW
    blog_view: builder.query({
      query: (id) => ({
        url: URL.BLOG_VIEW + id,
      }),
    }),
    //HQ_LIST
    hqList: builder.mutation({
      query: (payload) => ({
        url: URL.HQ_LIST,
        body: payload,
        method: "POST",
      }),
    }),
    hqEdit: builder.mutation({
      query: (payload) => ({
        url: URL.HQ_EDIT,
        body: payload,
        method: "POST",
      }),
    }),
    //HQ_VIEW
    hq_view: builder.query({
      query: (id) => ({
        url: URL.HQ_VIEW + id,
      }),
    }),
    //VENTOR_REGISTER
    vendor_store_register: builder.mutation({
      query: (payload) => ({
        url: URL.VENDOR_STORE_REGISTER,
        body: payload,
        method: "POST",
      }),
    }),
    //VENTOR_LIBRARY STORE
    vendorLibraryStore: builder.mutation({
      query: (payload) => ({
        url: URL.VENDOR_LIBRARY_STORE,
        body: payload,
        method: "POST",
      }),
    }),
    //VENTOR_LIBRARY LIST
    vendorLibraryList: builder.mutation({
      query: (payload) => ({
        url: URL.VENDOR_LIBRARY_LIST,
        body: payload,
        method: "POST",
      }),
    }),
    //VENTOR_LIBRARY EDIT
    vendorLibraryEdit: builder.mutation({
      query: (payload) => ({
        url: URL.VENDOR_LIBRARY_EDIT,
        body: payload,
        method: "POST",
      }),
    }),
    // VENDOR DEPARTMENT
    vendorDepartmentList: builder.mutation({
      query: (payload) => ({
        url: URL.VENDOR_DEPARTMENT,
        method: "POST",
        body: payload,
      }),
    }),
    // CURRENCY LIST
    currencyLists: builder.query({
      query: () => URL.CURRENCY,
    }),

    //PROFILE
    profile_status: builder.query({
      query: (id) => ({
        url: URL.STATUS_LIST + id,
      }),
    }),
    addProfile: builder.mutation({
      query: (payload) => ({
        url: URL.ADD_PROFILE,
        method: "POST",
        body: payload,
      }),
    }),
    editProfile: builder.mutation({
      query: (payload) => ({
        url: URL.EDIT_PROFILE,
        method: "POST",
        body: payload,
      }),
    }),

    // PRODUCT VENDOR REQUEST PRODUCT_LIST
    productReqList: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.PRODUCT_VENDOR_REQUEST_PRODUCT_LIST + params,
        method: "POST",
        body: payload,
      }),
    }),

    // PRODUCT VENDOR PRICE PRODUCT LIST
    productPriceList: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.PRODUCT_VENDOR_PRICE_PRODUCT_LIST + params,
        method: "POST",
        body: payload,
      }),
    }),

    // PRODUCT VENDOR PRICE REQUEST VIEW
    proVendorPriceReqShow: builder.query({
      query: (id) => URL.PRODUCT_VENDOR_PRICE_REQUEST_VIEW + id,
    }),

    // GET PRODUCT VENDOR PRICE LIST
    getProductVendorPriceList: builder.query({
      query: (id) => URL.GET_PRODUCT_VENDOR_PRICE_LIST + id,
    }),

    // GET PRODUCT DECOR PRICE LIST
    getProductDecorPriceList: builder.query({
      query: (id) => URL.GET_PRODUCT_DECOR_PRICE_LIST + id,
    }),

    // ADMIN ADD PRODUCT PRICE
    adminAddProPrice: builder.mutation({
      query: (payload) => ({
        url: URL.ADMIN_ADD_PRODUCT_PRICE,
        method: "POST",
        body: payload,
      }),
    }),

    // ADMIN VENDOR PRICE CHANGE
    adminVendorPriceStatusChange: builder.mutation({
      query: (payload) => ({
        url: URL.ADMIN_VENDOR_PRICE_CHANGE,
        method: "POST",
        body: payload,
      }),
    }),

    // PRODUCT SPECIFIC VENDOR PRICE
    proSpecificVendorPrice: builder.query({
      query: (id) => URL.PRODUCT_SPECIFIC_VENDOR_PRICE + id,
    }),

    // PRODUCT SPECIFIC VENDOR PRICE
    productsCurrentStatusChange: builder.mutation({
      query: (payload) => ({
        url: URL.PRODUCTS_CHANGE_CURRENT_STAUTS,
        method: "POST",
        body: payload,
      }),
    }),

    // PRODUCTS BULK STATUS
    prodBulkStatus: builder.mutation({
      query: (payload) => ({
        url: URL.PRODUCTS_BULK_STATUS,
        method: "POST",
        body: payload,
      }),
    }),

    // ASSIGN VENDOR PRODUCT PRICE PRIORITY
    assignVendorProPricePriority: builder.mutation({
      query: (payload) => ({
        url: URL.ASSIGN_VENDOR_PRODUCT_PRICE_PRIORITY,
        method: "POST",
        body: payload,
      }),
    }),

    // ADDRESS STATUS
    addressStatus: builder.query({
      query: (id) => URL.ADDRESS_STATUS + id,
    }),

    // ADDRESS LIST
    addressList: builder.query({
      query: (id) => URL.ADDRESS_LIST + id,
    }),

    roleLists: builder.mutation({
      query: (payload) => ({
        url: URL.ROLE_LIST,
        method: "POST",
        body: payload,
      }),
    }),

    // ADDRESS ADD
    addressAdd: builder.mutation({
      query: (payload) => ({
        url: URL.ADDRESS_ADD,
        method: "POST",
        body: payload,
      }),
    }),

    // ADDRESS EDIT
    addressEdit: builder.mutation({
      query: (payload) => ({
        url: URL.ADDRESS_EDIT,
        method: "POST",
        body: payload,
      }),
    }),
    // VENDOR POSITION CHANGE
    vendorPositionChange: builder.mutation({
      query: (payload) => ({
        url: URL.VENDOR_POSITION_CHANGE,
        method: "POST",
        body: payload,
      }),
    }),

    // VENDOR MAP PRICE
    vendorMapPriceAdd: builder.mutation({
      query: (payload) => ({
        url: URL.VENDOR_MAP_PRICE,
        method: "POST",
        body: payload,
      }),
    }),

    // VENDOR MAP PRICE
    vendorAcceptedProList: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.VENDOR_ACCEPTED_MAP_PRODUCT + params,
        method: "POST",
        body: payload,
      }),
    }),

    // VENDOR PRICE CHANGE
    vendorMapPriceUpdate: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.VENDOR_PRICE_CHANGE + id + "/update-price",
        method: "POST",
        body: payload,
      }),
    }),

    // VENDOR ACCEPTED VIEW
    vendorAcceptedShow: builder.query({
      query: (id) => URL.VENDOR_ACCEPTED_VIEW + id,
    }),

    // VENDOR APPROVED PRODUCTS STATUS
    vendorMapProdStatus: builder.mutation({
      query: (payload) => ({
        url: URL.VENDOR_ACCEPTED_STAUTS,
        method: "POST",
        body: payload,
      }),
    }),

    vendorViewNew: builder.query({
      query: (id) => URL.VENDOR_VIEW_NEW + id,
    }),
    // VENDOR EDIT
    vendorCreateEdit: builder.mutation({
      query: (payload) => ({
        url: URL.VENDOR_DETAILS_EDIT,
        method: "POST",
        body: payload,
      }),
    }),
    //vendor Status
    vendorStatusList: builder.query({
      query: (id) => URL.VENDOR_STATUS_LIST + id,
    }),
    //DECORATIVE METHODE
    decorativeMethodeAdded: builder.mutation({
      query: (payload) => ({
        url: URL.DECORATIVE_ADDED,
        method: "POST",
        body: payload,
      }),
    }),

    decorativeMethodeList: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.DECORATIVE_LIST + params,
        method: "POST",
        body: payload,
      }),
    }),

    decorativeMethodeStatus: builder.query({
      query: (id) => URL.DECORATIVE_STATUS + id,
    }),

    decorativeMethodeStatusPost: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.DECORATIVE_PRICE_STATUS + id,
        method: "POST",
        body: payload,
      }),
    }),

    decorativeMethodeView: builder.query({
      query: (id) => URL.DECORATIVE_VIEW + id,
    }),

    decorativeMethodeViewPost: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.DECORATIVE_VIEW + id,
        method: "POST",
        body: payload,
      }),
    }),

    // EVENT CATEGORY ADD
    eventCategoryStore: builder.mutation({
      query: (payload) => ({
        url: URL.EVENT_CATEGORY_STORE,
        method: "POST",
        body: payload,
      }),
    }),

    // PRESET PACK LIST
    presetPackList: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.PRESET_PACK_LIST + params,
        method: "POST",
        body: payload,
      }),
    }),

    // PRESET PACK STORE
    presetPackStore: builder.mutation({
      query: (payload) => ({
        url: URL.PRESET_PACK,
        method: "POST",
        body: payload,
      }),
    }),

    // PRESET PACK PRIORITY
    presetPackPriority: builder.mutation({
      query: (payload) => ({
        url: URL.PRESET_PACK_PRIORITY,
        method: "POST",
        body: payload,
      }),
    }),

    // PRESET PACK FILETR BY SKU
    filterBySku: builder.mutation({
      query: (payload) => ({
        url: URL.FILTER_BY_SKU,
        method: "POST",
        body: payload,
      }),
    }),

    // PRESET PACK VIEW
    presetPackView: builder.query({
      query: (id) => URL.PRESET_PACK_VIEW + id,
    }),

    // PRESET PACK VIEW
    presetPackEdit: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.PRESET_PACK_EDIT + id,
        method: "POST",
        body: payload,
      }),
    }),

    // PRESET PACK STATUS
    presetPackStaus: builder.query({
      query: (id) => URL.PRESET_PACK_STATUS + id,
    }),

    // EVENT CATEGORY EDIT
    eventCategoryEdit: builder.mutation({
      query: (payload) => ({
        url: URL.EVENT_CATEGORY_EDIT,
        method: "POST",
        body: payload,
      }),
    }),

    // EVENT CATEGORY LIST
    eventCategoryList: builder.mutation({
      query: (payload) => ({
        url: URL.EVENT_CATEGORY_LIST,
        method: "POST",
        body: payload,
      }),
    }),

    //EVENT STATUS
    eventStatus: builder.query({
      query: (id) => URL.EVENT_CATEGORY_STATUS + id,
    }),

    //EVENT CATEGORY ASSIGN
    eventCategoryAssign: builder.mutation({
      query: (payload) => ({
        url: URL.EVENT_CATEGORY_ASSIGN,
        method: "POST",
        body: payload,
      }),
    }),

    //PRODUCTSUGGESTION

    productSuggestionList: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.PRODUCT_SUGGESTION_LIST + params,
        method: "POST",
        body: payload,
      }),
    }),
    //PRODUCT SUGGESTION STATUS
    productSuggestionStatus: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.PRODUCT_SUGGESTION_STATUS + id,
        method: "POST",
        body: payload,
      }),
    }),

    productSuggestionStore: builder.mutation({
      query: (payload) => ({
        url: URL.PRODUCT_SUGGESTION_ADD,
        method: "POST",
        body: payload,
      }),
    }),
    // DOWNLOAD SAMPLE EXCELS
    downloadSampleExcels: builder.mutation({
      query: (payload) => ({
        url: URL.DOWNLOAD_SAMPLE_EXCELS,
        method: "POST",
        body: payload,
      }),
    }),
    productSuggestionEdit: builder.mutation({
      query: (payload) => ({
        url: URL.PRODUCT_SUGGESTION_EDIT,
        method: "POST",
        body: payload,
      }),
    }),

    // DOWNLOAD ADMIN PROFILE
    downloadAdminProfile: builder.mutation({
      query: (payload) => ({
        url: URL.DOWNLOAD_ADMIN_PROFILE,
        method: "POST",
        body: payload,
      }),
    }),

    productSuggestionView: builder.query({
      query: (id) => URL.PRODUCT_SUGGESTION_VIEW + id,
    }),

    // DOWNLOAD CATEGORY ROLE
    downloadCategoryRole: builder.mutation({
      query: (payload) => ({
        url: URL.DOWNLOAD_CATEGORY_ROLE,
        method: "POST",
        body: payload,
      }),
    }),

    // DOWNLOAD VENDOR
    downloadVendor: builder.mutation({
      query: (payload) => ({
        url: URL.DOWNLOAD_VENDOR_ROLE,
        method: "POST",
        body: payload,
      }),
    }),

    // DELETE VENDOR LOGO
    vendorLogoDelete: builder.mutation({
      query: (payload) => ({
        url: URL.VENDOR_LOGO_DELETE,
        method: "POST",
        body: payload,
      }),
    }),

    // VENDOR MAP PRO LIST
    vendorMapProList: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.VENDOR_MAP_PRO_LIST + params,
        method: "POST",
        body: payload,
      }),
    }),

    // VENDOR MAP VIEW
    vendorMapView: builder.mutation({
      query: (payload) => ({
        url: URL.VENDOR_MAP_VIEW,
        method: "POST",
        body: payload,
      }),
    }),

    // DOWNLOAD HSN
    downloadHsn: builder.mutation({
      query: (payload) => ({
        url: URL.DOWNLOAD_HSN_ROLE,
        method: "POST",
        body: payload,
      }),
    }),
    // Request List
    requestList: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.REQUEST_LIST + params,
        method: "POST",
        body: payload,
      }),
    }),

    // Request Store
    requestStore: builder.mutation({
      query: (payload) => ({
        url: URL.REQUEST_STORE,
        method: "POST",
        body: payload,
      }),
    }),

    // Request Edit
    requestEdit: builder.mutation({
      query: (payload) => ({
        url: URL.REQUEST_EDIT,
        method: "POST",
        body: payload,
      }),
    }),

    // Request sts
    requestStatus: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.REQUEST_STATUS_CHANGE + id,
        method: "POST",
        body: payload,
      }),
    }),

    requestView: builder.query({
      query: (id) => URL.REQUEST_VIEW + id,
    }),

    // CUSTOMER LIST
    customerList: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.CUSTOMER_LIST + params,
        method: "POST",
        body: payload,
      }),
    }),

    // CUSTOMER LIST STATUS
    customerListStatus: builder.query({
      query: (id) => URL.CUSTOMER_LIST_STATUS + id,
    }),

    // CUSTOMER LIST VIEW
    customerListView: builder.query({
      query: (id) => URL.CUSTOMER_LIST_VIEW + id,
    }),

    // CUSTOMER REQUEST LIST
    customerRequestList: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.CUSTOMER_REQUEST_LIST + params,
        method: "POST",
        body: payload,
      }),
    }),

    // CUSTOMER REQUEST LIST VIEW REGISTERED
    customerRequestListView: builder.query({
      query: (id) => URL.CUSTOMER_REQUEST_LIST_VIEW + id,
    }),

    // CUSTOMER REQUEST LIST VIEW NON REGISTERED
    customerRequestListViewNon: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.CUSTOMER_REQUEST_LIST_VIEW_NON + params,
        method: "POST",
        body: payload,
      }),
    }),

    //PRICE CHANGE LIST
    priceList: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.PRICE_CHANGE_LIST + params,
        method: "POST",
        body: payload,
      }),
    }),

    // CUSTOMER REQUEST LIST EDIT NON REGISTERED
    customerRequestListEditNon: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.CUSTOMER_REQUEST_LIST_EDIT_NON + params,
        method: "POST",
        body: payload,
      }),
    }),

    // ADMIN LIST CUSTOMER REQ
    adminListCustomerReq: builder.mutation({
      query: (payload) => ({
        url: URL.ADMIN_LIST_CUSTOMER_REQ,
        method: "POST",
        body: payload,
      }),
    }),

    // CUSTOMER REQUEST LIST TAGS
    customerRequestListTags: builder.query({
      query: () => URL.CUSTOMER_LIST_TAGS,
    }),

    //SKU CODE LIST
    skuCode: builder.query({
      query: (id) => URL.SKU_LIST,
    }),

    // CATEGORY VIEW ACTIVE
    newCategoryView: builder.query({
      query: (id) => URL.NEW_CATEGORY + id,
    }),

    // DOWNLOAD TAGS
    downloadTags: builder.mutation({
      query: (payload) => ({
        url: URL.DOWNLOAD_TAGS,
        method: "POST",
        body: payload,
      }),
    }),

    // DOWNLOAD Event TAGS
    downloadEventTags: builder.mutation({
      query: (payload) => ({
        url: URL.DOWNLOAD_EVENT_TAG,
        method: "POST",
        body: payload,
      }),
    }),
    // DOWNLOAD Icon TAGS
    downloadIconTags: builder.mutation({
      query: (payload) => ({
        url: URL.DOWNLOAD_ICON_TAG,
        method: "POST",
        body: payload,
      }),
    }),
    // DOWNLOAD CATEGORY SETTINGS
    downloadCategorySettings: builder.mutation({
      query: (payload) => ({
        url: URL.DOWNLOAD_CATEGORY_SETTINGS,
        method: "POST",
        body: payload,
      }),
    }),
    // DOWNLOAD VENDOR CREATION
    downloadVendorCreation: builder.mutation({
      query: (payload) => ({
        url: URL.DOWNLOAD_VENDOR_CREATION,
        method: "POST",
        body: payload,
      }),
    }),
    // DOWNLOAD PACKS
    downloadPacks: builder.mutation({
      query: (payload) => ({
        url: URL.DOWNLOAD_PACKS,
        method: "POST",
        body: payload,
      }),
    }),
    // DOWNLOAD PRODUCT LIBRARY
    downloadProductLibrary: builder.mutation({
      query: (payload) => ({
        url: URL.DOWNLOAD_PRODUCT_LIBRARY,
        method: "POST",
        body: payload,
      }),
    }),
    customerFileEdit: builder.mutation({
      query: (payload) => ({
        url: URL.CUSTOMER_EDIT_FILE,
        method: "POST",
        body: payload,
      }),
    }),
    customerNotes: builder.mutation({
      query: (payload) => ({
        url: URL.CUSTOMER_EDIT_NOTES,
        method: "POST",
        body: payload,
      }),
    }),
    vendorExport: builder.mutation({
      query: (payload) => ({
        url: URL.VENDOR_EXPORT,
        method: "POST",
        body: payload,
      }),
    }),
    // product STATUS
    customerProductSts: builder.query({
      query: (id) => URL.CUSTOMER_PRODUCT_REQUEST_STATUS + id,
    }),

    // Overview Attributes
    customerProductAdd: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.CUSTOMER_PRODUCT_REQUEST_ADD + id,
        method: "POST",
        body: payload,
      }),
    }),

    // Overview Attributes
    overViewAttributesAdd: builder.mutation({
      query: (payload) => ({
        url: URL.OVERVIEW_ATTRIBUTES_ADD,
        method: "POST",
        body: payload,
      }),
    }),
    overViewAttributesList: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.OVERVIEW_ATTRIBUTES_LIST + params,
        method: "POST",
        body: payload,
      }),
    }),

    overViewAttributesEdit: builder.query({
      query: (id) => URL.OVERVIEW_ATTRIBUTES_EDIT + id + "/edit",
    }),

    overViewAttributesUpdate: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.OVERVIEW_ATTRIBUTES_UPDATE + id + "/update",
        method: "POST",
        body: payload,
      }),
    }),

    overViewAttributesStatus: builder.query({
      query: (id) => URL.OVERVIEW_ATTRIBUTES_STATUS + id,
    }),

    // BOX LIST
    boxList: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.BOX_LIST + params,
        method: "POST",
        body: payload,
      }),
    }),

    // BOX WITHOUT PAGINATION LIST
    boxNoPageList: builder.query({
      query: () => URL.BOX + "list",
    }),

    // BOX STORE
    boxCreate: builder.mutation({
      query: (payload) => ({
        url: URL.BOX + "store",
        method: "POST",
        body: payload,
      }),
    }),

    // BOX EDIT
    boxUpdate: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.BOX + "update/" + id,
        method: "POST",
        body: payload,
      }),
    }),

    // BOX VIEW
    boxView: builder.query({
      query: (id) => URL.BOX + "view/" + id,
    }),

    // BOX STATUS
    boxStatus: builder.query({
      query: (id) => URL.BOX + "status/" + id,
    }),

    // PROJECT STATUS CHANGE
    projectStatusChange: builder.mutation({
      query: (payload) => ({
        url: URL.PROJECT_STATUS_CHANGE,
        method: "POST",
        body: payload,
      }),
    }),

    // PROJECT MARGIN REQUSET
    projectMarginRequest: builder.mutation({
      query: (payload) => ({
        url: URL.PROJECT_MARGIN_REQUSET,
        method: "POST",
        body: payload,
      }),
    }),

    // PROJECT MARGIN UPDATE
    projectMarginUpdate: builder.mutation({
      query: (payload) => ({
        url: URL.PROJECT_MARGIN_UPDATE,
        method: "POST",
        body: payload,
      }),
    }),

    // PRODUCT STATUS CHANGE
    productStatus: builder.mutation({
      query: (payload) => ({
        url: URL.PRODUCT_STATUS,
        method: "POST",
        body: payload,
      }),
    }),

    // SHIPPING CHARGE LIST
    shippingChargeList: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.SHIPPING_CHARGE_LIST + params,
        method: "POST",
        body: payload,
      }),
    }),

    // SHIPPING CHARGE WITHOUT PAGINATION LIST
    shippingChargeNoPageList: builder.query({
      query: () => URL.SHIPPING_CHARGE + "list",
    }),

    // SHIPPING CHARGE STORE
    shippingChargeCreate: builder.mutation({
      query: (payload) => ({
        url: URL.SHIPPING_CHARGE + "store",
        method: "POST",
        body: payload,
      }),
    }),

    // SHIPPING CHARGE EDIT
    shippingChargeUpdate: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.SHIPPING_CHARGE + "update/" + id,
        method: "POST",
        body: payload,
      }),
    }),

    // SHIPPING CHARGE VIEW
    shippingChargeView: builder.query({
      query: (id) => URL.SHIPPING_CHARGE + "view/" + id,
    }),

    // SHIPPING CHARGE STATUS
    shippingChargeStatus: builder.query({
      query: (id) => URL.SHIPPING_CHARGE + "status/" + id,
    }),

    // VENDOR SCHEDULE LIST
    vendorScheduleList: builder.mutation({
      query: (payload) => ({
        url: URL.VENDOR_SCHEDULE_LIST,
        method: "POST",
        body: payload,
      }),
    }),

    // VENDOR SCHEDULE STORE
    vendorScheduleStore: builder.mutation({
      query: (payload) => ({
        url: URL.VENDOR_SCHEDULE_STORE,
        method: "POST",
        body: payload,
      }),
    }),

    // STATUS CHANGE PRODUCT REQUEST
    statusChangeProductRequest: builder.mutation({
      query: (payload) => ({
        url: URL.STATUS_CHANGE_PRODUCT_REQUEST,
        method: "POST",
        body: payload,
      }),
    }),

    // CUSTOMER REJECT PRO REQUEST
    customerRejectProRequest: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.CUSTOMER_REJECT_PRO_REQUEST + params,
        method: "POST",
        body: payload,
      }),
    }),

    // PROJECT LIST
    projectList: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.PROJECT_LIST + params,
        method: "POST",
        body: payload,
      }),
    }),

    // PROJECT LIST
    projectUpdate: builder.mutation({
      query: (payload) => ({
        url: URL.PROJECT_UPDATE,
        method: "POST",
        body: payload,
      }),
    }),

    // DEPARTMENT LIST
    deparmentList: builder.mutation({
      query: (payload) => ({
        url: URL.DEPARTMENT_LIST,
        method: "POST",
        body: payload,
      }),
    }),
    // PRODUCT TASK UPDATE
    producttaskUpdate: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.PRODUCT_TASK_UPDATE + id,
        method: "POST",
        body: payload,
      }),
    }),

    // PRODUCT TASK LIST
    producttasklistList: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.PRODUCT_TASK_LIST,
        method: "POST",
        body: payload,
      }),
    }),
    // ACTIVITY LIST
    activityList: builder.mutation({
      query: (payload) => ({
        url: URL.ACTIVITY_LIST,
        method: "POST",
        body: payload,
      }),
    }),
    // PRODUCT TASK ADD
    producttaskadd: builder.mutation({
      query: (payload) => ({
        url: URL.PRODUCT_TASK_ADD,
        method: "POST",
        body: payload,
      }),
    }),

    // COMMANDS ADD
    commandsadd: builder.mutation({
      query: (payload) => ({
        url: URL.COMMANDS_ADD,
        method: "POST",
        body: payload,
      }),
    }),
    //PROJECTS LIST
    projectsList: builder.query({
      query: () => URL.PROJECT_GET_LISTS,
    }),
    // PRODUCT_VIEW
    producttaskView: builder.query({
      query: (id) => URL.PRODUCT_TASK_VIEW + id,
    }),
    // PROJECT VIEW
    projectView: builder.query({
      query: (id) => URL.PROJECT_VIEW + id,
    }),

    // PRESENTATION ADD
    presentationAdd: builder.mutation({
      query: (payload) => ({
        url: URL.PRESENTATION_ADD,
        method: "POST",
        body: payload,
      }),
    }),
    // FILES ADD
    fileAdd: builder.mutation({
      query: (payload) => ({
        url: URL.FILES_ADD,
        body: payload,
        method: "POST",
      }),
    }),
    // FILES list
    fileList: builder.mutation({
      query: (payload) => ({
        url: URL.FILES_LIST,
        body: payload,
        method: "POST",
      }),
    }),
    // files update
    filesUpdate: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.FILES_EDIT + id,
        method: "POST",
        body: payload,
      }),
    }),

    fileView: builder.mutation({
      query: (id) => ({
        url: URL.FILE_VIEW + id,
        method: "GET",
      }),
    }),
    fileDownload: builder.mutation({
      query: (id) => ({
        url: URL.FILE_DOWNLOAD + id,
        method: "GET",
      }),
    }),
    fileDelete: builder.mutation({
      query: (id) => ({
        url: URL.FILE_DELETE + id,
        method: "GET",
      }),
    }),
    // CLIENT FILE
    filesClient: builder.mutation({
      query: (payload) => ({
        url: URL.FILE_CLIENT,
        method: "POST",
        body: payload,
      }),
    }),
    activityPin: builder.mutation({
      query: (payload) => ({
        url: URL.FILE_PIN,
        method: "POST",
        body: payload,
      }),
    }),
    // PRESENTATION STATUS
    presentationStatus: builder.mutation({
      query: (payload) => ({
        url: URL.PRESENTATION_STATUS,
        method: "POST",
        body: payload,
      }),
    }),

    // PRESENTATION DELETE
    presentationDelete: builder.query({
      query: (id) => URL.PRESENTATION_DELETE + id,
    }),

    // PRESENTATION ADD PROJECT
    presentationProAdd: builder.mutation({
      query: (payload) => ({
        url: URL.PRESENTATION_ADD_PROJECT,
        method: "POST",
        body: payload,
      }),
    }),

    // PRESENTATION VIEW PROJECT
    presentationProView: builder.query({
      query: (id) => URL.PRESENTATION_VIEW_PROJECT + id,
    }),

    // PRESENTATION ADD PROJECT
    presentationProComment: builder.mutation({
      query: (payload) => ({
        url: URL.PRESENTATION_COMMENT_PROJECT,
        method: "POST",
        body: payload,
      }),
    }),

    // PRESENTATION PRICING PROJECT
    presentationProPricing: builder.mutation({
      query: (payload) => ({
        url: URL.PRESENTATION_PRICING,
        method: "POST",
        body: payload,
      }),
    }),

    // PRESENTATION ARTWORK STATUS
    presentationArtworkStatus: builder.mutation({
      query: (payload) => ({
        url: URL.PRESENTATION_ARTWORK_STATUS,
        method: "POST",
        body: payload,
      }),
    }),

    // PRESENTATION ARTWORK STORE
    artworkStore: builder.mutation({
      query: (payload) => ({
        url: URL.ARTWORK_STORE,
        method: "POST",
        body: payload,
      }),
    }),

    // PRESENTATION ARTWORK DELETE
    artworkDelete: builder.query({
      query: (id) => URL.ARTWORK_DELETE + id,
    }),

    // ADD PRODUCT INFO
    addProductInfo: builder.mutation({
      query: (payload) => ({
        url: URL.ADD_PRODUCT_INFO,
        method: "POST",
        body: payload,
      }),
    }),

    // Activity ADD
    activityAdd: builder.mutation({
      query: (payload) => ({
        url: URL.ACTIVITY_ADD,
        method: "POST",
        body: payload,
      }),
    }),

    // ESTIMATE ADD
    estimateAdd: builder.mutation({
      query: (payload) => ({
        url: URL.ESTIMATE_ADD,
        method: "POST",
        body: payload,
      }),
    }),

    // ESTIMATE STATUS
    estimateStatus: builder.mutation({
      query: (payload) => ({
        url: URL.ESTIMATE_STATUS,
        method: "POST",
        body: payload,
      }),
    }),

    // ESTIMATE EDIT
    estimateEdit: builder.mutation({
      query: (payload) => ({
        url: URL.ESTIMATE_EDIT,
        method: "POST",
        body: payload,
      }),
    }),

    // ESTIMATE EDIT
    estimateUpdate: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.ESTIMATE_UPDATE + id,
        method: "POST",
        body: payload,
      }),
    }),

    // ESTIMATE SIZE
    estimateSize: builder.mutation({
      query: (payload) => ({
        url: URL.ESTIMATE_SIZE,
        method: "POST",
        body: payload,
      }),
    }),

    // ESTIMATE QTY ADD
    estimateQtyAdd: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.ESTIMATE_QTY_ADD + id,
        method: "POST",
        body: payload,
      }),
    }),

    // ESTIMATE DETAIL
    estimateDetail: builder.query({
      query: (id) => URL.ESTIMATE_DETAIL + id,
    }),

    // ESTIMATE TOTAL UPDATE
    estimateTotalUpdate: builder.mutation({
      query: (payload) => ({
        url: URL.ESTIMATE_TOTAL_UPDATE,
        method: "POST",
        body: payload,
      }),
    }),

    estimateTax: builder.query({
      query: (id) => URL.ESTIMATE_TAX + id,
    }),

    // PROJECT ADDRESS LIST
    projectAddressList: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.PROJECT_ADDRESS_LIST + params,
        method: "POST",
        body: payload,
      }),
    }),

    // PROJECT ADDRESS DELETE
    projectAddressDelete: builder.query({
      query: (id) => URL.PROJECT_ADDRESS_DELETE + id,
    }),

    // PROJECT ADDRESS ADD
    projectAddressAdd: builder.mutation({
      query: (payload) => ({
        url: URL.PROJECT_ADDRESS_ADD,
        method: "POST",
        body: payload,
      }),
    }),
    // comments ADD
    filecommentAdd: builder.mutation({
      query: (payload) => ({
        url: URL.FILE_COMMENTS,
        method: "POST",
        body: payload,
      }),
    }),
    // CLIENTS COMMENT ADD
    fileclientcommandAdd: builder.mutation({
      query: (payload) => ({
        url: URL.FILE_CLIENT_COMMENTS,
        method: "POST",
        body: payload,
      }),
    }),
    // PROJECT ADDRESS EDIT
    projectAddressEdit: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.PROJECT_ADDRESS_UPDATE + id,
        method: "POST",
        body: payload,
      }),
    }),

    // PROJECT ADDRESS NO PAGE LIST
    projectAddressNoPageList: builder.query({
      query: (id) => URL.PROJECT_ADDRESS_LIST_NO_PAGE + "/" + id,
    }),

    // PROJECT ADDRESS NO PAGE LIST
    projectAddressView: builder.query({
      query: (id) => URL.PROJECT_ADDRESS_VIEW + id,
    }),

    // GET SHIPPING BOX QTY
    getShippingBoxQty: builder.mutation({
      query: (payload) => ({
        url: URL.GET_SHIPPING_BOX_QTY,
        method: "POST",
        body: payload,
      }),
    }),

    // SHIPPING PRICE
    shippingPrice: builder.mutation({
      query: (payload) => ({
        url: URL.SHIPPING_PRICE,
        method: "POST",
        body: payload,
      }),
    }),

    // INVENRTY PRICE
    inventryPrice: builder.mutation({
      query: (payload) => ({
        url: URL.INVENRTY_PRICE,
        method: "POST",
        body: payload,
      }),
    }),

    // STORE PROJECT BOX
    storeProjectBox: builder.mutation({
      query: (payload) => ({
        url: URL.STORE_PROJECT_BOX,
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLazyLogoutQuery,
  useChangePasswordMutation,
  useSetPasswordMutation,
  useLazyTokenVerifyQuery,
  useRoleCreateQuery,
  useRoleEditMutation,
  useRoleStoreMutation,
  useLazyRoleStatusQuery,
  useLazyRoleViewQuery,
  useLazyRolesQuery,
  useActiveRolesQuery,
  useLazyRoleViewWithTypeQuery,
  useLazyAdminsQuery,
  useAdminAddMutation,
  useAdminEditMutation,
  useLazyAdminViewQuery,
  useLazyAdminStatusQuery,
  useLazyAdminViewIdQuery,
  useLazyCategoriesQuery,
  useCategoryAddMutation,
  useCategoryEditMutation,
  useLazyCategoryStatusQuery,
  useLazyCategoryViewQuery,
  useLazyProVendorPriceReqShowQuery,
  useCategoriesAllActiveListMutation,
  useLazyCategoryActiveViewQuery,
  useMasterAddMutation,
  useMasterEditMutation,
  useLazyMasterStatusQuery,
  useLazyMasterViewQuery,
  useMasterListMutation,
  useMasterActiveListMutation,
  useLazyMasterNoPageListQuery,
  useForgotPasswordMutation,
  useAdminsListMutation,
  useRoleListMutation,
  useLazyCategoriesListVendorQuery,
  useVendorRegisterMutation,
  useVendorsListMutation,
  useLazyVendorsActiveListQuery,
  useVendorsEditMutation,
  useLazyVendorsViewQuery,
  useDepartmentRoleMutation,
  useAdminVendorPriceStatusChangeMutation,
  useLazyGetProductDecorPriceListQuery,
  useLazyDepartmentActiveRoleListQuery,
  useCategoryListsMutation,
  useLazyCategoriesAllQuery,
  useLazyTagsListQuery,
  useLazyTagsProductListQuery,
  useTagsCreateMutation,
  useLazyTagsStatusQuery,
  useTagsEditMutation,
  useLazyTagsViewQuery,
  useTagsListsMutation,
  useProductPriceListMutation,
  useLazyProSpecificVendorPriceQuery,
  useProductsCurrentStatusChangeMutation,
  useAssignVendorProPricePriorityMutation,
  useLazyVendorAcceptedShowQuery,
  useVendorMapProdStatusMutation,
  // useLazyProVendorPriceReqViewQuery,
  // useAdminVendorPriceChangeMutation,
  useProductReqStatusChangeMutation,
  useVendorMapProListMutation,
  useProdBulkStatusMutation,
  useProductReqListMutation,
  useProductListMutation,
  useProductListNoPageMutation,
  useProductCreateMutation,
  useProductUpdateMutation,
  useLazyProductViewQuery,
  useProductGenerateVarientMutation,
  useProductStoreVarientMutation,
  useProductUpdateVarientMutation,
  useAdminAddProPriceMutation,
  useLazyAttributeListQuery,
  useAttributeListsMutation,
  useLazyAttributeViewQuery,
  useAttributeCreateMutation,
  useAttributeEditMutation,
  useLazyAttributeStatusQuery,
  useDecorativePriceEditMutation,
  useDecorativeCreateMutation,
  useDecorativeEditMutation,
  useDecorativeListsMutation,
  useLazyDecorativeListQuery,
  useLazyDecorativeStatusQuery,
  useLazyDecorativeViewQuery,
  useLazyDecorativeViewNewQuery,
  useLazyDecorativePriceListQuery,
  useDecorativePriceAddMutation,
  useDecorativeVendorPriceEditMutation,
  useNavigationListMutation,
  useNavigationCreateMutation,
  useNavigationAssignMutation,
  useNavigationEditMutation,
  useNavigationListNewMutation,
  useLazyNavigationViewQuery,
  useLazyNavigationStatusQuery,
  useLazyNavigationView2Query,
  useHsnListMutation,
  useHsnCreateMutation,
  useHsnEditMutation,
  useLazyHsnAllListQuery,
  useLazyHsnViewQuery,
  useLazyHsnStatusQuery,
  useAdminExportsMutation,
  useCurrencyCreateMutation,
  useCurrencyEditMutation,
  useLazyCurrencyStatusQuery,
  useLazyCurrencyViewQuery,
  useCurrencyListMutation,
  useLazyCurrencyActiveListQuery,
  useLazyColorsListAllQuery,
  useColorCreateMutation,
  useColorEditMutation,
  useColorListMutation,
  useLazyColorStatusQuery,
  useLazyColorViewQuery,
  useTestimonialListMutation,
  useTestimonialStoreMutation,
  useTestimonialEditMutation,
  useLazyTestimonialStatusQuery,
  useLazyTestimonialShowQuery,
  useBannerListMutation,
  useLazyBannerStatusQuery,
  useBannerEditMutation,
  useBannerStoreMutation,
  useLazyBannerViewQuery,
  useFaqListMutation,
  useLazyFaqStatusQuery,
  useFaqEditMutation,
  useFaqStoreMutation,
  useLazyFaqViewQuery,
  useVendorListMutation,
  useLazyVendorStatusQuery,
  useVendorStoreMutation,
  useVendorEditMutation,
  useLazyVendorViewQuery,
  useBloglistMutation,
  useBlogAddMutation,
  useBlogEditMutation,
  useLazyBlog_viewQuery,
  useLazyBlogStatusQuery,
  useHqListMutation,
  useHqEditMutation,
  useLazyHq_viewQuery,
  useLazyCategoryProductViewQuery,
  useLazyGetProductVendorPriceListQuery,
  useLazyProductImgDeleteQuery,
  // useLazyCurrencyListRegisterQuery,
  useVendor_store_registerMutation,
  useVendorLibraryEditMutation,
  useVendorLibraryListMutation,
  useVendorLibraryStoreMutation,
  useDecorativeMethodeViewPostMutation,
  useVendorLogoDeleteMutation,
  useVendorMapViewMutation,
  useVendorMapPriceAddMutation,
  useVendorMapPriceUpdateMutation,
  useVendorAcceptedProListMutation,

  usePresetPackListMutation,
  usePresetPackPriorityMutation,
  usePresetPackStoreMutation,
  useLazyPresetPackStausQuery,
  useLazyPresetPackViewQuery,
  usePresetPackEditMutation,
  useFilterBySkuMutation,
  useCategoryProductViewNewMutation,

  useVendorDepartmentListMutation,
  useLazyCurrencyListsQuery,
  useLazyAddressStatusQuery,
  useLazyProfile_statusQuery,
  useLazyAddressListQuery,
  useAddProfileMutation,
  useEditProfileMutation,
  useRoleListsMutation,
  useAddressAddMutation,
  useAddressEditMutation,
  useVendorPositionChangeMutation,
  useLazyVendorViewNewQuery,
  useVendorCreateEditMutation,
  useLazyVendorStatusListQuery,
  useDecorativeMethodeAddedMutation,
  useDecorativeMethodeListMutation,
  useLazyDecorativeMethodeStatusQuery,
  useDecorativeMethodeStatusPostMutation,
  useLazyDecorativeMethodeViewQuery,
  useEventCategoryStoreMutation,
  useEventCategoryListMutation,
  useEventCategoryEditMutation,
  useLazyEventStatusQuery,
  useEventCategoryAssignMutation,
  useProductSuggestionListMutation,
  useProductSuggestionStatusMutation,
  useProductSuggestionStoreMutation,
  useProductSuggestionEditMutation,
  useLazyProductSuggestionViewQuery,
  useDownloadSampleExcelsMutation,
  useDownloadAdminProfileMutation,
  useDownloadCategoryRoleMutation,
  useDownloadVendorMutation,
  useDownloadHsnMutation,
  useRequestListMutation,
  useRequestStatusMutation,
  useLazyRequestViewQuery,
  useRequestStoreMutation,
  useCustomerListMutation,
  useLazyCustomerListStatusQuery,
  useLazyCustomerListViewQuery,
  useCustomerRequestListMutation,
  useLazyCustomerRequestListViewQuery,
  useRequestEditMutation,
  usePriceListMutation,
  useCustomerRequestListViewNonMutation,
  useCustomerRequestListEditNonMutation,
  useAdminListCustomerReqMutation,
  useLazyCustomerRequestListTagsQuery,
  useLazySkuCodeQuery,
  useLazyNewCategoryViewQuery,
  useDownloadTagsMutation,
  useDownloadIconTagsMutation,
  useDownloadEventTagsMutation,
  useDownloadVendorCreationMutation,
  useDownloadCategorySettingsMutation,
  useDownloadPacksMutation,
  useDownloadProductLibraryMutation,
  useCustomerNotesMutation,
  useCustomerFileEditMutation,
  useLazyCustomerProductStsQuery,
  useCustomerProductAddMutation,
  useVendorExportMutation,
  useOverViewAttributesAddMutation,
  useOverViewAttributesListMutation,
  useLazyOverViewAttributesEditQuery,
  useOverViewAttributesUpdateMutation,
  useLazyOverViewAttributesStatusQuery,
  useLazyHsnNoPageListQuery,
  useBoxCreateMutation,
  useBoxListMutation,
  useBoxUpdateMutation,
  useLazyBoxNoPageListQuery,
  useLazyBoxStatusQuery,
  useLazyBoxViewQuery,
  useShippingChargeCreateMutation,
  useShippingChargeListMutation,
  useShippingChargeUpdateMutation,
  useLazyShippingChargeNoPageListQuery,
  useLazyShippingChargeStatusQuery,
  useLazyShippingChargeViewQuery,
  useVendorScheduleListMutation,
  useVendorScheduleStoreMutation,
  useStatusChangeProductRequestMutation,
  useCustomerRejectProRequestMutation,
  useProjectListMutation,
  useLazyProjectViewQuery,
  useProjectUpdateMutation,
  usePresentationAddMutation,
  usePresentationStatusMutation,
  useLazyPresentationDeleteQuery,
  usePresentationProAddMutation,
  useLazyPresentationProViewQuery,
  usePresentationProCommentMutation,
  usePresentationArtworkStatusMutation,
  usePresentationProPricingMutation,
  useArtworkStoreMutation,
  useLazyArtworkDeleteQuery,
  useAddProductInfoMutation,
  useProjectStatusChangeMutation,
  useProjectMarginRequestMutation,
  useProjectMarginUpdateMutation,
  // PRODUCT TASK
  useProducttasklistListMutation,
  useProducttaskaddMutation,
  useLazyProjectsListQuery,
  useDeparmentListMutation,
  useLazyProducttaskViewQuery,
  useProducttaskUpdateMutation,
  useProductStatusMutation,
  // commands
  useCommandsaddMutation,

  // activity
  useActivityAddMutation,
  useActivityListMutation,

  // files
  useFileAddMutation,
  useFileListMutation,
  useFilesUpdateMutation,
  useFileViewMutation,
  useFileDeleteMutation,
  useFileDownloadMutation,
  useFilesClientMutation,
  // file comments
  useFilecommentAddMutation,
  useFileclientcommandAddMutation,
  //pin
  useActivityPinMutation,
  useEstimateAddMutation,
  useEstimateEditMutation,
  useEstimateUpdateMutation,
  useEstimateSizeMutation,
  useEstimateQtyAddMutation,
  useLazyEstimateDetailQuery,
  useEstimateStatusMutation,
  useEstimateTotalUpdateMutation,
  useProjectAddressAddMutation,
  useProjectAddressEditMutation,
  useProjectAddressListMutation,
  useLazyProjectAddressDeleteQuery,
  useLazyProjectAddressNoPageListQuery,
  useLazyProjectAddressViewQuery,
  useLazyEstimateTaxQuery,
  useGetShippingBoxQtyMutation,
  useInventryPriceMutation,
  useShippingPriceMutation,
  useStoreProjectBoxMutation,
} = api;
