import React, { useEffect, useState } from "react";
import CachedIcon from "@mui/icons-material/Cached";
import { Pages, Search } from "@mui/icons-material";
import ProjectDetailPopup from "../Popup/ProjectDetailPopup";
import AddTaskPopup from "../Popup/AddTaskPopup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { salesForce } from "../../redux/api/DummyJson";
import { Link, useNavigate } from "react-router-dom";
import ProjectDetailPopup2 from "../Popup/ProjectDetailPopup2";
import ProductSearchHeader from "../ProductComp/ProductSearchHeader";
import { searchIcon, viewIcon, editIcon } from "../../assets/img";
import moment from "moment/moment";
import { useProducttasklistListMutation } from "../../redux/api/api";

const TaskAssignby = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("sales");
  const [showDetailPopup, setShowDetailPopup] = useState(null);
  const [showAddTask, setShowAddTask] = useState(false);
  const [list, setList] = useState([]);
  const [row, setRow] = useState(10);
  const [search, setSearch] = useState("");
  const [order, setOrder] = useState();
  const [types, setType] = useState(1);
  const [status, setStatus] = useState("");
  const [task_date, setTask_date] = useState("");
  const [task_deadline_date, setTask_deadline_date] = useState("");
  const [completion_date, setCompletion_date] = useState("");
  const [ProducttaskListApi] = useProducttasklistListMutation();
  const [page, setPage] = useState("1");
  const [pagination, setPagination] = useState("");
  const onTabClick = (tab) => {
    setActiveTab(tab);
  };

  const closeDetailPopup = () => {
    setShowDetailPopup(null);
  };

  const onClickAddTask = () => {
    setShowAddTask(!showAddTask);
  };
  //PRODUCT TASK LIST
  const getProducttaskList = (
    searchs,
    rows,
    orders,
    task_dates,
    task_deadline_dates,
    completion_dates,
    typess,
    status
  ) => {
    let formdata = new FormData();
    let params = `?page=${page}`;

    if (rows) {
      formdata.append("rows", rows);
    }

    if (searchs) {
      formdata.append("search", searchs);
    }

    if (orders) {
      formdata.append("order", orders);
    }

    if (status) {
      formdata.append("status", status);
    }

    if (task_dates) {
      formdata.append("task_date", task_dates);
    }
    if (task_deadline_dates) {
      formdata.append("task_deadline_date", task_deadline_dates);
    }
    if (completion_dates) {
      formdata.append("completion_date", completion_dates);
    }

    setSearch(searchs);
    setRow(rows);
    setOrder(orders);
    setType(typess);
    setTask_date(task_dates);
    setCompletion_date(completion_dates);
    setTask_deadline_date(task_deadline_dates);

    setStatus(status);
    formdata.append("type", 1);
    ProducttaskListApi({ payload: formdata, params: params })
      .unwrap()
      .then((res) => {
        setList(res?.lists);
        setPagination(res?.pagination_meta);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {
    if (row) {
      getProducttaskList();
    }
  }, [row]);
  return (
    <div className="mt-4 ">
      <div className="d-flex ac-jb flex-column flex-md-row">
        <div className=" d-flex ac-jc place-holde justify-content-md-start gap-3 flex-wrap w-100 w-md-90 mb-md-0 mb-4">
          <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary me-3">
            Task Date
          </p>
          <input
            className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 pointer"
            placeholder="task date"
            type="date"
            onChange={(e) =>
              getProducttaskList(
                search,
                row,
                order,
                e.target.value,
                task_deadline_date,
                completion_date,
                types,
                status
              )
            }
            value={task_date}
          />
          <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary me-3">
            Task Deadline
          </p>
          <input
            className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 pointer "
            placeholder="task deadline date"
            type="date"
            onChange={(e) =>
              getProducttaskList(
                search,
                row,
                order,
                task_date,
                e.target.value,
                completion_date,
                types,
                status
              )
            }
            value={task_deadline_date}
          />
          <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary me-3">
            Completed Date
          </p>
          <input
            className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 pointer "
            placeholder="completion date"
            type="date"
            onChange={(e) =>
              getProducttaskList(
                search,
                row,
                order,
                task_date,
                task_deadline_date,
                e.target.value,
                types,
                status
              )
            }
            value={completion_date}
          />
          <button className="w-20 get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4">
            <select
              className="bg-transparent border-0 py-3 primary1"
              onChange={(e) =>
                getProducttaskList(
                  search,
                  row,
                  order,
                  task_date,
                  task_deadline_date,
                  completion_date,
                  types,
                  e.target.value
                )
              }
              value={status}
            >
              <option disabled selected value="">
                Status
              </option>
              <option value={1}>Yet to start</option>
              <option value={2}>In Progress</option>
              <option value={3}>Completed</option>
            </select>
          </button>
        </div>
        <div className="d-flex ac-jc gap-3">
          <div className="d-flex mt-sm-3 border-search">
          <img src={searchIcon} alt="img" className="searchiConImg mt-1" />
            <input
              className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
              placeholder="Search"
              onChange={(e)=>
                getProducttaskList(
                  e.target.value,
                  row,
                  order,
                  task_date,
                  task_deadline_date,
                  completion_date,
                  types,
                  status
                )
               }
               value={search}
            />
          </div>

          <button
            className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded"
            onClick={() => getProducttaskList("", 10, "", "", "", "", 1, "")}
          >
            <CachedIcon />
          </button>
        </div>
      </div>

      <div className="w-95 ac-jb d-flex flex-md-row flex-column mt-4">
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
            <option value="10">
              {pagination?.total ? pagination?.total : 0}
            </option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
        <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont me-3">
          Total Count : {pagination?.total ? pagination?.total : 0}
        </p>
      </div>

      <div className="mt-5" style={{ width: "100%", overflow: "auto" }}>
        <table className="table-design-new" style={{ width: "100%" }}>
          <thead>
            <tr className="border-bottom w-100 text-nowrap text-center">
              <th>
                <p className="f2 fs-14 text-center">S.No</p>
              </th>
              <th>
                <p className="f2 fs-14 text-center">
                  Task Date
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>
              <th>
                <p className="f2 fs-14 text-center">
                  Task Deadline
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>

              <th>
                <p className="f2 fs-14 text-center">
                  Project Associated
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>
              <th>
                {" "}
                <p className="f2 fs-14 text-center">
                  Assigned By
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>
              <th>
                {" "}
                <p className="f2 fs-14 text-center">
                  User
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>
              <th>
                {" "}
                <p className="f2 fs-14 text-center">
                  Status
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>
              <th>
                {" "}
                <p className="f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-js">
                  Options
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            {list?.map((item, index) => {
              return (
                <tr className="text-center" key={index}>
                  <td>
                    <p className="pointerView f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                      {index + 1}
                    </p>
                  </td>
                  <td>
                    <p
                      className="f2 fs-14 text-center primary1"
                      // onClick={() => setShowDetailPopup(item)}
                    >
                      {item?.task_date}
                    </p>
                  </td>
                  <td>
                    <p
                      className="f2 fs-14 text-center primary1"
                      // onClick={() => setShowDetailPopup(item)}
                    >
                      {item?.task_deadline_date}
                    </p>
                  </td>
                  <td>
                    <div className="d-felx fs-14">
                      <Link
                        className="f3 primary1"
                        to={"/overview"}
                        target="_blank"
                      >
                        {item?.project?.project_code}
                      </Link>
                    </div>
                  </td>
                  <td>
                    <p className="f2">{item?.assignedBy?.first_name}</p>
                  </td>

                  <td>
                    <p className=" f2 ">{item?.assignedTo?.first_name}</p>
                  </td>
                  <td>
                    <p
                      className={`${
                        item?.status === 1
                          ? "text-primary"
                          : item?.status === 2
                          ? "text-warning"
                          : item?.status === 3
                          ? "text-success"
                          : ""
                      } f3 text-center`}
                      // onClick={() => setShowDetailPopup(item)}
                    >
                      {item?.status === 1
                        ? "Yet to start"
                        : item?.status === 2
                        ? "In Progress"
                        : item?.status === 3
                        ? "Completed"
                        : ""}
                    </p>
                  </td>
                  <td>
                    <div className="d-flex ac-jc primary1">
                      <div
                        onClick={() =>
                          navigate("/projects-task-view", {
                            state: { type: "view", data: item, assign_type: 1 },
                          })
                        }
                        className="viewBoxDes p-2 mb-2 bg-white rounded-2"
                      >
                        <img src={viewIcon} className="viewDes" />
                      </div>
                      {item?.status === 1 && (
                        <div
                          onClick={() =>
                            navigate("/projects-task", {
                              state: {
                                type: "edit",
                                data: item,
                                assign_type: 1,
                              },
                            })
                          }
                          className="viewBoxDes ms-2 p-2 mb-2 bg-white rounded-2"
                        >
                          <img src={editIcon} className="viewDes" />
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {showDetailPopup && (
        <ProjectDetailPopup2
          item={showDetailPopup}
          toggleShowPopup={closeDetailPopup}
          onClickAddTask={onClickAddTask}
        />
      )}
      {showAddTask && <AddTaskPopup toggleShowPopup={onClickAddTask} />}
      <div className="d-flex justify-content-between mt-3">
        <button
          className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
            page == 1 && "opacity-50"
          }`}
          onClick={() => setPage(page - 1)}
          disabled={page == 1 ? true : false}
        >
          Previous
        </button>
        <button
          className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
            page == pagination?.last_page && "opacity-50"
          }`}
          onClick={() => setPage(page + 1)}
          disabled={page == pagination?.last_page ? true : false}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default TaskAssignby;
