import React, { useState } from "react";
import { noLogo, product, viewIcon } from "../../assets/img";
import { collaborateList } from "../../redux/api/DummyJson";
import { useNavigate, useSearchParams } from "react-router-dom";
import DeletePopup from "../Popup/DeletePopup";
import AddArtWorker from "../Projects/AddArtWorker";
import AddFilesPopup from "../Popup/AddFilesOverview";
import { Close } from "@mui/icons-material";
import {
  useArtworkStoreMutation,
  useEstimateUpdateMutation,
  usePresentationArtworkStatusMutation,
} from "../../redux/api/api";
import { useDispatch } from "react-redux";
import { saveLoader } from "../../redux/slice/loaderNoti";
import { toast } from "react-hot-toast";
import AddBoxIcon from "@mui/icons-material/AddBox";

const TableComptwo = ({
  deleteToggle,
  setAddTitle,
  projectDetail,
  setProjectDetail,
  colSpan,
  setColspan,
  getProjectView,
  setShowPopup,
  artworkDelete,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [addClient, setAddClient] = useState(false);
  const [addArt, setAddArt] = useState(false);
  const [AddFilesOverview, setAddFilesOverview] = useState(false);

  // RTK QUERY
  const [artworkStoreApi] = useArtworkStoreMutation();
  const [artworkStatusApi] = usePresentationArtworkStatusMutation();
  const [artworkUpdateApi] = useEstimateUpdateMutation();

  const [addShow, setAddShow] = useState(false);
  const [deletes, setDeletes] = useState(false);
  const [actDect, setActDect] = useState(false);
  const [viewStock, setViewStock] = useState(false);
  const [btn, setBtn] = useState(false);
  const [hide, setHide] = useState(0);
  const [status, setStatus] = useState(0);
  const [status2, setStatus2] = useState(0);
  const [artworkData, setArtWorkData] = useState({});
  const [artworkDatail, setArtWorkDatail] = useState({});
  const [design_status, setdesign_status] = useState("");
  const [sale_status, setsale_status] = useState("");

  const [decorative_methodList, setdecorative_methodList] = useState([]);
  const [decorativeMethodList, setDecorativeMethodList] = useState([]);
  const [decorative_method_id, setdecorative_method_id] = useState("");
  const [decorative_method_name, setdecorative_method_name] = useState("");
  const [proof_required, setproof_required] = useState("");
  const [logo_name, setlogo_name] = useState("");
  const [uom, setuom] = useState(0);
  const [logo_length, setlogo_length] = useState("");
  const [logo_breadth, setlogo_breadth] = useState("");
  const [logo_color, setlogo_color] = useState("");
  const [repeat_logo, setrepeat_logo] = useState("");
  const [supplier_note, setsupplier_note] = useState("");
  const [location, setlocation] = useState("");
  const [file, setfile] = useState("");

  const [decorative_method_idErr, setdecorative_method_idErr] = useState(false);
  const [proof_requiredErr, setproof_requiredErr] = useState(false);
  const [logo_nameErr, setlogo_nameErr] = useState(false);
  const [uomErr, setuomErr] = useState(false);
  const [logo_lengthErr, setlogo_lengthErr] = useState(false);
  const [logo_breadthErr, setlogo_breadthErr] = useState(false);
  const [logo_colorErr, setlogo_colorErr] = useState(false);
  const [repeat_logoErr, setrepeat_logoErr] = useState(false);
  const [supplier_noteErr, setsupplier_noteErr] = useState(false);
  const [locationErr, setlocationErr] = useState(false);
  const [fileErr, setfileErr] = useState(false);

  const toggleShowPopup = () => {
    setAddClient(!addClient);
  };
  const artWork = () => {
    toggleShowPopup();
  };
  const toggleShowPopups = () => {
    setAddFilesOverview(!AddFilesOverview);
  };

  const deleteToggles = (value) => {
    setActDect(!actDect);
    if (value == 2) {
      artworkDelete(status2);
    }
  };

  const deletePopupOpenArt = (id) => {
    setStatus2(id);
    setActDect(!actDect);
  };

  const submitHandleArt = () => {
    if (
      decorative_method_id?.length == 0 ||
      proof_required?.length == 0 ||
      logo_name?.length == 0 ||
      uom == 0 ||
      logo_length?.length == 0 ||
      logo_breadth?.length == 0 ||
      logo_color?.length == 0 ||
      repeat_logo?.length == 0 ||
      supplier_note?.length == 0 ||
      location?.length == 0 ||
      file?.length == 0
    ) {
      setdecorative_method_idErr(true);
      setproof_requiredErr(true);
      setlogo_nameErr(true);
      setuomErr(true);
      setlogo_lengthErr(true);
      setlogo_breadthErr(true);
      setlogo_colorErr(true);
      setrepeat_logoErr(true);
      setsupplier_noteErr(true);
      setlocationErr(true);
      setfileErr(true);
    } else {
      let formdata = new FormData();
      let project_code = searchParams?.get("project");
      if (project_code) {
        let split = project_code.split("_");
        formdata.append("project_id", split[1]);
        formdata.append("product_id", artworkData?.product?.id);
        formdata.append("project_detail_id", artworkData?.id);
        formdata.append("product_sku_id", artworkData?.product_sku?.id);
        formdata.append("decorative_method_id", decorative_method_id);
        formdata.append("proof_required", proof_required);
        formdata.append("logo_name", logo_name);
        formdata.append("uom", uom);
        formdata.append("logo_length", logo_length);
        formdata.append("logo_breadth", logo_breadth);
        formdata.append("logo_color", logo_color);
        formdata.append("repeat_logo", repeat_logo);
        formdata.append("supplier_note", supplier_note);
        formdata.append("location", location);

        if (file?.name) {
          formdata.append("file", file);
        }

        dispatch(saveLoader(true));
        setBtn(true);
        if (artworkDatail?.id) {
          artworkUpdateApi({ payload: formdata, id: artworkDatail?.id })
            .unwrap()
            .then((res) => {
              dispatch(saveLoader(false));
              setBtn(false);
              getProjectView();
              toggleShowClosePopup();
            })
            .catch((err) => {
              dispatch(saveLoader(false));
              setBtn(false);
              console.log("err", err);
            });
        } else {
          artworkStoreApi(formdata)
            .unwrap()
            .then((res) => {
              dispatch(saveLoader(false));
              setBtn(false);
              getProjectView();
              toggleShowClosePopup();
            })
            .catch((err) => {
              dispatch(saveLoader(false));
              setBtn(false);
              console.log("err", err);
            });
        }
      }
    }
  };

  const handleStatusArt = (status, type) => {
    dispatch(saveLoader(true));
    let formdata = new FormData();

    formdata.append("artwork_id", artworkDatail?.id);

    if (type == 1) {
      formdata.append("sale_status", status);
      setsale_status(status);
    }

    if (type == 2) {
      formdata.append("design_status", status);
      setdesign_status(status);
    }

    artworkStatusApi(formdata)
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        dispatch(saveLoader(false));
        getProjectView();
        if (type == 1) {
          if (status == 2 || status == 3) {
            setAddClient(false);
          }
        }
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        console.log("err", err);
      });
  };

  const artWorkHandler = (art, item, index, aind) => {
    if (art?.decorative_method_id?.id) {
      setdecorative_method_id(art?.decorative_method_id?.id);
      setdecorative_method_name(art?.decorative_method_id?.name);
    }
    if (art?.proof_required) {
      setproof_required(art?.proof_required);
    }

    if (art?.logo_name) {
      setlogo_name(art?.logo_name);
    }

    if (art?.uom) {
      setuom(+art?.uom);
    }

    if (art?.logo_length) {
      setlogo_length(art?.logo_length);
    }

    if (art?.logo_breadth) {
      setlogo_breadth(art?.logo_breadth);
    }

    if (art?.logo_color) {
      setlogo_color(art?.logo_color);
    }

    if (art?.repeat_logo) {
      setrepeat_logo(art?.repeat_logo);
    }

    if (art?.supplier_note) {
      setsupplier_note(art?.supplier_note);
    }

    if (art?.location) {
      setlocation(art?.location);
    } else {
      setlocation(aind + 1);
    }

    if (art?.image_url) {
      setfile(art?.image_url);
    }

    if (art?.design_status) {
      setdesign_status(art?.design_status);
    }

    if (art?.sale_status) {
      setsale_status(art?.sale_status);
    }

    if (art?.id) {
      setArtWorkDatail(art);
    }

    setdecorative_methodList(item?.product?.productDecorations);
    setDecorativeMethodList(item?.product?.productDecorations);
    setArtWorkData(item);
    setAddClient(true);
  };

  const toggleShowClosePopup = () => {
    setdecorative_method_id("");
    setdecorative_method_name("");
    setproof_required("");
    setlogo_name("");
    setuom("");
    setlogo_length("");
    setlogo_breadth("");
    setlogo_color("");
    setrepeat_logo("");
    setsupplier_note("");
    setlocation("");
    setfile("");
    setdecorative_method_idErr(false);
    setproof_requiredErr(false);
    setlogo_nameErr(false);
    setuomErr(false);
    setlogo_lengthErr(false);
    setlogo_breadthErr(false);
    setlogo_colorErr(false);
    setrepeat_logoErr(false);
    setsupplier_noteErr(false);
    setlocationErr(false);
    setfileErr(false);
    setAddClient(false);
    setArtWorkData({});
    setArtWorkDatail({});
    setdesign_status("");
    setsale_status("");
  };

  const handleArtworkAdder = (index) => {
    let temp = [...projectDetail];
    let temx = [...temp[index]["projectArtwork"]];
    let arr = [];
    temx.push(1);

    temp[index] = {
      ...temp[index],
      projectArtwork: temx,
    };

    temp?.map((item, ind) => {
      arr.push(item?.projectArtwork?.length);
    });

    let maxValue = Math.max(...arr);
    setColspan(maxValue);
    setProjectDetail(temp);
  };

  return (
    <div className="presentaion_pages overflow-scroll mt-4">
      {addClient && (
        <AddArtWorker
          toggleShowPopup={toggleShowClosePopup}
          setAddArt={setAddArt}
          toggleShowPopups={toggleShowPopups}
          statusOption={true}
          decorative_method_id={decorative_method_id}
          setdecorative_method_id={setdecorative_method_id}
          decorative_method_name={decorative_method_name}
          setdecorative_method_name={setdecorative_method_name}
          proof_required={proof_required}
          setproof_required={setproof_required}
          logo_name={logo_name}
          setlogo_name={setlogo_name}
          uom={uom}
          setuom={setuom}
          logo_length={logo_length}
          setlogo_length={setlogo_length}
          logo_breadth={logo_breadth}
          setlogo_breadth={setlogo_breadth}
          logo_color={logo_color}
          setlogo_color={setlogo_color}
          repeat_logo={repeat_logo}
          setrepeat_logo={setrepeat_logo}
          supplier_note={supplier_note}
          setsupplier_note={setsupplier_note}
          location={location}
          setlocation={setlocation}
          file={file}
          setfile={setfile}
          decorative_method_idErr={decorative_method_idErr}
          proof_requiredErr={proof_requiredErr}
          logo_nameErr={logo_nameErr}
          uomErr={uomErr}
          logo_lengthErr={logo_lengthErr}
          logo_breadthErr={logo_breadthErr}
          logo_colorErr={logo_colorErr}
          repeat_logoErr={repeat_logoErr}
          supplier_noteErr={supplier_noteErr}
          locationErr={locationErr}
          fileErr={fileErr}
          decorative_methodList={decorative_methodList}
          decorativeMethodList={decorativeMethodList}
          setDecorativeMethodList={setDecorativeMethodList}
          btn={btn}
          submitHandleArt={submitHandleArt}
          artworkDatail={artworkDatail}
          design_status={design_status}
          sale_status={sale_status}
          handleStatusArt={handleStatusArt}
        />
      )}
      {AddFilesOverview && (
        <AddFilesPopup
          toggleShowPopup={toggleShowPopups}
          popupType={"addClient"}
        />
      )}
      <div className="w-100 overflow-scroll table_border menu-table">
        <table>
          <thead>
            <tr className="bg-soft-gray1">
              <th
                style={{
                  textAlign: "left",
                  width: "0%",
                  padding: "15px 0px 15px 0px",
                }}
              >
                <div className="dropdown mx-2">
                  <button
                    className="dropdown-toggle cust-btn addbtn  ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    onClick={() =>
                      navigate(
                        `/product-library-select?project=${searchParams?.get(
                          "project"
                        )}`,
                        { state: { back: "estimate" } }
                      )
                    }
                  >
                    + Add
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <span
                        className="dropdown-item f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        onClick={() => navigate("/product-library")}
                      >
                        Product
                      </span>
                    </li>
                    <li>
                      <span
                        className="dropdown-item f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        onClick={() => setAddTitle(true)}
                      >
                        Tittle
                      </span>
                    </li>
                  </ul>
                </div>
              </th>
              <th
                className="f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center"
                colSpan={colSpan + 1}
              >
                Artwork
              </th>
            </tr>
          </thead>
          <tbody className="overflow-scroll">
            {projectDetail?.map((item, ind) => {
              return (
                <tr
                  className={`position-relative color-for-hecd bor_t value_tr ${
                    item?.estimate_status == 4
                      ? "opacity-50 pe-none"
                      : item?.estimate_status == 5
                      ? "opacity-50 pe-none"
                      : ""
                  }`}
                  key={ind}
                >
                  <td className="text-start ps-2">
                    <div className="mx-2" style={{ width: "500px" }}>
                      <div className="d-flex ac-js">
                        <img
                          src={
                            item?.product?.defaultImage
                              ? item?.product?.defaultImage
                              : noLogo
                          }
                          className="object-fit-contain img_size2"
                        />
                        <p className="text-start f1 text-dark fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                          {item?.product?.description}
                        </p>
                      </div>
                    </div>
                  </td>
                  {item?.projectArtwork?.map((art, aind) => {
                    return (
                      <td key={aind}>
                        <div className="d-inline">
                          <label className="f3 mt-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary d-flex">
                            Location {aind + 1}:
                            {art?.id && (
                              <div
                                className="mx-2 d-flex ac-jc position-relative hide_res2"
                                style={{ width: "20px" }}
                                onClick={() => {
                                  deletePopupOpenArt(art?.id);
                                }}
                              >
                                <Close className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center gray" />
                              </div>
                            )}
                          </label>
                          <div className="" style={{ width: "300px" }}>
                            <div
                              className="artwork_images d-flex ac-jc"
                              role={"button"}
                              onClick={() => {
                                artWorkHandler(art, item, ind, aind);
                              }}
                            >
                              {art?.id ? (
                                <div className="w-100">
                                  <img
                                    src={
                                      art?.image_url ? art?.image_url : noLogo
                                    }
                                    className="w-100"
                                  />
                                </div>
                              ) : (
                                <p className="f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                  {" "}
                                  Add Artwork
                                </p>
                              )}
                            </div>
                            {art?.id && (
                              <div className="mx-1 mt-2 mb-2">
                                <button
                                  // onClick={() =>
                                  //   navigate("/upload-artwork-inprocess")
                                  // }
                                  className="text-nowrap px-2 artwor_btn"
                                >
                                  {art?.design_status == 2 &&
                                  art?.sale_status == 2
                                    ? "Sales Approved"
                                    : art?.design_status == 1
                                    ? "Design Inprogress"
                                    : art?.design_status == 2
                                    ? "Design Completed"
                                    : "Art Work Progress"}
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </td>
                    );
                  })}
                  <td>
                    <div className="d-inline">
                      <label className="f3 mt-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary">
                        &nbsp;
                      </label>
                      <div
                        className="artwork_images d-flex ac-jc"
                        role={"button"}
                        onClick={() => handleArtworkAdder(ind)}
                      >
                        <p className="f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 w-100">
                          <AddBoxIcon />
                        </p>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {actDect && (
        <DeletePopup deleteToggle={deleteToggles} setAddTitle={setAddTitle} />
      )}
    </div>
  );
};

export default TableComptwo;
