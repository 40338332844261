import React, { useEffect, useState } from "react";
import {
  editIcon,
  noLogo,
  searchIcon,
  toggleOff,
  toggleOn,
  viewIcon,
} from "../assets/img";
import { attributeList, decorativeMethodList } from "../redux/api/DummyJson";
import { Search } from "@mui/icons-material";
import CachedIcon from "@mui/icons-material/Cached";
import AddAttributesPopup from "../components/Popup/AddAttributesPopup";
import ImageCroper from "../components/Popup/ImageCroper";
import GetAppIcon from "@mui/icons-material/GetApp";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ErrorIcon from "@mui/icons-material/Error";
import {
  useAdminExportsMutation,
  useAttributeCreateMutation,
  useAttributeEditMutation,
  useAttributeListsMutation,
  useLazyAttributeListQuery,
  useLazyAttributeStatusQuery,
  useLazyAttributeViewQuery,
  useLazyBoxNoPageListQuery,
  useLazyMasterNoPageListQuery,
  useLazyShippingChargeNoPageListQuery,
  useLazyShippingChargeStatusQuery,
  useLazyShippingChargeViewQuery,
  useShippingChargeCreateMutation,
  useShippingChargeListMutation,
  useShippingChargeUpdateMutation,
} from "../redux/api/api";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";

const ChargeShippingPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [popupType, setPopupType] = useState("");
  const [croper, setCroper] = useState(false);
  const [croperImage, setCropImage] = useState();
  const [list, setList] = useState([]);
  const [listMaster, setListMaster] = useState({});
  const [listBox, setListBox] = useState([]);
  const [listsBox, setListsBox] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [pageRow, setPageRow] = useState(10);
  const [page, setPage] = useState(1);
  const [pagenation, setPagination] = useState({});
  const [btn, setBtn] = useState(false);
  const [update, setUpdate] = useState({});

  const [box_id, setbox_id] = useState("");
  const [shipping_location_id, setshipping_location_id] = useState("");
  const [shipping_type_id, setshipping_type_id] = useState("");
  const [price, setprice] = useState("");
  const [type, setType] = useState("");
  const [locationArr, setLocationArr] = useState([]);
  const [box_idErr, setbox_idErr] = useState(false);
  const [shipping_location_idErr, setshipping_location_idErr] = useState(false);
  const [shipping_type_idErr, setshipping_type_idErr] = useState(false);
  const [priceErr, setpriceErr] = useState(false);
  const [nameErr, setNameErr] = useState(false);
  const [box_type, setbox_type] = useState("");
  const [box_typeErr, setbox_typeErr] = useState(false);

  //  RTK QUERY
  const [listMasterApi] = useLazyMasterNoPageListQuery();
  const [listBoxApi] = useLazyBoxNoPageListQuery();
  const [listShippingChargeApi] = useLazyShippingChargeNoPageListQuery();
  const [listsShippingChargeApi] = useShippingChargeListMutation();
  const [viewShippingChargeApi] = useLazyShippingChargeViewQuery();
  const [createShippingChargeApi] = useShippingChargeCreateMutation();
  const [editShippingChargeApi] = useShippingChargeUpdateMutation();
  const [statusShippingChargeApi] = useLazyShippingChargeStatusQuery();
  const [exportsApi] = useAdminExportsMutation();

  const toggleShowPopup = (type) => {
    setShowPopup(!showPopup);
    setbox_id("");
    setshipping_location_id("");
    setshipping_type_id("");
    setprice("");
    setbox_type("");
    setbox_typeErr(false);
    setbox_idErr(false);
    setshipping_location_idErr(false);
    setshipping_type_idErr(false);
    setpriceErr(false);
    setListBox([]);
    setUpdate({});
    if (type) setPopupType(type);
  };

  const getMasters = () => {
    listMasterApi()
      .unwrap()
      .then((res) => {
        setListMaster(res);
      })
      .catch((err) => {
        console.log("err", err);
      });

    listBoxApi()
      .unwrap()
      .then((res) => {
        setListsBox(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getMasters();
  }, []);

  const getList = (event, row) => {
    let formdata = new FormData();
    let params = `?page=${page}`;

    setSearchValue(event);

    if (event?.length > 0) {
      formdata.append("search", event);
    }

    if (row) {
      setPageRow(row);
      formdata.append("rows", row);
    }

    listsShippingChargeApi({ payload: formdata, params: params })
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setList(res?.lists);
          setPagination(res?.pagination_meta);
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  const change = (id) => {
    statusShippingChargeApi(id)
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          toast.success(res?.data?.message || res?.message);
          getList();
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  useEffect(() => {
    getList();
  }, [page]);

  const submitHandle = () => {
    if (
      box_id?.length == 0 ||
      shipping_location_id?.length == 0 ||
      shipping_type_id?.length == 0 ||
      price?.length == 0 ||
      box_type?.length == 0
    ) {
      setbox_idErr(true);
      setshipping_location_idErr(true);
      setshipping_type_idErr(true);
      setpriceErr(true);
      setbox_typeErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("box_id", box_id);
      formdata.append("shipping_location_id", shipping_location_id);
      formdata.append("shipping_type_id", shipping_type_id);
      formdata.append("price", price);
      formdata.append("type", box_type);

      setBtn(true);
      dispatch(saveLoader(true));
      if (update?.id) {
        editShippingChargeApi({ payload: formdata, id: update?.id })
          .unwrap()
          .then((res) => {
            setBtn(false);
            dispatch(saveLoader(false));
            toggleShowPopup();
            setUpdate({});
            getList();
          })
          .catch((err) => {
            setBtn(false);
            dispatch(saveLoader(false));
            console.log("err", err);
          });
      } else {
        createShippingChargeApi(formdata)
          .unwrap()
          .then((res) => {
            setBtn(false);
            dispatch(saveLoader(false));
            toggleShowPopup();
            setUpdate({});
            getList();
          })
          .catch((err) => {
            setBtn(false);
            dispatch(saveLoader(false));
            console.log("err", err);
          });
      }
    }
  };

  const getAttribute = (item) => {
    toggleShowPopup("edit");
    setprice(item?.price);
    setshipping_location_id(item?.shipping_location?.id);
    setshipping_type_id(item?.shipping_type?.id);
    setbox_id(item?.box?.id);
    setbox_type(item?.type);
    if (item?.type) {
      let filters = listsBox?.filter((i) => i?.type == item?.type);
      setListBox(filters);
    }
    setUpdate(item);
  };

  // EXPORT EXCEL
  const getAdminExports = () => {
    let formdata = new FormData();
    formdata.append("type", 24);
    exportsApi(formdata)
      .unwrap()
      .then((res) => {
        if (res?.download_link) {
          const link = document.createElement("a");
          link.href = res?.download_link;
          link.setAttribute("download", "");
          link.setAttribute("target", "_blank");
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleType = (value) => {
    let temp = [
      {
        id: 1,
        value: "Metro",
      },
      {
        id: 2,
        value: "National",
      },
      {
        id: 3,
        value: "North East",
      },
    ];

    let temp1 = [
      {
        id: 4,
        value: "Local",
      },
      {
        id: 5,
        value: "Regional",
      },
      {
        id: 6,
        value: "Zonal",
      },
    ];

    if (value == 2) {
      let final = temp1.concat(temp);
      setLocationArr(final);
    } else {
      setLocationArr(temp);
    }
    setshipping_type_id(value);
  };

  const handlebox_type = (event) => {
    let filters = listsBox?.filter((i) => i?.type == event);
    setListBox(filters);
    setbox_type(event);
    setbox_id("");
  };

  return (
    <>
      {showPopup && (
        <div className="add-category-popup">
          <div style={{ width: "70%" }}>
            <p className="d-flex ac-jb mt-2 f4 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
              {popupType === "edit" ? "Edit" : "Add"} Shipping Charge
              <span>
                <button onClick={toggleShowPopup} className="cust-btn">
                  <HighlightOffIcon />
                </button>
              </span>
            </p>

            <div className="d-flex ac-jc add-content">
              <div className="w-100 d-flex ac-jb mt-4 flex-wrap">
                <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
                  Type<span>*</span>
                </p>
                <div className="w-100 d-flex w-xs-100 mt-2 position-relative">
                  <div className="w-100  w-xs-100 mt-xs-5 position-relative">
                    <select
                      className="w-80 cust-btn searchFill w-xs-100 ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                      onChange={(e) => setshipping_type_id(e.target.value)}
                      value={shipping_type_id}
                    >
                      <option hidden>Select</option>
                      {listMaster?.shipping_types?.map((item, ind) => {
                        return (
                          <option value={item?.id} key={ind}>
                            {item?.name}
                          </option>
                        );
                      })}
                    </select>
                    {shipping_type_id?.length == 0 && shipping_type_idErr && (
                      <div className="d-flex gap-1 position-absolute">
                        <ErrorIcon className="svg_log" />
                        <p className="err-input_log mt-1">Select Type</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-100 d-flex ac-jb mt-4 flex-wrap">
                <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
                  Location<span>*</span>
                </p>
                <div className="w-100 d-flex w-xs-100 mt-2 position-relative">
                  <div className="w-100  w-xs-100 mt-xs-5 position-relative">
                    <select
                      className="w-80 cust-btn searchFill w-xs-100 ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                      onChange={(e) => setshipping_location_id(e.target.value)}
                      value={shipping_location_id}
                    >
                      <option hidden>Select</option>
                      {listMaster?.shipping_locations?.map((item, ind) => {
                        return (
                          <option value={item?.id} key={ind}>
                            {item?.name}
                          </option>
                        );
                      })}
                    </select>
                    {shipping_location_id?.length == 0 &&
                      shipping_location_idErr && (
                        <div className="d-flex gap-1 position-absolute">
                          <ErrorIcon className="svg_log" />
                          <p className="err-input_log mt-1">Select Location</p>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex ac-jc add-content">
              <div className="w-100 d-flex ac-jb mt-4 flex-wrap">
                <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
                  Box Type<span>*</span>
                </p>
                <div className="w-100 d-flex w-xs-100 mt-2 position-relative">
                  <div className="w-100  w-xs-100 mt-xs-5 position-relative">
                    <select
                      className="w-80 cust-btn searchFill w-xs-100 ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                      onChange={(e) => handlebox_type(e.target.value)}
                      value={box_type}
                    >
                      <option hidden>Select</option>
                      <option value={1}>Individual</option>
                      <option value={2}>Bulk</option>
                    </select>
                    {box_type?.length == 0 && box_typeErr && (
                      <div className="d-flex gap-1  position-absolute">
                        <ErrorIcon className="svg_log" />
                        <p className="err-input_log mt-1">Select Box Type</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-100 d-flex ac-jb mt-4 flex-wrap">
                <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
                  Box<span>*</span>
                </p>
                <div className="w-100 d-flex w-xs-100 mt-2 position-relative">
                  <div className="w-100  w-xs-100 mt-xs-5 position-relative">
                    <select
                      className="w-80 cust-btn searchFill w-xs-100 ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                      onChange={(e) => setbox_id(e.target.value)}
                      value={box_id}
                    >
                      <option hidden>Select</option>
                      {listBox?.map((item, ind) => {
                        return (
                          <option value={item?.id} key={ind}>
                            {item?.name}-{item?.model}-{item?.dimension}
                          </option>
                        );
                      })}
                    </select>
                    {box_id?.length == 0 && box_idErr && (
                      <div className="d-flex gap-1  position-absolute">
                        <ErrorIcon className="svg_log" />
                        <p className="err-input_log mt-1">Select Box</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex ac-js add-content">
              <div className="w-50 d-flex ac-jb mt-4 flex-wrap">
                <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
                  Price to Customer<span>*</span>
                </p>
                <div className="w-100 d-flex w-xs-100 mt-2 position-relative">
                  <div className="w-100  w-xs-100 mt-xs-5 position-relative">
                    <input
                      className="w-80 cust-btn searchFill w-xs-100 ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                      placeholder="Price to Customer"
                      onChange={(e) => setprice(e.target.value)}
                      value={price}
                      type="number"
                    />
                    {price?.length == 0 && priceErr && (
                      <div className="d-flex gap-1 position-absolute">
                        <ErrorIcon className="svg_log" />
                        <p className="err-input_log mt-1">
                          Enter Price to Customer
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex mt-3 justify-content-end m-2">
              <button
                onClick={submitHandle}
                disabled={btn}
                className={`mt-2 cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
                  btn && "opacity-50"
                }`}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="dashRightView p-5 home_section trans">
        <div className="d-flex ac-jb mt-2 flex-wrap">
          <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary">
            Shipping Charge
          </p>
          <div className="w-100 ac-jb d-flex flex-md-row flex-column mt-4">
            <div className="d-flex">
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
                Show
              </p>
              <select
                className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary"
                onChange={(e) => getList(searchValue, e.target.value)}
                value={pageRow}
              >
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="25">25</option>
              </select>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
                Entries
              </p>
            </div>
            <div className="d-flex flex-md-row flex-column">
              {/* <div className="d-flex mt-sm-3">
              <img src={searchIcon} className="searchiConImg" alt="icon" />
              <input
                className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                placeholder="Search"
              />
            </div> */}
              <div className="d-flex ac-jb flex-md-row flex-column">
                <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont me-3 mt-4">
                  Total Count : {pagenation?.total}
                </p>
                <div
                  className="pointerView d-flex mt-4 me-3"
                  role={"button"}
                  onClick={getAdminExports}
                >
                  <GetAppIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
                  <p
                    className={`gray f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14`}
                  >
                    Export
                  </p>
                </div>
                <div className="d-flex mt-sm-3 border-search mx-1">
                  <img
                    src={searchIcon}
                    alt="search"
                    className="searchiConImg mt-1"
                  />
                  <input
                    className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                    placeholder="Search"
                    onChange={(e) => getList(e.target.value, pageRow)}
                    value={searchValue}
                  />
                </div>
                <button
                  onClick={() => toggleShowPopup("add")}
                  className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                >
                  + Add Shipping Charge
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5" style={{ width: "100%", overflow: "auto" }}>
          <table className="table-design-new" style={{ width: "100%" }}>
            <thead>
              <tr className="border-bottom w-100 text-nowrap">
                <th>
                  <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                    S.No
                  </p>
                </th>
                <th>
                  <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                    Type
                  </p>
                </th>
                <th>
                  <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                    Location{" "}
                  </p>
                </th>
                <th>
                  <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                    Price to customer
                  </p>
                </th>
                <th>
                  <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                    Box Type
                  </p>
                </th>
                <th>
                  {" "}
                  <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                    Status
                  </p>
                </th>
                <th>
                  {" "}
                  <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                    Action
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              {list?.map((item, index) => {
                return (
                  <tr>
                    <td>
                      <p className="pointerView f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                        {index + 1}
                      </p>
                    </td>
                    <td>
                      <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1 text-capitalize">
                        {item?.shipping_type?.name}
                      </p>
                    </td>
                    <td>
                      <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1 text-capitalize">
                        {item?.shipping_location?.name}
                      </p>
                    </td>
                    <td>
                      <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1 text-capitalize">
                        {item?.price}
                      </p>
                    </td>
                    <td>
                      <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1 text-capitalize">
                        {item?.type == 2 ? "Bulk" : "Individual"}
                      </p>
                    </td>
                    <td>
                      <div
                        className="d-flex ac-jc"
                        onClick={() => change(item?.id)}
                      >
                        {item?.status === 1 ? (
                          <div>
                            <img src={toggleOn} className="toggleOnDes" />
                          </div>
                        ) : (
                          <div>
                            <img src={toggleOff} className="toggleOnDes" />
                          </div>
                        )}
                      </div>
                    </td>
                    <td>
                      <div
                        onClick={() => getAttribute(item)}
                        className="ms-2 p-2 bg-white rounded-2 d-flex justify-content-center"
                      >
                        <img src={editIcon} className="viewDes" alt="icon" />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-between mt-3">
          <button
            className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
              page == 1 && "opacity-50"
            }`}
            onClick={() => setPage(page - 1)}
            disabled={page == 1 ? true : false}
          >
            Previous
          </button>
          <button
            className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
              page == pagenation?.last_page && "opacity-50"
            }`}
            onClick={() => setPage(page + 1)}
            disabled={page == pagenation?.last_page ? true : false}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default ChargeShippingPage;
