import React, { useRef, useState } from "react";
import { Col } from "react-bootstrap";
import ErrorIcon from "@mui/icons-material/Error";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { usePresentationArtworkStatusMutation } from "../../redux/api/api";
import { useDispatch } from "react-redux";
import { saveLoader } from "../../redux/slice/loaderNoti";
import { toast } from "react-hot-toast";

const AddArtWorker = ({
  toggleShowPopup,
  setAddArt,
  toggleShowPopups,
  statusOption,

  decorative_method_id,
  setdecorative_method_id,
  decorative_method_name,
  setdecorative_method_name,
  proof_required,
  setproof_required,
  logo_name,
  setlogo_name,
  uom,
  setuom,
  logo_length,
  setlogo_length,
  logo_breadth,
  setlogo_breadth,
  logo_color,
  setlogo_color,
  repeat_logo,
  setrepeat_logo,
  supplier_note,
  setsupplier_note,
  location,
  setlocation,
  file,
  setfile,
  decorative_method_idErr,
  proof_requiredErr,
  logo_nameErr,
  uomErr,
  logo_lengthErr,
  logo_breadthErr,
  logo_colorErr,
  repeat_logoErr,
  supplier_noteErr,
  locationErr,
  fileErr,
  decorative_methodList,
  decorativeMethodList,
  setDecorativeMethodList,
  btn,
  submitHandleArt,
  artworkDatail,

  design_status,
  sale_status,
  handleStatusArt,
}) => {
  const dispatch = useDispatch();
  const imgRef = useRef();

  const [ticketStatus, setTicketStatus] = useState(1);
  const [dropDown, setDropDown] = useState(null);
  const [searchIconTags, setSearchIconTags] = useState("");

  const searchTagsFunIcon = (event) => {
    let temp = [...decorative_methodList];
    let search = temp.filter((value) =>
      value?.first_name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setDecorativeMethodList(search);
    } else {
      setDecorativeMethodList(decorative_methodList);
    }

    setSearchIconTags(event);
  };

  const onTagsSelectIcon = (event, id, type) => {
    setdecorative_method_name(event);
    setdecorative_method_id(id);

    if (type !== "check") {
      setDropDown(null);
    }

    setSearchIconTags("");
    setDecorativeMethodList(decorative_methodList);
  };

  return (
    <div className="add-client-contact-popup ">
      <div className="addArt" style={{ height: "90vh", overflow: "auto" }}>
        <div className="d-flex justify-content-between">
          <div>
            <p className="mt-2 f4 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
              {" "}
              Add Artwork
            </p>
          </div>

          {statusOption && (
            <div className="d-flex ac-js">
              <Col sm={6} md={6} lg={6} className="">
                <div className="d-flex flex-column flex-md-row flex-wrap m-2">
                  <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Sales Status
                  </p>
                  <select
                    placeholder="Status"
                    className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    onChange={(e) => handleStatusArt(e.target.value, 1)}
                    value={sale_status}
                    disabled={
                      artworkDatail?.id && artworkDatail?.sale_status == 2
                        ? true
                        : artworkDatail?.id
                        ? false
                        : true
                    }
                  >
                    <option value={1}>Inprogress</option>
                    <option value={2}>Approved</option>
                    <option value={3}>Rejected</option>
                  </select>
                </div>
              </Col>
              <Col sm={6} md={6} lg={6} className="position-relative rel_sty">
                <div className="d-flex flex-column flex-md-row flex-wrap m-2">
                  <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Design Status
                  </p>
                  <select
                    placeholder="Status"
                    className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    onChange={(e) => handleStatusArt(e.target.value, 2)}
                    value={design_status}
                    disabled={
                      artworkDatail?.id && artworkDatail?.sale_status == 2
                        ? true
                        : artworkDatail?.id
                        ? false
                        : true
                    }
                  >
                    <option value={1}>Inprogress</option>
                    <option value={2}>Completed</option>
                  </select>
                </div>
              </Col>
            </div>
          )}

          <div className="mt-2">
            <button
              className=" cust-btn addbtn ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
              onClick={() => toggleShowPopup()}
            >
              Cancel
            </button>
            <button
              className={`cust-btn addbtn ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded ${
                btn
                  ? "opacity-50"
                  : artworkDatail?.sale_status == 2
                  ? "opacity-50"
                  : ""
              }`}
              onClick={() => submitHandleArt()}
              disabled={
                btn ? true : artworkDatail?.sale_status == 2 ? true : false
              }
            >
              Save
            </button>
          </div>
        </div>

        <div className="popUpForm d-flex flex-wrap">
          <Col sm={12} md={6}>
            <div className="w-100 d-flex ac-js">
              <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                <div className="w-100 d-flex ac-js mt-3">
                  <p className="w-50 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                    Decorative Method
                  </p>
                  <div className="w-50 position-relative">
                    {/* <div className="w-70 ms-3 position-relative"> */}
                    <input
                      placeholder="Decorative Method"
                      type="text"
                      value={
                        decorative_method_name
                          ? decorative_method_name
                          : "Select Decorative Method"
                      }
                      onClick={() => {
                        setDropDown(4);
                      }}
                      className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                      disabled={artworkDatail?.sale_status == 2 ? true : false}
                    />
                    <button
                      className="drop_down cust-btn"
                      onClick={() => {
                        setDropDown(4);
                      }}
                      disabled={artworkDatail?.sale_status == 2 ? true : false}
                    >
                      <KeyboardArrowDownIcon />
                    </button>
                    {dropDown == 4 && (
                      <div
                        className="invisible-cont2 z-0"
                        onClick={() => setDropDown(null)}
                      />
                    )}
                    <div
                      className={`${
                        dropDown == 4 && "submenu_1  py-2"
                      } submenu_cont_1 overflow-scroll z-3`}
                    >
                      <div className="d-flex gap-1">
                        <input
                          className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                          placeholder="Search..."
                          onChange={(e) => searchTagsFunIcon(e.target.value)}
                          value={searchIconTags}
                        />
                      </div>
                      {decorativeMethodList?.map((item, ind) => {
                        return (
                          <button
                            className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                            key={ind}
                          >
                            <button
                              className="px-2 cust-btn text-start py-1 w-100 "
                              onClick={() => {
                                onTagsSelectIcon(item?.name, item?.id);
                              }}
                            >
                              {item?.name}
                            </button>
                          </button>
                        );
                      })}
                    </div>
                    {/* </div> */}
                    {decorative_method_id?.length == 0 &&
                      decorative_method_idErr && (
                        <div className="d-flex gap-1 mt-2">
                          <ErrorIcon className="svg_log" />
                          <p className="err-input_log mt-1">
                            Select Decorative Method
                          </p>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100 d-flex ac-js">
              <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                <div className="w-100 d-flex ac-js mt-3">
                  <p className="w-50 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                    Proof Required:
                  </p>
                  <div className="w-100 ms-3">
                    <select
                      placeholder=""
                      className="w-100 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                      name=""
                      id=""
                      onChange={(e) => setproof_required(e.target.value)}
                      value={proof_required}
                      disabled={artworkDatail?.sale_status == 2 ? true : false}
                    >
                      <option hidden>Select Proof Type</option>
                      <option value={"email_proof"}>Email Proof</option>
                      <option value={"digital_photo"}>Digital Photo</option>
                      <option value={"pre_production"}>Pre Production</option>
                    </select>
                    {proof_required?.length == 0 && proof_requiredErr && (
                      <div className="d-flex gap-1 mt-2">
                        <ErrorIcon className="svg_log" />
                        <p className="err-input_log mt-1">Select Proof</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100 d-flex ac-js mt-2">
              <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                <div className="w-100 d-flex ac-js mt-2">
                  <p className="w-50 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                    Logo Name:
                  </p>
                  <div className="w-100 ms-3">
                    <textarea
                      placeholder="Required Field"
                      className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      onChange={(e) => setlogo_name(e.target.value)}
                      value={logo_name}
                      disabled={artworkDatail?.sale_status == 2 ? true : false}
                    />
                    {logo_name?.length == 0 && logo_nameErr && (
                      <div className="d-flex gap-1 mt-2">
                        <ErrorIcon className="svg_log" />
                        <p className="err-input_log mt-1">Enter Logo Name</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100 d-flex ac-js mt-2">
              <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                <div className="w-100 d-flex ac-js mt-2">
                  <p className="w-50 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                    UOM:
                  </p>
                  <div className="w-100">
                    <div className="w-100 ac-js d-flex gap-3 ms-3">
                      <div className="ps-2 bg-transparent ps-2 d-flex ps-2 gap-3 ac-js rounded-4 hover_Ef py-2 cp my-1 ">
                        <button
                          className="d-flex gap-3  ac-js cust-btn"
                          onClick={() => {
                            if (artworkDatail?.sale_status == 2) {
                              console.log(11);
                            } else {
                              setuom(1);
                            }
                          }}
                        >
                          <div className="radio_btn d-flex ac-jc">
                            <div
                              className={`${
                                uom === 1 ? "sm_radio" : "sm_radio1"
                              }`}
                            />
                          </div>
                          <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                            Inches
                          </p>
                        </button>
                      </div>
                      <div className="ps-2 bg-transparent ps-2 d-flex ps-2 gap-3 ac-js rounded-4 hover_Ef py-2 cp my-1 ">
                        <button
                          onClick={() => {
                            if (artworkDatail?.sale_status == 2) {
                              console.log(11);
                            } else {
                              setuom(2);
                            }
                          }}
                          className="d-flex gap-3  ac-js cust-btn"
                        >
                          <div className="radio_btn d-flex ac-jc">
                            <div
                              className={`${
                                uom === 2 ? "sm_radio" : "sm_radio1"
                              }`}
                            />
                          </div>
                          <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                            Stitch
                          </p>
                        </button>
                      </div>
                    </div>
                    {uom == 0 && uomErr && (
                      <div className="d-flex gap-1 mt-2">
                        <ErrorIcon className="svg_log" />
                        <p className="err-input_log mt-1">Select UOM</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100 d-flex ac-js mt-2">
              <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                <div className="w-100 d-flex ac-js mt-2">
                  <p className="w-50 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                    Logo Size:
                  </p>
                  <div className="w-100 ms-3 d-flex gap-2">
                    <div>
                      <input
                        placeholder="Length"
                        className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        onChange={(e) => setlogo_length(e.target.value)}
                        value={logo_length}
                        type={"number"}
                        disabled={
                          artworkDatail?.sale_status == 2 ? true : false
                        }
                      />
                      {logo_length?.length == 0 && logo_lengthErr && (
                        <div className="d-flex gap-1 mt-2">
                          <ErrorIcon className="svg_log" />
                          <p className="err-input_log mt-1">Enter Length</p>
                        </div>
                      )}
                    </div>
                    <div>
                      <input
                        placeholder="Breadth"
                        className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        onChange={(e) => setlogo_breadth(e.target.value)}
                        value={logo_breadth}
                        type={"number"}
                        disabled={
                          artworkDatail?.sale_status == 2 ? true : false
                        }
                      />
                      {logo_breadth?.length == 0 && logo_breadthErr && (
                        <div className="d-flex gap-1 mt-2">
                          <ErrorIcon className="svg_log" />
                          <p className="err-input_log mt-1">Enter Breadth</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100 d-flex ac-js mt-2">
              <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                <div className="w-100 d-flex ac-js mt-2">
                  <p className="w-50 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                    Logo Color:
                  </p>
                  <div className="w-100 ms-3">
                    <textarea
                      placeholder="Required Field"
                      className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      onChange={(e) => setlogo_color(e.target.value)}
                      value={logo_color}
                      disabled={artworkDatail?.sale_status == 2 ? true : false}
                    />
                    {logo_color?.length == 0 && logo_colorErr && (
                      <div className="d-flex gap-1 mt-2">
                        <ErrorIcon className="svg_log" />
                        <p className="err-input_log mt-1">Enter Breadth</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100 d-flex ac-js">
              <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                <div className="w-100 d-flex ac-js mt-2">
                  <p className="w-50 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                    Repeat Logo?:
                  </p>
                  <div className="w-100 ms-3">
                    <select
                      placeholder=""
                      className="w-100 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                      name=""
                      id=""
                      onChange={(e) => setrepeat_logo(e.target.value)}
                      value={repeat_logo}
                      disabled={artworkDatail?.sale_status == 2 ? true : false}
                    >
                      <option value={""}></option>
                      <option value={"yes"}>Yes</option>
                      <option value={"no"}>No</option>
                    </select>
                    {repeat_logo?.length == 0 && repeat_logoErr && (
                      <div className="d-flex gap-1 mt-2">
                        <ErrorIcon className="svg_log" />
                        <p className="err-input_log mt-1">Select Repeat Logo</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="w-100 d-flex ac-js mt-2">
              <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                <div className="w-100 d-flex ac-js mt-2">
                  <p className="w-50 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                    Supplier Notes:
                  </p>
                  <div className="w-100 ms-3">
                    <textarea
                      placeholder="Required Field"
                      className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      onChange={(e) => setsupplier_note(e.target.value)}
                      value={supplier_note}
                      disabled={artworkDatail?.sale_status == 2 ? true : false}
                    />
                    {supplier_note?.length == 0 && supplier_noteErr && (
                      <div className="d-flex gap-1 mt-2">
                        <ErrorIcon className="svg_log" />
                        <p className="err-input_log mt-1">
                          Enter Supplier Notes
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={6}>
            <div className="field_row mt-3 mx-2 ">
              {file ? (
                <div className="art-placeholder p-1">
                  <img
                    className="w-100"
                    src={file?.name ? URL.createObjectURL(file) : file}
                    onClick={() => {
                      if (artworkDatail?.sale_status == 2) {
                        console.log(11);
                      } else {
                        imgRef.current.click();
                      }
                    }}
                  />
                </div>
              ) : (
                <div
                  className="art-placeholder"
                  // onClick={() => toggleShowPopups()}
                  onClick={() => {
                    if (artworkDatail?.sale_status == 2) {
                      console.log(11);
                    } else {
                      imgRef.current.click();
                    }
                  }}
                >
                  Select Image
                </div>
              )}
            </div>
            <input
              ref={imgRef}
              type={"file"}
              className="d-none"
              onChange={(e) => setfile(e.target.files[0])}
            />
            {file?.length == 0 && fileErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Select Image</p>
              </div>
            )}
          </Col>
        </div>
      </div>
    </div>
  );
};

export default AddArtWorker;
