import React, { useEffect, useState } from "react";
import { danger, danger_sm, product } from "../../assets/img";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorIcon from "@mui/icons-material/Error";
import moment from "moment";
import { Close } from "@mui/icons-material";
import CheckingPricePoppup from "../EstimateComp/Poppup/CheckingPricePoppup";
import ImageUpload from "../EstimateComp/Poppup/ImageUpload";
import ImageView from "../EstimateComp/Poppup/ImageView";
import PresntationImage from "./PresntationImage";
import PresentationEditTable from "./PresentationEditTable";
import PresentationPricingTable from "./PresentationPricingTable";
import AddArtWorker from "./AddArtWorker";
import AddFilesPopup from "../Popup/AddFilesOverview";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { sub_menu } from "../../redux/api/DummyJson";
import LockOpenRoundedIcon from "@mui/icons-material/LockOpenRounded";
import HttpsRoundedIcon from "@mui/icons-material/HttpsRounded";
import SouthRoundedIcon from "@mui/icons-material/SouthRounded";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import { Col } from "react-bootstrap";
import {
  useAddProductInfoMutation,
  useLazyPresentationProViewQuery,
  usePresentationArtworkStatusMutation,
  usePresentationProPricingMutation,
} from "../../redux/api/api";
import { useDispatch } from "react-redux";
import { saveLoader } from "../../redux/slice/loaderNoti";
import { toast } from "react-hot-toast";

const PresentationEditScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locationNavi = useLocation();
  const productDataList = locationNavi?.state?.list;

  // RTK QUERY
  const [presentationProViewApi] = useLazyPresentationProViewQuery();
  const [presentationProPricingApi] = usePresentationProPricingMutation();
  const [presentationInfoAddApi] = useAddProductInfoMutation();
  const [artworkStatusApi] = usePresentationArtworkStatusMutation();

  const [productName, setProductName] = useState("Brand Shirt");
  const [warnings, setWarnings] = useState([]);
  const [personalCom, setPersonalCom] = useState("");
  const [personalComs, setPersonalComs] = useState([]);
  const [addClient, setAddClient] = useState(false);
  const [AddFilesOverview, setAddFilesOverview] = useState(false);
  const [btns, setBtn] = useState(false);
  const [addArt, setAddArt] = useState(false);
  const [priceCharge, setPriceCharge] = useState([]);
  const [runCharge, setRunCharge] = useState([]);
  const [dropdown, setDropDown] = useState(null);
  const [productPrice, setproductPrice] = useState({});
  const [numbers, setNumbers] = useState([]);
  const [qty, setQty] = useState({
    25: 25,
    50: 50,
    100: 100,
    150: 150,
    250: 250,
    500: 500,
    1000: 1000,
  });
  const [imageUpload, setImageUpload] = useState(false);
  const [ticketStatus, setTicketStatus] = useState(1);

  const [artworkData, setArtWorkData] = useState({});
  const [artworkDatail, setArtWorkDatail] = useState({});
  const [decorative_methodList, setdecorative_methodList] = useState([]);
  const [decorativeMethodList, setDecorativeMethodList] = useState([]);
  const [decorative_method_id, setdecorative_method_id] = useState("");
  const [decorative_method_name, setdecorative_method_name] = useState("");
  const [proof_required, setproof_required] = useState("");
  const [logo_name, setlogo_name] = useState("");
  const [uom, setuom] = useState(0);
  const [logo_length, setlogo_length] = useState("");
  const [logo_breadth, setlogo_breadth] = useState("");
  const [logo_color, setlogo_color] = useState("");
  const [repeat_logo, setrepeat_logo] = useState("");
  const [supplier_note, setsupplier_note] = useState("");
  const [location2, setlocation2] = useState("");
  const [file, setfile] = useState("");
  const [design_status, setdesign_status] = useState("");
  const [sale_status, setsale_status] = useState("");

  const [decorative_method_idErr, setdecorative_method_idErr] = useState(false);
  const [proof_requiredErr, setproof_requiredErr] = useState(false);
  const [logo_nameErr, setlogo_nameErr] = useState(false);
  const [uomErr, setuomErr] = useState(false);
  const [logo_lengthErr, setlogo_lengthErr] = useState(false);
  const [logo_breadthErr, setlogo_breadthErr] = useState(false);
  const [logo_colorErr, setlogo_colorErr] = useState(false);
  const [repeat_logoErr, setrepeat_logoErr] = useState(false);
  const [supplier_noteErr, setsupplier_noteErr] = useState(false);
  const [locationErr, setlocationErr] = useState(false);
  const [fileErr, setfileErr] = useState(false);

  const [productData, setProductData] = useState({});

  const [viewSupplier, setViewSupplier] = useState([]);
  const [decorative_methods, setdecorative_methods] = useState([]);
  const [skus, setskus] = useState([]);
  const [addLocation, setAddLocation] = useState([""]);
  const [location, setLocation] = useState([]);
  const [addItemLoc, setAddItemLoc] = useState([""]);
  const [addFixedChargeDec, setAddFixedChargeDec] = useState([""]);
  const [addRunChargeDec, setAddRunChargeDec] = useState([""]);
  const [decoration, setDecoration] = useState([1, 2]);
  const [addColor, setAddColor] = useState([]);
  const [run, setRun] = useState({});
  const [fixed, setFixed] = useState({});
  const [decorationData, setDecorationData] = useState({
    0: {
      0: { text: "Imprint Cost", select: 1 },
      1: { text: "Setup Cost", select: 1 },
    },
  });

  const [privateComment, setPrivateComment] = useState("");
  const [privateNote, setPrivateNotes] = useState("");

  const [production_lead_time, setproduction_lead_time] = useState(
    "10-12 Business Days"
  );
  const [sample_lead_time, setsample_lead_time] = useState("2-5 Business Days");
  const [product_note, setproduct_note] = useState("");
  const [description, setdescription] = useState("");

  const [production_lead_timeErr, setproduction_lead_timeErr] = useState(false);
  const [sample_lead_timeErr, setsample_lead_timeErr] = useState(false);
  const [product_noteErr, setproduct_noteErr] = useState(false);
  const [descriptionErr, setdescriptionErr] = useState(false);

  const calculation = (value, for_price) => {
    let percen = (+value / 100) * +for_price;
    let sum = Number(+percen + +for_price).toFixed();
    return +sum;
  };

  const getView = () => {
    presentationProViewApi(locationNavi?.state?.data?.id)
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          let responce = res?.project_detail;
          setProductData(res?.project_detail);
          setLocation(res?.project_detail?.projectArtwork);
          let data = res?.productPrice;
          let prices = {
            ...data,
            total: {
              for_25: +data?.for_25,
              for_50: +data?.for_50,
              for_100: +data?.for_100,
              for_150: +data?.for_150,
              for_250: +data?.for_250,
              for_500: +data?.for_500,
              for_1000: +data?.for_1000,
            },
          };

          let default_price = {
            for_25: +data?.productDistributorPrice?.for_25,
            for_50: +data?.productDistributorPrice?.for_50,
            for_100: +data?.productDistributorPrice?.for_100,
            for_150: +data?.productDistributorPrice?.for_150,
            for_250: +data?.productDistributorPrice?.for_250,
            for_500: +data?.productDistributorPrice?.for_500,
            for_1000: +data?.productDistributorPrice?.for_1000,
          };

          if (responce?.projectPresentationPrice?.length > 0) {
            let run_charge = [];
            let final_prices = {
              ...prices,
            };

            let projectPresentationPrice = [];
            responce?.projectPresentationPrice?.map((item, ind) => {
              if (item?.qty >= 1 && item?.qty <= 25) {
                projectPresentationPrice.push({
                  ...item,
                  actual_qty: 25,
                });
                final_prices["total"]["for_25"] = +item?.total;
              } else if (item?.qty >= 26 && item?.qty <= 50) {
                projectPresentationPrice.push({
                  ...item,
                  actual_qty: 50,
                });
                final_prices["total"]["for_50"] = +item?.total;
              } else if (item?.qty >= 51 && item?.qty <= 100) {
                projectPresentationPrice.push({
                  ...item,
                  actual_qty: 100,
                });
                final_prices["total"]["for_100"] = +item?.total;
              } else if (item?.qty >= 101 && item?.qty <= 150) {
                projectPresentationPrice.push({
                  ...item,
                  actual_qty: 150,
                });
                final_prices["total"]["for_150"] = +item?.total;
              } else if (item?.qty >= 151 && item?.qty <= 250) {
                projectPresentationPrice.push({
                  ...item,
                  actual_qty: 250,
                });
                final_prices["total"]["for_250"] = +item?.total;
              } else if (item?.qty >= 251 && item?.qty <= 500) {
                projectPresentationPrice.push({
                  ...item,
                  actual_qty: 500,
                });
                final_prices["total"]["for_500"] = +item?.total;
              } else if (item?.qty >= 501 && item?.qty <= 1000) {
                projectPresentationPrice.push({
                  ...item,
                  actual_qty: 1000,
                });
                final_prices["total"]["for_1000"] = +item?.total;
              }
            });

            let tempEnable = [];

            projectPresentationPrice?.map((item, ind) => {
              let arr = [25, 50, 100, 150, 250, 500, 1000];
              arr.forEach((ar, aind) => {
                if (item?.actual_qty == ar) {
                  setQty((val) => ({ ...val, [ar]: item?.qty }));
                  tempEnable.push(`for_${ar}`);
                }
              });
            });

            let margin = projectPresentationPrice?.[0]?.margin;

            final_prices["for_25"] = calculation(margin, default_price?.for_25);
            final_prices["for_50"] = calculation(margin, default_price?.for_50);
            final_prices["for_100"] = calculation(
              margin,
              default_price?.for_100
            );
            final_prices["for_150"] = calculation(
              margin,
              default_price?.for_150
            );
            final_prices["for_250"] = calculation(
              margin,
              default_price?.for_250
            );
            final_prices["for_500"] = calculation(
              margin,
              default_price?.for_500
            );
            final_prices["for_1000"] = calculation(
              margin,
              default_price?.for_1000
            );
            final_prices["scp_percentage"] = margin;

            setproductPrice(final_prices);

            setNumbers(tempEnable);

            let chargeObj = {};

            projectPresentationPrice.forEach((item) => {
              item.run_charge.forEach((charge) => {
                if (!chargeObj[charge.name]) {
                  chargeObj[charge.name] = { name: charge.name };
                }
                chargeObj[charge.name][`for_${item.actual_qty}`] =
                  +charge.value;
              });
            });
            let finaRunCharges = Object.values(chargeObj);
            if (finaRunCharges?.length > 0) {
              finaRunCharges.forEach((obj) => {
                let for_keys = [
                  "for_25",
                  "for_50",
                  "for_100",
                  "for_150",
                  "for_250",
                  "for_500",
                  "for_1000",
                ];
                for_keys.forEach((key) => {
                  if (!(key in obj)) {
                    obj[key] = 0;
                  }
                });
              });
              setRunCharge(finaRunCharges);
            }
          } else {
            setproductPrice(prices);
          }

          setViewSupplier(res?.vendors);
          setdecorative_methods(res?.decorative_methods);
          setskus(res?.skus);

          if (responce?.personal_comment) {
            setPrivateComment(responce?.personal_comment);
          }

          if (responce?.private_note) {
            setPrivateNotes(responce?.private_note);
          }

          if (responce?.production_lead_time) {
            setproduction_lead_time(responce?.production_lead_time);
          }

          if (responce?.sample_lead_time) {
            setsample_lead_time(responce?.sample_lead_time);
          }

          if (responce?.product_note) {
            setproduct_note(responce?.product_note);
          }

          if (responce?.description) {
            setdescription(responce?.description);
          }

          if (responce?.warnings?.length > 0) {
            let warnings = [];
            responce?.warnings?.map((item, ind) => {
              warnings.push({ warn: item });
            });
            setWarnings(warnings);
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (locationNavi?.state?.data?.id) {
      getView();
    }
  }, []);

  const handleStatusArt = (status, type) => {
    dispatch(saveLoader(true));
    let formdata = new FormData();

    formdata.append("artwork_id", artworkDatail?.id);

    if (type == 1) {
      formdata.append("sale_status", status);
      setsale_status(status);
    }

    if (type == 2) {
      formdata.append("design_status", status);
      setdesign_status(status);
    }

    artworkStatusApi(formdata)
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        dispatch(saveLoader(false));
        getView();
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        console.log("err", err);
      });
  };

  const removeDecoration = (item, ind, ind2) => {
    let temp = [...decoration];

    let num = decoration[ind2];

    temp.splice(ind, 1);
    setDecoration(temp);

    if (Object.values(decorationData)?.length > 0) {
      let obj = decorationData[ind2];

      console.log("obj", obj);

      if (obj !== undefined) {
        delete obj[ind];

        let tem = Object.values(obj);

        if (tem?.length == 0) {
          delete decorationData[ind2];
        }

        setDecorationData((val) => ({ ...val }));
      }
    }
  };

  const chargeDecoration = (event, type, ind, ind2) => {
    let value = event.target.value;
    if (decorationData[ind2]) {
      let obj = { ...decorationData[ind2] };

      let tems = { ...obj[ind] };

      let tem = {
        ...obj,
        [ind]: { ...tems, [type]: value },
      };

      setDecorationData({ ...decorationData, [ind2]: tem });
    } else {
      let obj = { ...decorationData[ind2] };

      let tems = { ...obj[ind] };

      let tem = {
        ...obj,
        [ind]: {
          ...tems,
          [type]: value,
        },
      };

      setDecorationData({ ...decorationData, [ind2]: tem });
    }
  };

  const addWarn = () => {
    let temp = [...warnings];
    temp.push({ warn: "" });
    setWarnings(temp);
  };

  const removeWarn = (e, ind) => {
    let temp = [...warnings];
    temp.splice(ind, 1);
    setWarnings(temp);
  };

  const handleChangeWarn = (e, index) => {
    let temp = [...warnings];
    const name = e.target.name;
    const value = e.target.value;
    temp[index][name] = value;
    setWarnings(temp);
  };

  const addPost = () => {
    if (personalCom?.length > 0) {
      let temp = [...personalComs];

      temp.push({
        name: "Roger",
        command: personalCom,
        date: moment(new Date()).format("YYYY-MM-DD"),
      });

      setPersonalCom("");
      setPersonalComs(temp.reverse());
    }
  };

  const removePost = (item, index) => {
    let temp = [...personalComs];
    temp.splice(index, 1);
    setPersonalComs(temp);
  };

  const artWork = () => {
    toggleShowPopup();
  };

  const toggleShowPopup = () => {
    setAddClient(!addClient);
  };

  const toggleShowPopups = () => {
    setAddFilesOverview(!AddFilesOverview);
  };

  const locationRemove = (num) => {
    let temp = [...location];
    temp.splice(num, 1);
    setLocation(temp);
  };

  const removeAddItemClick = (e, ind, ind2) => {
    let temp = [...addItemLoc];
    temp.splice(ind2[ind], 1);
    setAddItemLoc(temp);
  };

  const addAddLocClick2 = () => {
    let temp = [...addLocation];
    temp.push({ warn: "" });
    setAddLocation(temp);
  };

  const locationAdd = (num, type) => {
    let temp = [...location];
    temp.push(num);

    for (let i = 0; i < temp?.length; i++) {
      setDecorationData((val) => ({
        ...val,
        [i + 1]: {
          0: { text: "Imprint Cost", select: 1 },
          1: { text: "Setup Cost", select: 1 },
        },
      }));
    }

    if (type == "copy") {
      // console.log("ss");
      let obj = { ...decorationData };
    }
    setLocation(temp);
  };

  const poppupImageToggle = () => {
    setImageUpload(!imageUpload);
  };

  // console.log("runCharge", runCharge, productData);

  const submitHandle = () => {
    let count = 1;
    let formadata = new FormData();
    formadata.append("project_id", locationNavi?.state?.data?.project_id);
    formadata.append("project_detail_id", locationNavi?.state?.data?.id);
    formadata.append("product_id", locationNavi?.state?.data?.product?.id);

    let pricings = [];

    let price = {
      for_25: productPrice?.for_25,
      for_50: productPrice?.for_50,
      for_100: productPrice?.for_100,
      for_150: productPrice?.for_150,
      for_250: productPrice?.for_250,
      for_500: productPrice?.for_500,
      for_1000: productPrice?.for_1000,
    };

    let total_price = {
      for_25: productPrice?.total?.for_25,
      for_50: productPrice?.total?.for_50,
      for_100: productPrice?.total?.for_100,
      for_150: productPrice?.total?.for_150,
      for_250: productPrice?.total?.for_250,
      for_500: productPrice?.total?.for_500,
      for_1000: productPrice?.total?.for_1000,
    };

    if (total_price?.for_50) {
      Object.keys(total_price).map((item, ind) => {
        let key = item?.split("_");
        let obj = {};
        let tem_price = [];
        if (numbers?.includes(item)) {
          obj.qty = +qty?.[key[1]];
          obj.actual_qty = +key[1];
          obj.total = total_price[item];
          obj.margin = productPrice?.scp_percentage;
          for (let i = 0; i < runCharge?.length; i++) {
            tem_price.push({
              value: runCharge[i]?.[item],
              name: runCharge[i]?.name,
            });
          }
          obj.run_charge = tem_price;
          pricings.push(obj);
        }
      });
    }

    // console.log("pricings", pricings);

    pricings?.map((item, ind) => {
      formadata.append(`pricings[${ind}][qty]`, item?.qty);
      formadata.append(`pricings[${ind}][margin]`, item?.margin);
      formadata.append(`pricings[${ind}][total]`, item?.total);
      item?.run_charge?.map((charge, cind) => {
        if (charge?.name) {
          formadata.append(
            `pricings[${ind}][run_charge][${cind}][name]`,
            charge?.name
          );
        } else {
          count = count + 1;
        }

        if (charge?.value) {
          formadata.append(
            `pricings[${ind}][run_charge][${cind}][value]`,
            charge?.value
          );
        } else {
          count = count + 1;
        }
      });
    });

    if (privateComment) {
      formadata.append("personal_comment", privateComment);
    }

    if (privateNote) {
      formadata.append("private_note", privateNote);
    }

    if (productPrice?.request == 1) {
      formadata.append("price_status", 1);
    }

    if (count == 1) {
      setBtn(true);
      dispatch(saveLoader(true));
      presentationProPricingApi(formadata)
        .unwrap()
        .then((res) => {
          setBtn(false);
          dispatch(saveLoader(false));
          if (res?.status == "success") {
            toast.success(res?.message);
          }
        })
        .catch((err) => {
          setBtn(false);
          dispatch(saveLoader(false));
          console.log("err", err);
        });
    } else {
      toast.error("Please Fil All Details");
    }
  };

  const submitHandleInfo = () => {
    if (
      production_lead_time?.length == 0 ||
      sample_lead_time?.length == 0 ||
      product_note?.length == 0 ||
      description?.length == 0
    ) {
      setproduct_noteErr(true);
      setproduction_lead_timeErr(true);
      setsample_lead_timeErr(true);
      setdescriptionErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("project_detail_id", locationNavi?.state?.data?.id);
      formdata.append("production_lead_time", production_lead_time);
      formdata.append("sample_lead_time", sample_lead_time);
      formdata.append("product_note", product_note);
      formdata.append("description", description);

      if (warnings?.length > 0) {
        warnings?.map((item, ind) => {
          formdata.append(`warnings[${ind}]`, item?.warn);
        });
      }

      dispatch(saveLoader(true));
      setBtn(true);
      presentationInfoAddApi(formdata)
        .unwrap()
        .then((res) => {
          dispatch(saveLoader(false));
          setBtn(false);
          if (res?.status == "success") {
            toast.success(res?.message);
            getView();
          }
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          setBtn(false);
          console.log("err", err);
        });
    }
  };

  const artWorkHandler = (art, item, index, aind) => {
    if (art?.decorative_method_id?.id) {
      setdecorative_method_id(art?.decorative_method_id?.id);
      setdecorative_method_name(art?.decorative_method_id?.name);
    }
    if (art?.proof_required) {
      setproof_required(art?.proof_required);
    }

    if (art?.logo_name) {
      setlogo_name(art?.logo_name);
    }

    if (art?.uom) {
      setuom(+art?.uom);
    }

    if (art?.logo_length) {
      setlogo_length(art?.logo_length);
    }

    if (art?.logo_breadth) {
      setlogo_breadth(art?.logo_breadth);
    }

    if (art?.logo_color) {
      setlogo_color(art?.logo_color);
    }

    if (art?.repeat_logo) {
      setrepeat_logo(art?.repeat_logo);
    }

    if (art?.supplier_note) {
      setsupplier_note(art?.supplier_note);
    }

    if (art?.location) {
      setlocation2(art?.location);
    } else {
      setlocation2(aind + 1);
    }

    if (art?.image_url) {
      setfile(art?.image_url);
    }

    if (art?.design_status) {
      setdesign_status(art?.design_status);
    }

    if (art?.sale_status) {
      setsale_status(art?.sale_status);
    }

    if (art?.id) {
      setArtWorkDatail(art);
    }

    setdecorative_methodList(item?.product?.productDecorations);
    setDecorativeMethodList(item?.product?.productDecorations);
    setArtWorkData(item);
    setAddClient(true);
  };

  const toggleShowClosePopup = () => {
    setdecorative_method_id("");
    setdecorative_method_name("");
    setproof_required("");
    setlogo_name("");
    setuom("");
    setlogo_length("");
    setlogo_breadth("");
    setlogo_color("");
    setrepeat_logo("");
    setsupplier_note("");
    setlocation2("");
    setfile("");
    setdecorative_method_idErr(false);
    setproof_requiredErr(false);
    setlogo_nameErr(false);
    setuomErr(false);
    setlogo_lengthErr(false);
    setlogo_breadthErr(false);
    setlogo_colorErr(false);
    setrepeat_logoErr(false);
    setsupplier_noteErr(false);
    setlocationErr(false);
    setfileErr(false);
    setAddClient(false);
    setArtWorkData({});
    setArtWorkDatail({});
    setdesign_status("");
    setsale_status("");
  };

  return (
    <div className="dashRightView p-5">
      {imageUpload && (
        <AddArtWorker
          toggleShowPopup={poppupImageToggle}
          setAddArt={setAddArt}
          toggleShowPopups={toggleShowPopups}
        />
      )}
      {/* {checkPricePoppup && <CheckingPricePoppup poppupAddressToggle={poppupAddressToggle} />}
            {imageUpload && <ImageUpload poppupImageToggle={poppupImageToggle} />}
            {imageViewPoppup && <ImageView poppupImageView={poppupImageView} />} */}
      <div className="w-100 d-flex ac-jb px-4">
        <div>
          <p className="f1 fs-15 fs-xs-16 fs-sm-18 fs-md-19 fs-lg-21 fs-xl-22 fs-xxl-23">
            Edit Item
          </p>
        </div>
        <div className="position-fixed close_position z-3">
          <button
            onClick={() => navigate(-1)}
            class="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-5 rounded"
          >
            Close
          </button>
        </div>
      </div>
      <div className="d-flex as-jb w-100 mt-5 edit_screen_cont res-flex_img gap-5">
        <div className="d-flex flex-wrap w-100 w-md-70 ac-jb res-flex_im flex-m-r">
          <div className="d-flex w-100 gap-3 ac-jb flex-m-r">
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Product Name
              </p>
              <div className="position-relative">
                <input
                  placeholder="Required Field"
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  value={productData?.product?.name}
                  readOnly
                />
              </div>
            </div>
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Supplier
              </p>
              <div className="position-relative">
                <input
                  value={"View Supplier"}
                  placeholder="Required Field"
                  readOnly
                  type={"text"}
                  onClick={() => {
                    setDropDown(1);
                  }}
                  className="editBtn cp w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                />
                <button
                  className="drop_down cust-btn"
                  onClick={() => {
                    setDropDown(1);
                  }}
                >
                  <KeyboardArrowDownIcon />
                </button>
                {dropdown == 1 && (
                  <div
                    className="invisible-cont2 z-0"
                    onClick={() => setDropDown(null)}
                  />
                )}
                <div
                  className={`${
                    dropdown == 1 && "submenu_1"
                  } submenu_cont_1 overflow-scroll z-3`}
                >
                  {viewSupplier?.map((item, ind) => {
                    return (
                      <div className="d-flex hover-cust">
                        <button
                          className="px-2 cust-btn text-start py-1 w-100"
                          onClick={() => {
                            setDropDown(null);
                          }}
                          key={ind}
                        >
                          {item?.vendor?.name}
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="w-xl-30 w-lg-30 w-md-100 w-sm-100 w-xs-100">
              <p className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                SKU
              </p>

              <div className="position-relative">
                <input
                  value={"View SKU"}
                  placeholder="Required Field"
                  readOnly
                  type={"text"}
                  className="editBtn cp w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  onClick={() => {
                    setDropDown(3);
                  }}
                />
                <button
                  className="drop_down cust-btn"
                  onClick={() => {
                    setDropDown(3);
                  }}
                >
                  <KeyboardArrowDownIcon />
                </button>
                {dropdown == 3 && (
                  <div
                    className="invisible-cont2 z-0"
                    onClick={() => setDropDown(null)}
                  />
                )}
                <div
                  className={`${
                    dropdown == 3 && "submenu_1"
                  } submenu_cont_1 overflow-scroll z-3`}
                >
                  {skus?.map((item, ind) => {
                    return (
                      <div className="d-flex hover-cust">
                        <button
                          className="px-2 cust-btn text-start py-1 w-100"
                          onClick={() => {
                            setDropDown(null);
                          }}
                          key={ind}
                        >
                          {item?.variant_name ? item?.variant_name + "-" : ""}
                          {item?.variant_sku}
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="w-xl-30 w-lg-30 w-md-100 w-sm-100 w-xs-100">
              <p className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Decoration Methods
              </p>

              <div className="position-relative">
                <input
                  value={"View Decoration Methods"}
                  placeholder="Required Field"
                  readOnly
                  onClick={() => {
                    setDropDown(2);
                  }}
                  type={"text"}
                  className="editBtn cp w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                />
                <button
                  className="drop_down cust-btn"
                  onClick={() => {
                    setDropDown(2);
                  }}
                >
                  <KeyboardArrowDownIcon />
                </button>
                {dropdown == 2 && (
                  <div
                    className="invisible-cont2 z-0"
                    onClick={() => setDropDown(null)}
                  />
                )}
                <div
                  className={`${
                    dropdown == 2 && "submenu_1"
                  } submenu_cont_1 overflow-scroll z-3`}
                >
                  {decorative_methods?.map((item, ind) => {
                    return (
                      <div className="d-flex hover-cust">
                        <button
                          className="px-2 cust-btn text-start py-1 w-100"
                          onClick={() => {
                            setDropDown(null);
                          }}
                          key={ind}
                        >
                          {item?.decorative_method?.name}
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="w-100 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
            {" "}
            <p
              className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
              style={{ marginLeft: "0px" }}
            >
              Product Description
            </p>
            <textarea
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              placeholder=""
              rows={4}
              value={productData?.product?.description}
            />
          </div>
          <div className="d-flex flex-wrap w-100 w-md-70 ac-jb res-flex_im flex-m-r">
            <div className="d-flex w-100 gap-3 ac-jb flex-m-r">
              <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
                <p className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                  Production Lead Time
                </p>
                <div className="position-relative">
                  <input
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    onChange={(e) => setproduction_lead_time(e.target.value)}
                    value={production_lead_time}
                    disabled={productDataList?.status >= 5 ? true : false}
                  />
                  {production_lead_time?.length == 0 &&
                    production_lead_timeErr && (
                      <div className="d-flex gap-1 mt-2">
                        <ErrorIcon className="svg_log" />
                        <p className="err-input_log mt-1">
                          Enter Production Lead Time
                        </p>
                      </div>
                    )}
                </div>
              </div>
              <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
                <p className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                  Sample Lead Time
                </p>
                <div className="position-relative">
                  <input
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    onChange={(e) => setsample_lead_time(e.target.value)}
                    value={sample_lead_time}
                    disabled={productDataList?.status >= 5 ? true : false}
                  />
                  {sample_lead_time?.length == 0 && sample_lead_timeErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">
                        Enter Sample Lead Time
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
                <p className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                  Product Note
                </p>
                <div className="position-relative">
                  <textarea
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    onChange={(e) => setproduct_note(e.target.value)}
                    value={product_note}
                    disabled={productDataList?.status >= 5 ? true : false}
                  />
                  {product_note?.length == 0 && product_noteErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">Enter Product Note</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="w-100">
            <p className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Description
            </p>
            <div className="position-relative">
              <textarea
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                onChange={(e) => setdescription(e.target.value)}
                value={description}
                disabled={productDataList?.status >= 5 ? true : false}
              />
              {description?.length == 0 && descriptionErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Description</p>
                </div>
              )}
            </div>
          </div>
          {warnings?.map((item, ind) => (
            <div className="w-100 d-flex align-items-start  align-items-md-center gap-0 gap-md-3 flex-column flex-md-row">
              <button className="black d-flex gap-1 ac-jc cust-btn w-auto text-nowrap f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                <img src={danger} className="danger_icon" />
                <p className="f2 fs-xs-11 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  WARNING:
                </p>
              </button>
              <div className="w-100 d-flex ac-jc gap-2">
                <div className="position-relative w-100">
                  <input
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    onChange={(e) => handleChangeWarn(e, ind)}
                    value={item?.warn}
                    name="warn"
                    disabled={productDataList?.status >= 5 ? true : false}
                  />
                </div>
                {productDataList?.status <= 4 && (
                  <button
                    className="cust-btn"
                    onClick={() => removeWarn(item, ind)}
                  >
                    <Close className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16" />
                  </button>
                )}
              </div>
            </div>
          ))}
          <div className="d-flex ac-js gap-2 mt-3 flex-column flex-sm-row w-100 mt-4">
            {productDataList?.status <= 4 && (
              <button
                class="text-nowrap cust-btn addbtn ms-1 bg-primar text-white f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-5 rounded"
                onClick={() => addWarn()}
              >
                Add Warning
              </button>
            )}
            <p className="fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-13 fs-xxl-14">
              Please check supplier website for any warnings now shown
            </p>
          </div>
          <div className="mt-4 w-100 d-flex ac-je">
            {productDataList?.status <= 4 && (
              <button
                class={`cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
                  btns && "opacity-50"
                }`}
                onClick={() => submitHandleInfo()}
                disabled={btns ? true : false}
              >
                Submit
              </button>
            )}
          </div>
          <div className="d-flex mt-5 ac-jb flex-column flex-md-row w-100">
            <div className="d-flex mt-3 ac-jc gap-2 flex-column flex-sm-row">
              <button
                onClick={() =>
                  navigate("/product-library-details", {
                    state: { data: productData?.product, type: "edit" },
                  })
                }
                className="text-nowrap cust-btn addbtn bg-primar text-white f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-5 rounded"
              >
                View Product Page
              </button>
              <button
                onClick={() =>
                  navigate("/product-library-details", {
                    state: { data: productData?.product, type: "edit", tab: 1 },
                  })
                }
                className="text-nowrap cust-btn addbtn bg-primar text-white f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-5 rounded"
              >
                Check Pricing
              </button>
            </div>
            <div className="mt-md-4 mt-3">
              <h5 className="f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 w-100 text-dark">
                Currency :
                <span className="primary f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25">
                  INR
                </span>
              </h5>
            </div>
          </div>
          <div className="overflow-scroll w-100">
            <PresentationEditTable
              productDataList={productDataList}
              runCharge={runCharge}
              setRunCharge={setRunCharge}
              qty={qty}
              setQty={setQty}
              productPrice={productPrice}
              setproductPrice={setproductPrice}
              numbers={numbers}
              setNumbers={setNumbers}
              submitHandle={submitHandle}
              btns={btns}
            />
          </div>
        </div>
        <PresntationImage
          artWork={artWork}
          runCharge={runCharge}
          setRunCharge={setRunCharge}
          setPriceCharge={setPriceCharge}
          priceCharge={priceCharge}
          productData={productData}
        />
        {addClient && (
          <AddArtWorker
            toggleShowPopup={toggleShowClosePopup}
            setAddArt={setAddArt}
            toggleShowPopups={toggleShowPopups}
            statusOption={true}
            decorative_method_id={decorative_method_id}
            setdecorative_method_id={setdecorative_method_id}
            decorative_method_name={decorative_method_name}
            setdecorative_method_name={setdecorative_method_name}
            proof_required={proof_required}
            setproof_required={setproof_required}
            logo_name={logo_name}
            setlogo_name={setlogo_name}
            uom={uom}
            setuom={setuom}
            logo_length={logo_length}
            setlogo_length={setlogo_length}
            logo_breadth={logo_breadth}
            setlogo_breadth={setlogo_breadth}
            logo_color={logo_color}
            setlogo_color={setlogo_color}
            repeat_logo={repeat_logo}
            setrepeat_logo={setrepeat_logo}
            supplier_note={supplier_note}
            setsupplier_note={setsupplier_note}
            location={location2}
            setlocation={setlocation2}
            file={file}
            setfile={setfile}
            decorative_method_idErr={decorative_method_idErr}
            proof_requiredErr={proof_requiredErr}
            logo_nameErr={logo_nameErr}
            uomErr={uomErr}
            logo_lengthErr={logo_lengthErr}
            logo_breadthErr={logo_breadthErr}
            logo_colorErr={logo_colorErr}
            repeat_logoErr={repeat_logoErr}
            supplier_noteErr={supplier_noteErr}
            locationErr={locationErr}
            fileErr={fileErr}
            decorative_methodList={decorative_methodList}
            decorativeMethodList={decorativeMethodList}
            setDecorativeMethodList={setDecorativeMethodList}
            artworkDatail={artworkDatail}
            design_status={design_status}
            sale_status={sale_status}
            handleStatusArt={handleStatusArt}
          />
        )}
        {AddFilesOverview && (
          <AddFilesPopup
            toggleShowPopup={toggleShowPopups}
            popupType={"addClient"}
          />
        )}
      </div>
      <div>
        <div
          className={`
              toggle_box1 d-flex ac-jc  rounded-5 w-20`}
        >
          <button className="cust-btn f3 text-light py-2 py-sm-3  px-1 px-sm-3 d-flex ac-jc text-nowrap f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
            Supplier Decorator
          </button>
        </div>
        {/* <div className="position-relative">
          <div className="position-relative mt-3">
            <button
              onClick={() => {
                setDropDown(8);
              }}
              className="pointerView cust-btn px-4 py-2 tranc cp"
            >
              <p className="primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                + Decoration Location
              </p>
            </button>
            {dropdown === 8 && (
              <div
                className="invisible-cont2 z-0"
                onClick={() => setDropDown(undefined)}
              />
            )}
            <div
              className={`${
                dropdown === 8 && "submenu_1 h-auto"
              } submenu_cont_1 overflow-scroll z-3`}
              style={{ width: "300px" }}
            >
              <button className="d-flex cust-btn w-100 as-jb hover-cust ac-jb">
                <button
                  onClick={() => {
                    setDropDown(undefined);
                    addAddLocClick2();
                    locationAdd(1);
                  }}
                  className="px-2 cust-btn text-start py-1 w-100 "
                >
                  Add new decoration location
                </button>
              </button>
            </div>
          </div>
        </div> */}
        {location?.map((item, ind) => {
          return (
            <div className="w-100 overflow-scroll mt-5 edit_page_cust">
              <table className="w-100">
                <div className="d-flex hide_res">
                  <p className="primary f3 ms-5 pt-3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Location {ind + 1}
                  </p>
                </div>
                <tr>
                  <div className="py-3 hide_res">
                    <td>
                      <div className="d-flex w-100">
                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "415px" }}
                        >
                          <div className="w-100">
                            <input
                              placeholder="Required Field"
                              className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                              value={item?.product?.name}
                            />
                          </div>
                        </div>
                        <div className="mx-2 d-flex ac-jc position-relative">
                          <button
                            onClick={() =>
                              artWorkHandler(item, productData, 0, ind)
                            }
                            className=" bg-transparent doted_border d-flex ac-js "
                          >
                            <div className="">
                              <img
                                src={
                                  item?.image_url ? item?.image_url : product
                                }
                                className="object-fit-contain img_style"
                              />
                            </div>
                          </button>
                        </div>
                        {/* <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "200px" }}
                        >
                          <div className="d-flex ac-js gap-2 ">
                            <img src={danger_sm} className="danger_sm" />
                            <p className="fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                              Proof Required
                            </p>
                          </div>
                        </div> */}
                      </div>
                    </td>
                  </div>
                </tr>
                <tr>
                  <div className="py-3 hide_res">
                    <td>
                      <div className="w-100 d-flex ac-js gap-3">
                        <Col sm={12} md={6}>
                          <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                            <div className="w-100 mt-3">
                              <p className="w-100 mx-3 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                                Decorative Method
                              </p>
                              <div className="w-100 ms-3">
                                <input
                                  placeholder="Required Field"
                                  className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                                  value={item?.decorative_method_id?.name}
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col sm={12} md={6}>
                          <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                            <div className="w-100 mt-3">
                              <p className="w-100 mx-3 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                                {/* Imprint Type: */}
                                Proof Required
                              </p>
                              <div className="w-100 ms-3">
                                <input
                                  placeholder="Required Field"
                                  className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                                  value={
                                    item?.proof_required == "email_proof"
                                      ? "Email Proof"
                                      : item?.proof_required == "digital_photo"
                                      ? "Digital Photo"
                                      : item?.proof_required == "pre_production"
                                      ? "Pre Production"
                                      : ""
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col sm={12} md={6}>
                          <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                            <div className="w-100 mt-3">
                              <p className="w-100 mx-3 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                                Logo Name
                              </p>
                              <div className="w-100 ms-3">
                                <textarea
                                  placeholder="Required Field"
                                  className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                                  value={item?.logo_name}
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col sm={12} md={6}>
                          <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                            <div className="w-100 mt-3">
                              <p className="w-100 mx-3 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                                UOM
                              </p>
                              <div className="w-100 ac-js d-flex gap-3 ms-3">
                                <div className="ps-2 bg-transparent ps-2 d-flex ps-2 gap-3 ac-js rounded-4 hover_Ef py-2 cp my-1 ">
                                  <button className="d-flex gap-3  ac-js cust-btn">
                                    <div className="radio_btn d-flex ac-jc">
                                      <div
                                        className={`${
                                          +item?.uom == 1
                                            ? "sm_radio"
                                            : "sm_radio1"
                                        }`}
                                      />
                                    </div>
                                    <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                      Inches
                                    </p>
                                  </button>
                                </div>
                                <div className="ps-2 bg-transparent ps-2 d-flex ps-2 gap-3 ac-js rounded-4 hover_Ef py-2 cp my-1 ">
                                  <button className="d-flex gap-3  ac-js cust-btn">
                                    <div className="radio_btn d-flex ac-jc">
                                      <div
                                        className={`${
                                          +item?.uom == 2
                                            ? "sm_radio"
                                            : "sm_radio1"
                                        }`}
                                      />
                                    </div>
                                    <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                      Stitch
                                    </p>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </div>
                    </td>
                  </div>
                  <div className="py-3 hide_res">
                    <td>
                      <div className="w-100 d-flex ac-js  gap-3">
                        <Col sm={12} md={6}>
                          <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                            <div className="w-100 mt-3">
                              <p className="w-100 mx-3 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                                Logo Size
                              </p>
                              <div className="w-100 ms-3 d-flex gap-2">
                                <input
                                  placeholder="Length"
                                  className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                                  value={item?.logo_length}
                                />
                                <input
                                  placeholder="Breadth"
                                  className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                                  value={item?.logo_breadth}
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col sm={12} md={6}>
                          <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                            <div className="w-100 mt-3">
                              <p className="w-100 mx-3 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                                Logo Color
                              </p>
                              <div className="w-100 ms-3">
                                <textarea
                                  placeholder="Required Field"
                                  className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                                  value={item?.logo_color}
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col sm={12} md={6}>
                          <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                            <div className="w-100 mt-3">
                              <p className="w-100 mx-3 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                                Repeat Logo?
                              </p>
                              <div className="w-100 ms-3">
                                <input
                                  placeholder="Required Field"
                                  className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                                  value={
                                    item?.repeat_logo == "yes"
                                      ? "Yes"
                                      : item?.repeat_logo == "no"
                                      ? "No"
                                      : ""
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col sm={12} md={6}>
                          <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                            <div className="w-100 mt-3">
                              <p className="w-100 mx-3 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                                Supplier Notes
                              </p>
                              <div className="w-100 ms-3">
                                <textarea
                                  placeholder="Required Field"
                                  className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                                  value={item?.supplier_note}
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                      </div>
                    </td>
                  </div>
                </tr>
              </table>
            </div>
          );
        })}
      </div>

      <div className="d-flex w-100 gap-3 ac-jb flex-m-r my-3 ">
        <div className="w-xl-100 w-lg-48 w-md-100 w-sm-100 w-xs-100 mt-3">
          <p className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Personal Comment:
          </p>
          <div className="position-relative">
            <textarea
              placeholder="Required Field"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              onChange={(e) => setPrivateComment(e.target.value)}
              value={privateComment}
              disabled={productDataList?.status >= 5 ? true : false}
            />
            {/* <button
              style={{
                position: "absolute",
                top: -40,
                zIndex: 100,
                right: 0,
              }}
              class="cust-btn addbtn ms-1 mt-2 bg-primar text-white f2 fs-10 fs-xs-9 fs-sm-9 fs-md-9 fs-lg-9 fs-xl-10 fs-xxl-10 py-2 py-md-2 px-5 rounded"
            >
              Post
            </button> */}
          </div>
        </div>
        <div className="w-xl-100 w-lg-48 w-md-100 w-sm-100 w-xs-100 mt-3">
          <p className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Private Notes:
          </p>
          <div className="position-relative">
            <textarea
              placeholder="Required Field"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              onChange={(e) => setPrivateNotes(e.target.value)}
              value={privateNote}
              disabled={productDataList?.status >= 5 ? true : false}
            />
          </div>
        </div>
      </div>
      <div className="mt-4 w-100 d-flex ac-jc">
        {productDataList?.status <= 4 && (
          <button
            class={`cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
              btns && "opacity-50"
            }`}
            onClick={() => submitHandle()}
            disabled={btns ? true : false}
          >
            Submit
          </button>
        )}
      </div>
    </div>
  );
};

export default PresentationEditScreen;
