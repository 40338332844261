import React, { useEffect, useState } from "react";
import CachedIcon from "@mui/icons-material/Cached";
import { Search } from "@mui/icons-material";
import { salesForce } from "../../redux/api/DummyJson";
import AddAttributesPopup from "../Popup/AddAttributesPopup";
import ProjectDetailPopup from "../Popup/ProjectDetailPopup";
import ProjectDetailPopup2 from "../Popup/ProjectDetailPopup2";
import AddTaskPopup from "../Popup/AddTaskPopup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Link } from "react-router-dom";
import Ecommerce from "./Ecommerce";
import Enterprise from "./Enterprise";
import {
  useAdminListCustomerReqMutation,
  useProjectListMutation,
} from "../../redux/api/api";

const SalesForecastTab = () => {
  const [showDetailPopup, setShowDetailPopup] = useState(null);
  const [showAddTask, setShowAddTask] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({});

  const [ProjectListApi] = useProjectListMutation();
  const [customerAdminApi] = useAdminListCustomerReqMutation();

  const onTabClick = (tab) => {
    setActiveTab(tab);
  };

  const closeDetailPopup = () => {
    setShowDetailPopup(null);
  };

  const onClickAddTask = () => {
    setShowAddTask(!showAddTask);
  };

  const [adminList, setAdminList] = useState([]);

  const [searchs, setsearchs] = useState("");
  const [rows, setrows] = useState("");
  const [orders, setorders] = useState("");
  const [order_reps, setorder_reps] = useState("");
  const [client_reps, setclient_reps] = useState("");
  const [statuss, setstatuss] = useState("");
  const [codes, setcodes] = useState("");
  const [production_reps, setproduction_reps] = useState("");

  // PROJECT PROJECT LIST
  const getProjectList = (
    search,
    row,
    order,
    order_rep,
    client_rep,
    status,
    code,
    production_rep
  ) => {
    let formdata = new FormData();
    let params = `?page=${page}`;

    if (row) {
      formdata.append("rows", row);
    }

    if (search) {
      formdata.append("search", search);
    }

    if (order) {
      formdata.append("order", order);
    }

    if (order_rep) {
      formdata.append("order_rep", order_rep);
    }
    if (client_rep) {
      formdata.append("client_rep", client_rep);
    }
    if (status) {
      formdata.append("status", status);
    }

    if (code) {
      formdata.append("code", code);
    }
    if (production_rep) {
      formdata.append("production_rep", production_rep);
    }

    setsearchs(search);
    setrows(row);
    setorders(order);
    setorder_reps(order_rep);
    setclient_reps(client_rep);
    setstatuss(status);
    setcodes(code);
    setproduction_reps(production_rep);

    ProjectListApi({ payload: formdata, params: params })
      .unwrap()
      .then((res) => {
        let temp = [];
        res?.lists?.map((item, ind) => {
          let tags = [];

          item?.projectTags?.map((tg, tind) => {
            tags.push(tg.name);
          });
          temp.push({
            ...item,
            tags: tags.toString(),
          });
        });

        setList(temp);
        setPagination(res?.pagination_meta);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // ADMIN LIST
  const getViewAdmin = () => {
    customerAdminApi()
      .unwrap()
      .then((res) => {
        setAdminList(res?.admins);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getProjectList();
  }, [page]);

  useEffect(() => {
    getViewAdmin();
  }, []);

  return (
    <>
      <div className="d-flex mt-2 ac-jb flex-wrap me-md-3 rk2">
        <div className="d-flex mt-4 rk">
          <button
            onClick={() => onTabClick(0)}
            style={{ cursor: "pointer" }}
            className={`${
              activeTab === 0
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                activeTab === 0 ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              E-Commerce
            </p>
          </button>
          <button
            onClick={() => onTabClick(1)}
            style={{ cursor: "pointer" }}
            className={`${
              activeTab === 1
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                activeTab === 1 ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Enterprise
            </p>
          </button>
        </div>
      </div>
      {activeTab == 0 && (
        <Ecommerce
          list={list}
          pagination={pagination}
          searchs={searchs}
          rows={rows}
          orders={orders}
          order_reps={order_reps}
          client_reps={client_reps}
          statuss={statuss}
          codes={codes}
          production_reps={production_reps}
          getProjectList={getProjectList}
          adminList={adminList}
          page={page}
          setPage={setPage}
        />
      )}
      {activeTab == 1 && <Enterprise />}
    </>
  );
};

export default SalesForecastTab;
