import {
  art1,
  art2,
  closeIcon,
  cloudUpload,
  head,
  logos,
  profilePic,
  tshirt1,
  tshirt2,
  tshirt3,
} from "../assets/img";
import React, { useEffect, useState } from "react";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import ImageGallery from "react-image-gallery";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import { presentation_list } from "../redux/api/DummyJson";
import { useNavigate, useLocation } from "react-router-dom";
import { useLazyProjectViewQuery } from "../redux/api/api";
import { ErrorIcon } from "react-hot-toast";

const PresentationListA = () => {

  const navigate = useNavigate();
  const [projectViewApi] = useLazyProjectViewQuery();
  const location = useLocation();
  console.log(location, "location")
  const [presentionlist, setPresentationList] = useState([]);
  const [images, setImages] = useState([]);
  const [productqty, setProductQty] = useState();
  const [fixedcharges, setFixedCharges] = useState();
  const [userPopup, setUserPopup] = useState(false);




  const handleUserPopup = () => {
    setUserPopup(!userPopup);
  }

 



  const getView = () => {
    projectViewApi(location?.state?.data?.id)
      .unwrap()
      .then((res) => {
        if (res?.status == "success" || res?.data?.status == "success") {
          const projectData = res?.project || {};
          setPresentationList(projectData.projectPresentationPrice || []);
          const allArtworks = projectData.projectDetail
            ?.map((detail) => detail.artworks || [])
            ?.flat();
          const imageGalleryItems = Array.isArray(allArtworks)
            ? allArtworks.map((artwork) => ({
              original: artwork?.image_url || "",
              thumbnail: artwork?.image_url || ""
            }))
            : [];
          setProductQty(projectData.projectPresentationPrice);
          const projectPresentationPrice = projectData.projectPresentationPrice;
          const allRunCharges = projectPresentationPrice?.map((item) => item.run_charge) || [];
          console.log(allRunCharges, "all")
          setFixedCharges(allRunCharges);
          setImages(imageGalleryItems);
        }
      })
      .catch((err) => {
        console.log("Error fetching project details:", err);
      });
  };

  useEffect(() => {
    if (location?.state?.data?.id) {
      getView();
    }
  }, []);
  console.log(presentionlist, "presentionlist")
  return (
    <div className="bg-white" style={{ backgroundColor: "#fff" }}>
      {userPopup && (
        <div className="modal-popup">
          <div className="back-close" />
          <div
            style={{ width: "60%" }}
            className="center-content d-flex flex-column flex-lg-row p-0 m-0"
          >
            <button
              onClick={() => handleUserPopup()}
              className="modal-cls-btn fs-33 f5 primary cust-btn px-2 mb-0 mx-2"
            >
              <img src={closeIcon} alt="" />
            </button>
            <div className="d-flex flex-column as-jc px-3 mx-auto px-3 px-lg-5 w-100 h-100 ">
              {/* <h6 className="mb-2 mb-lg-3 f4 fs-xs-20 fs-sm-24 fs-lg-26 fs-xl-28">
                New Decoration1
              </h6> */}
              <div className="w-100 d-flex ac-jc flex-column flex-md-row">
                <div className="w-xs-100 w-md-50 px-2">
                  <div className="d-flex flex-wrap tl-log-upload-box ac-jb mt-lg-2">
                    {/* <p className="parag mb-2">
                      Artwork Allmade unisex Organic cotton Tee
                    </p> */}
                    <label className="w-100 position-relative">
                      <div className="rej-img">
                        <img src={profilePic} alt="No Image" />
                      </div>
                    </label>
                  </div>
                </div>
                <div className="w-xs-100 w-md-50 px-2">
                  <div className="ac-jb mt-3 mt-lg-4 pop-input">
                    <p className="f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19 primary1 mb-0">
                      Location
                    </p>
                    <input
                      type="text"
                      placeholder="Specify the placement of your artwork"
                      className="w-100 bg-transparent f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19"
                    />
                  </div>
                  <div className="ac-jb mt-3 mt-lg-4 pop-input">
                    <p className="f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19 primary1 mb-0">
                      Dimensions
                    </p>
                    <input
                      type="text"
                      placeholder="W 2.3 X H 4.5"
                      className="w-100 bg-transparent f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19"
                    />
                  </div>
                </div>
              </div>
              <div className="ac-jb mt-3 mt-lg-4 pop-input w-100">
                <p className="f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19 primary1 mb-2">
                  Additional Notes
                </p>
                <input
                  type="text"
                  placeholder="Notes"
                  className="w-100 bg-transparent f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19"
                />
              </div>
              <button
                className="get-start-btn border-0 mt-4 mx-auto d-flex py-2"
                onClick={() => handleUserPopup()}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      ) }
      <div className=" " style={{ width: "90%", margin: "auto" }}>
        <div className="d-flex flex-wrap">
          <div
            className="d-flex flex-wrap align-items-center mt-3 py-2 banner col-md-5"
            style={{ backgroundColor: "#ecfaff", borderRadius: "20px" }}
          >
            <div className="">
              <img
                alt="img"
                src={logos}
                className=" ps-3"
                style={{ width: "150px", objectFit: "cover" }}
              />
            </div>

            <div className="ps-2 font-blue2">
              <h2 className="f4 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20">
                merchHQ Pvt.Ltd.
              </h2>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
                PRESENTATION #110208 for AAHIKA
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                Project Name
              </p>
            </div>
          </div>
          <div className="col-md-7 mt-3">
            <img alt="img" src={head} className="ps-lg-3 w-100" />
          </div>
        </div>
        {presentionlist.map((item, index) => (
          <div className="d-flex flex-wrap mt-4 align-items-start" key={index}>
            <div
              className="col-md-6 mt-4 rounded bg-primary2"
              // style={{
              //   // backgroundColor: "#ecfaff",
              //   borderRadius: "22px",
              //   padding: "12px",
              //   textAlign: "center",
              // }}
              style={{
                // backgroundColor: "#ecfaff",
                borderRadius: "32px",
                padding: "12px",
                textAlign: "center",
              }}
            >
              {images?.length > 0 && (
                <ImageGallery items={images.map(image => ({
                  original: image.original,
                  thumbnail: image.thumbnail

                }))} />
              )}
            </div>
            <div className="col-md-6 p-4">
              
              <div style={{}}>
                <h2 className="f3 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 font-blue2">
                  20 oz Matt Solid State Himalayan Tumbler
                </h2>
                <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  220 GSM, Classic Fit. Style code: 64520.100% ring-spun co on.
                  Countered welt collar. tereaway label Tumble Try Medium. Lorem
                  ipsum dolor sit amet consectetur adipisicing elit. Eligendi
                  cupiditate quisquam Lorem ipsum dolor sit amet consectetur
                  adipisicing elit. Ad quidem eligendi maxime consectetur non
                </p>
              </div>
              <p className="f2 fs-xs-11 fs-sm-14 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 font-blue2 mt-3">
                Pricing
              </p>
              <div
                className="my-3 bg-primary2 p-3"
                style={{ lineHeight: "40px" }}
              >
                <table className="w-100 overflow-scroll  ">
                  <tr className=" text-center">

                    <th className="f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap font-blue2">
                      QTY
                    </th>
                    {/* {productqty?.map((item, ind) => ( */}

                    <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      {item.qty}
                    </td>
                    {/* ))} */}

                  </tr>
                  <tr className="text-center">
                    <td className="f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap font-blue2">
                      RS
                    </td>
                    {/* {productqty?.map((item, ind) => ( */}

                    <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      {item.total}
                    </td>
                    {/* ))} */}

                  </tr>
                </table>
              </div>
              <div>
                <p className="f2 fs-xs-11 fs-sm-14 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 font-blue2">
                  Fixed Charges
                </p>
                <div
                  className="p-3 my-3 bg-primary2 w-100"
                  style={{ lineHeight: "40px" }}
                >
                  <table className=" w-100  overflow-scroll">
                    {item?.run_charge?.map((charge, chargeIndex) => (
                      <tr>
                        <td className="f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap font-blue2">
                          {charge.name}
                        </td>
                        <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                          {charge.value}
                        </td>
                      </tr>
                    ))}
                  </table>
                </div>
                <p className="f2 fs-xs-11 fs-sm-14 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 font-blue2">
                  Total Price
                </p>
                <div
                  className="p-3 my-3 bg-primary2 w-100"
                  style={{ lineHeight: "40px" }}
                >
                  <table className=" w-100  overflow-scroll">
                    <tr>
                      <td className="f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap font-blue2">
                        Total Price
                      </td>
                      <td className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                        RS 6,987,9879.00
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="d-flex flex-wrap ">
                  <div className="col-md-4">
                    <p className="f2 fs-xs-11 fs-sm-14 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 font-blue2">
                      Colors:
                    </p>
                    <div className="d-flex">
                      <div className="lt-Blue">
                        <FiberManualRecordIcon />
                      </div>
                    </div>
                  </div>

                </div>
                {/* <h2 className="f4 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 gray my-3 ">
                    Comments :
                  </h2>
                  <div className="d-flex my-1">
                    <h2 className="f4 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20">
                      Sakthi :
                    </h2>
                    <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 gray mx-1">
                      Nice Product
                    </p>
                    <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  mx-1">
                      - Delete
                    </p>
                  </div>
                  <div>
                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 gray mb-3">
                      04/04/2024 - 06:11PM
                    </p>
                  </div> */}

                <div className="w-100">
                  <textarea
                    className="editBtn bg-primary2 rounded border-0 w-100 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    style={{ height: "100px" }}
                    placeholder="Comment on this item..."
                  ></textarea>
                </div>
                <div className="d-flex ac-jb">
                  <button className="my-3 bg-primar border-0 p-2 rounded f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white px-4">
                    Add Comment <PlaylistAddCheckIcon />
                  </button>

                </div>
              </div>
            </div>
            <div className="d-flex flex-wrap w-100 ac-jb mb-4">
              <div className="col-md-5">
                <div className="bg-primary2  rounded p-3 mt-3 w-50">
                  <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 font-blue2">
                    TYPE : 1
                  </p>
                </div>
                <div className="bg-primary2  rounded p-3 mt-3 w-50">
                  <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 font-blue2">
                    ARTWORK DETAILS
                  </p>
                </div>
                <div>
                  <table className="ms-3 mt-2 w-100" style={{ lineHeight: "30px" }}>
                    <tr>
                      <td>
                        <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                          DESIGN NAME
                        </p>
                      </td>
                      <td>
                        <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 me-3">
                          Vel
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                          IMPRINT TYPE
                        </p>
                      </td>
                      <td>
                        <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 me-3">
                          Applique
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                          DESIGN LOCATION
                        </p>
                      </td>
                      <td>
                        <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 me-3">

                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                          DESIGN SIZE
                        </p>
                      </td>
                      <td>
                        <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 me-3">
                          20*30
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                          DESIGN COLOR
                        </p>
                      </td>
                      <td>
                        <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 me-3">
                          black
                        </p>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="col-md-5">
                <div className="bg-primary2  rounded p-3 mt-3 w-50">
                  <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 font-blue2">
                    TYPE : 1
                  </p>
                </div>
                <div className="bg-primary2  rounded p-3 mt-3 w-50">
                  <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 font-blue2">
                    ARTWORK DETAILS
                  </p>
                </div>
                <div>
                  <table className="ms-3 mt-2 w-100" style={{ lineHeight: "30px" }}>
                    <tr>
                      <td>
                        <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                          DESIGN NAME
                        </p>
                      </td>
                      <td>
                        <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 me-3">
                          Vel
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                          IMPRINT TYPE
                        </p>
                      </td>
                      <td>
                        <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 me-3">
                          Applique
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                          DESIGN LOCATION
                        </p>
                      </td>
                      <td>
                        <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 me-3">

                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                          DESIGN SIZE
                        </p>
                      </td>
                      <td>
                        <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 me-3">
                          20*30
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                          DESIGN COLOR
                        </p>
                      </td>
                      <td>
                        <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 me-3">
                          black
                        </p>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="col-md-5">
                <div className="bg-primary2  rounded p-3 mt-3 w-50">
                  <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 font-blue2">
                    TYPE : 1
                  </p>
                </div>
                <div className="bg-primary2  rounded p-3 mt-3 w-50">
                  <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 font-blue2">
                    ARTWORK DETAILS
                  </p>
                </div>
                <div>
                  <table className="ms-3 mt-2 w-100" style={{ lineHeight: "30px" }}>
                    <tr>
                      <td>
                        <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                          DESIGN NAME
                        </p>
                      </td>
                      <td>
                        <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 me-3">
                          Vel
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                          IMPRINT TYPE
                        </p>
                      </td>
                      <td>
                        <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 me-3">
                          Applique
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                          DESIGN LOCATION
                        </p>
                      </td>
                      <td>
                        <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 me-3">

                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                          DESIGN SIZE
                        </p>
                      </td>
                      <td>
                        <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 me-3">
                          20*30
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                          DESIGN COLOR
                        </p>
                      </td>
                      <td>
                        <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 me-3">
                          black
                        </p>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="d-flex justify-content-end w-100 pe-3">
                <div>
                  <button
                    className=""
                    style={{
                      backgroundColor: "#f82a2a",
                      borderRadius: "10px",
                      padding: "10px",
                      border: "none",
                    }}
                    // onClick={() => {
                    //   navigate("/poproduct-request-a");
                    // }}
                    onClick={() => handleUserPopup()}
                  >
                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
                      Request Change
                    </p>
                  </button>
                </div>

                <div>
                  <button
                    style={{
                      backgroundColor: "#0082ca",
                      borderRadius: "10px",
                      padding: "10px",
                      border: "none",
                      marginLeft: "5px",
                    }}
                    onClick={() => {
                      navigate("/poproduct-request-a");
                    }}
                  >
                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white ">
                      Approve Order
                    </p>
                  </button>
                </div>
              </div>
            </div>
            <div className="my-3 d-flex  flex-wrap ">
              <div
                className="col-md-5 bg-primary2"
                style={{
                  // backgroundColor: "#ecfaff",
                  borderRadius: "32px",
                  padding: "12px",
                  textAlign: "center",
                }}
              >
                <img
                  alt="img"
                  src={tshirt1}
                  className=" "
                  style={{
                    width: "100%",
                    objectFit: "cover",
                    borderRadius: "20px",
                  }}
                />
                <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  Product image for reference only.
                </p>
                <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 font-blue2 pointer" onClick={() => {
                  navigate("/estimate-b");
                }}>
                  Click to enlarge
                </p>
              </div>
              <div className="col-md-7 p-4">
                <div style={{}}>
                  <h2 className="f3 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 font-blue2">
                    20 oz Matt Solid State Himalayan Tumbler
                  </h2>
                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                    220 GSM, Classic Fit. Style code: 64520.100% ring-spun co on.
                    Countered welt collar. tereaway label Tumble Try Medium. Lorem
                    ipsum dolor sit amet consectetur adipisicing elit. Eligendi
                    cupiditate quisquam Lorem ipsum dolor sit amet consectetur
                    adipisicing elit. Ad quidem eligendi maxime consectetur non
                  </p>
                </div>
                <div class="tab  mt-4 overflow-scroll">
                  <table>
                    <thead>
                      <tr>
                        <th className="f3 col-md-2  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap font-blue2">
                          ITEM
                        </th>
                        <th className="f3 col-md-6  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap font-blue2"></th>
                        <th>
                          <p className=" col-md-1 f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap mx-5 font-blue2">
                            QTY
                          </p>
                        </th>
                        <th>
                          <p className=" col-md-1 f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap mx-5 font-blue2">
                            PRICE
                          </p>
                        </th>
                        <th>
                          <p className=" col-md-1 f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap mx-5 font-blue2">
                            AMOUNT
                          </p>
                        </th>
                      </tr>
                    </thead>
                    <tbody style={{ lineHeight: "30px" }}>
                      <tr>
                        <td className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                          <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                            Size: TBD - Color: TBD
                          </p>
                          {/* <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap ">
                        Size: M - Color: Blue
                      </p>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        Size: TBD - Color: TBD
                      </p> */}
                        </td>
                        <td>
                          <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap"></p>
                          <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap "></p>
                          <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap"></p>
                        </td>
                        <td>
                          <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap text-center mx-3">
                            300
                          </p>
                          {/* <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                        10
                      </p>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3">
                        0
                      </p> */}
                        </td>
                        <td>
                          <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap text-center">
                            RS6,987,9879.00
                          </p>
                          {/* <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        RS6,3479.00
                      </p>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        RS6,4569.00
                      </p> */}
                        </td>
                        <td>
                          <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap text-center">
                            RS6,987,9879.00
                          </p>
                          {/* <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        RS6,3479.00
                      </p>
                      <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                        RS6,4569.00
                      </p> */}
                        </td>
                      </tr>

                      <p className=" col-md-1 f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap font-blue2">
                        TOTAL UNITS
                      </p>
                      <td></td>
                      <td>
                        <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap text-center mx-3">
                          310
                        </p>
                      </td>

                      <tr
                        className=""
                        style={{
                          borderBottom: "0px",
                          borderTop: "1px",
                          borderRight: "0",
                          borderColor: "#787b7f",
                          borderStyle: "solid",
                          borderLeft: 0,
                        }}
                      >
                        <td>
                          <p className="f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap font-blue2">
                            TOTAL
                          </p>
                        </td>
                        <td>
                          <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap"></p>
                        </td>
                        <td>
                          <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3"></p>
                        </td>
                        <td>
                          <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap mx-3"></p>
                        </td>
                        <td>
                          <p className="f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap text-center font-blue2">
                            RS348,332.00
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="w-100">
                    <textarea
                      className="editBtn bg-primary2 rounded border-0 w-100 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      style={{ height: "100px" }}
                      placeholder="Comment on this item..."
                    ></textarea>
                  </div>
                  <div className="d-flex ac-jb">
                    <button className="my-3 bg-primar border-0 p-2 rounded f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white px-4">
                      Add Comment <PlaylistAddCheckIcon />
                    </button>

                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="">
        <div
          className="mt-3  px-2"
          style={{
            backgroundColor: "#07679c",
            padding: "20px",
            lineHeight: "30px",
          }}
        >
          <div className="d-flex flex-wrap ac-jb w-90 m-auto">
            <div className="">
              <h2 className="f4 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 white">
                Roger Daniel
              </h2>
              <a className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 lt-Blue">
                roger@companystore.io
              </a>
              <button
                class=" d-flex ac-jc ps-3 mt-5"
                style={{
                  backgroundColor: "#0082ca",
                  border: "none",
                  borderRadius: "7px",
                  padding: "5px",
                  color: "#fff",
                }}
              >
                <UnsubscribeIcon />

                <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
                  Contact us
                </p>
              </button>
            </div>

            <div className="col-md-4">
              <p className="f3 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 white">
                merchHQ Pvt.Ltd.
              </p>
              <p className="f1 fs-xs-11 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 white ">
                #39 Krishna Reddy Colony, Domlur Layout Banglore- 560071
                Kartnataka, India.
              </p>
              <div class="d-flex white mt-sm-2 ">
                <FacebookRoundedIcon className="me-2" />

                <TwitterIcon className="mx-2" />

                <LinkedInIcon className="mx-2" />

                <InstagramIcon className="mx-2" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PresentationListA;
